/****************************************************************************************
 * File Name    : Account.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
    <div class = 'div_list'>
        <a-row :gutter="16" type="flex" justify="space-around" align="middle">
            <a-col class="gutter-row" :span="10">
                <div class="gutter-box">
                    <img :src = "image" style="width:100px; max-height:100px;">
                </div>
            </a-col>
            <a-col class="gutter-row" :span="14">
                <div class="gutter-box">
                        <h4>{{name}} ({{number}})</h4>
                        <a-icon type="mail" /> {{email}}
                        <br/>
                        <a-tag>{{role}}</a-tag>
                        <a-divider type = 'horizontal' v-if="isAdmin || isCurrentUser"/>
                        <div class="" v-if="isAdmin || isCurrentUser">
                            <span class = 'icon_button' @click = 'editUser(accountSeq)'><a-icon type="edit" /> Edit</span>
                            <a-divider type = 'vertical' v-if="isAdmin" />
                            <span class = 'icon_button' style="cursor:pointer;" @click = 'deleteUser(accountSeq)' v-if="isAdmin"><a-icon type="delete" /> Delete</span>
                        </div>
                        <div style="height:42px;" v-if="!isAdmin && !isCurrentUser">&nbsp;</div>
                </div>
            </a-col>
         </a-row>
    </div>
</template>
<script>
export default {
    name: 'Account',
    props : ['accountSeq', 'name','number','email','department','title','role','image'],
    // data() {
    //     return {
    //         formItemLayout: {
    //             labelCol: { span: 3 },
    //             wrapperCol: { span: 21 },
    //         },
    //         fileUploadSeq : 0,
    //         spinning : false,
    //     };
    // },
    computed : {
        isAdmin() {console.log(sessionStorage.getItem('user_number') === '1234')
            return sessionStorage.getItem('user_number') === '1234';
        },
        isCurrentUser() {
            return sessionStorage.getItem('user_number') === this.number;
        }
    },
    methods : {
        editUser() {
            this.$emit('editUser', this.accountSeq);
        },
        deleteUser() {
            this.$emit('deleteUser', this.accountSeq);
        }
    }
}
</script>
<style scoped>
    .div_list {
        border : 0px solid #ccc;
        padding : 10px 10px 10px 10px;
    }
    .div_action {
        text-align: right;
        padding-right : 10px
    }

</style>