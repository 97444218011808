/****************************************************************************************
 * File Name    : ModalEquipmentList.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
    <div>
        <p/>
        <p/>
        <a-input-search placeholder="input search text" style="width: 100%" />
        <p/>
        <Equipment name = "컨베이어벨트" type = "view" :description = "['공통']" v-on:selectEquipment="selectEquipment"/>
        <p/>
        <Equipment name = "진공펌프" type = "view" :description = "['정수기전용']"  v-on:selectEquipment="selectEquipment"/>
    </div>
</template>
<script>
import Equipment from "../components/Equipment.vue" ;
export default {
    name: 'ModalEquipmentList',
    components: {
        Equipment
    },
    data() {
        return {
            isDataLoaded : false,
        };
    },
    mounted() {

        this.isDataLoaded = true;
    },
    methods : {
        selectEquipment() {
            console.log("## selectEquipment in ModalEquipmentList ##");
            // this.flagForDrawer = true;
            this.$emit('selectEquipment');
        },
    }
}
</script>
<style scoped>
</style>