/****************************************************************************************
 * File Name    : SheetFinished.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
    <div>
        <a-row :gutter="16">
            <a-col class="gutter-row" :span="12">
                <div class="gutter-box">
                    <PageHeader title = "초/중/종품 보고서 관리" />
                </div>
            </a-col>
        </a-row>
        <a-divider type = 'horizontal' />
        <a-form class="" layout = "vertical" >
            <a-card>
                <a-space align="end">
                    보고서 조회
                    <a-input default-value="" v-model="filterCS" v-on:keyup="filterCSList">
                        <a-select slot="addonAfter" default-value="모델명" v-model="filterField" v-on:change="filterCSList">
                            <a-select-option value="모델명">모델명</a-select-option>
                            <a-select-option value="제품명">제품명</a-select-option>
                            <a-select-option value="검사결과">검사결과</a-select-option>
                            <!-- <a-select-option value="작성일자">작성일자</a-select-option>
                            <a-select-option value="작성상태">작성상태</a-select-option> -->
                        </a-select>
                    </a-input>

                    일자 조회
                    <a-input default-value="" v-model="filterDateStartCS" v-on:keyup="filterCSList" style="width:100px;" placeholder="0000-00-00"></a-input> ~
                    <a-input default-value="" v-model="filterDateEndCS" v-on:keyup="filterCSList" style="width:100px;" placeholder="0000-00-00"></a-input>
                </a-space>
                <p/>
                <a-table :columns="columns3" size = "small" :bordered=false :data-source="data3" :pagination="true" :scroll="{ x: 0, y: '80vh' }">
                    <span slot="tags" slot-scope="tags">
                        <a-tag v-for="tag in tags" :key="tag" class="state-btn" :class="{ green: (tag === '검사완료'), navy: (tag === '승인완료') }">
                            {{ tag.toUpperCase() }}
                        </a-tag>
                    </span>
                    <span slot="name" slot-scope="text">{{ text }}</span>
                    <span slot="action" slot-scope="record">
                        <span style = 'cursor:pointer' @click="handlerToViewProductCS(record.key, 'D')"><a-icon type="eye" /> View</span>
                        <a-divider type="vertical" />
                        <span style = 'cursor:pointer' @click="handlerToDeleteProductCS(record.key, 'D')"><a-icon type="delete" /> Delete</span>
                    </span>
                </a-table>
            </a-card>

        </a-form>
        <a-drawer title="" placement="right" :closable="false" :visible="flagForDrawer" @close="handlerForCloseDrawer" width="100%" >
            <FinishedReportViewer :target = "target" :pop = "pop" :showSaveBtn = true :showConfirmBtn = true v-on:flagForDrawer="handlerForCloseDrawer" v-on:editProductCS="handlerToEditProductCS" />
        </a-drawer>
    </div>
</template>
<script>
import _ from "lodash";
import PageHeader from "../components/PageHeader.vue";
import FinishedReportViewer from "./FinishedReportViewer.vue" ;
const columns = [
    {    title: '상태'    ,   dataIndex: 'tags' ,   key: 'tags'     ,  scopedSlots: { customRender: 'tags'        },  width : 100},
    {    title: '모델명' , dataIndex: 'model' , key: 'model' , scopedSlots: { customRender: 'model' },},
    {    title: '세부모델명' , dataIndex: 'modelDetail' , key: 'modelDetail' , scopedSlots: { customRender: 'modelDetail' },},
    {    title: '제품명' , dataIndex: 'product' , key: 'product' , scopedSlots: { customRender: 'name' },},
    // {    title: '제품정보' , dataIndex: 'barcode' , key: 'barcode' , scopedSlots: { customRender: 'barcode' },},
    {    title: '최종수정일',  dataIndex: 'updated', key: 'updated'  ,  scopedSlots: { customRender: 'updated'     },},
    {    title: '관리'                          ,   key: 'action'   ,  scopedSlots: { customRender: 'action'      },  width : 150},
];
const columns2 = [
    {    title: '관리'                          ,   key: 'action'   ,  scopedSlots: { customRender: 'action'      },  width : 100},
    {    title: '상태'    ,   dataIndex: 'tags' ,   key: 'tags'     ,  scopedSlots: { customRender: 'tags'        },  width : 100},
    {    title: '이름'    ,   dataIndex: 'name' ,   key: 'name'     ,  scopedSlots: { customRender: 'name'        },},
];
const columns3 = [
    {    title: '상태'    ,   dataIndex: 'tags' ,   key: 'tags'     ,  scopedSlots: { customRender: 'tags'        },  width : 100},
    {    title: '모델명' , dataIndex: 'model' , key: 'model' , scopedSlots: { customRender: 'model' },},
    {    title: '세부모델명' , dataIndex: 'modelDetail' , key: 'modelDetail' , scopedSlots: { customRender: 'modelDetail' },},
    {    title: '제품명' , dataIndex: 'product' , key: 'product' , scopedSlots: { customRender: 'name' },},
    {    title: '바코드' , dataIndex: 'barcode' , key: 'barcode' , scopedSlots: { customRender: 'name' },},
    {    title: '라인' , dataIndex: 'line' , key: 'line' , scopedSlots: { customRender: 'name' },},
    {    title: '최종수정일',  dataIndex: 'updated', key: 'updated'  ,  scopedSlots: { customRender: 'updated'     },},
    {    title: '검사결과',  dataIndex: 'checkState', key: 'checkState'  ,  scopedSlots: { customRender: 'updated'     },},
    {    title: '관리'                          ,   key: 'action'   ,  scopedSlots: { customRender: 'action'      },  width : 150},
];
const data = [
  {    key: '1',    name: 'FGCS210505-WP400-V1 보류중 20200401-15:30'   ,  tags: ['필요'],  },
  {    key: '2',    name: 'FGCS210505-WP301-V1 보류중 20200329-09:30'   ,  tags: ['필요'],  },
  {    key: '3',    name: 'PCCS210505-WP400-V1 완료 20200401-15:30'     ,  tags: ['진행'],  },
  {    key: '4',    name: 'PCCS210505-WP301-V1 완료 20200329-09:30'     ,  tags: ['완료'],  },
];
const data3 = [
  {    key: '1',    name: '정수기', model: 'WP-400',cs : 'FGCS210505-WP400-V1 ',id: '홍길동',created:'2021-01-01',updated:'2021-01-01' ,  tags: ['완료'],  },
  {    key: '2',    name: '정수기', model: 'WP-400',cs : 'FGCS210505-WP400-V1 ',id: '홍길동',created:'2021-01-01',updated:'2021-01-01' ,  tags: ['완료'],  },

];
export default {
    name: 'FinishedReport',
    components: {
        PageHeader ,
        FinishedReportViewer,
    },
    data() {
        return {
            isDataLoaded : false,
            flagForDrawer: false,
            data,
            columns,
            columns2,
            columns3,
            data3,
            data3Backup: [],
            csArray: [],
            dataArray: [],
            dataArray3: [],
            pop: null,
            target: null,
            barcode: null,
            filterCS : '',
            filterField: '모델명',
            filterDateStartCS: '',
            filterDateEndCS: ''
        };
    },
    mounted() {
        this.pop = sessionStorage.getItem('pop') || '';
        this.barcode = sessionStorage.getItem('barcode') || '';
        
        this.isDataLoaded = true;
        this.fetchProductCSList();
        
        const userAgent = navigator.userAgent.toLowerCase();
        const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|samsungbrowser|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);

        if (isTablet) {
            let x;
            let els1 = document.body.querySelectorAll('.ant-layout-header');
            for (x = 0; x < els1.length; x++) {
                els1[x].style.display = 'none';
            }
            let els2 = document.body.querySelectorAll('.ant-breadcrumb');
            for (x = 0; x < els2.length; x++) {
                els2[x].style.display = 'none';
            }
            let els3 = document.body.querySelectorAll('.ant-divider-horizontal');
            for (x = 0; x < els3.length; x++) {
                els3[x].style.display = 'none';
            }
            let els4 = document.body.querySelectorAll('.desktop-only');
            for (x = 0; x < els4.length; x++) {
                els4[x].style.display = 'none';
            }
            let els5 = document.body.querySelectorAll('.ant-layout-sider');
            for (x = 0; x < els5.length; x++) {
                els5[x].style.display = 'none';
            }
        }
    },
    methods : {
        // showDrawer() {
        //     this.flagForDrawer = true;
        // },
        // closeDrawer() {
        //     this.flagForDrawer = false;
        // },
        // editTool() {
        //     console.log("## editTool in Tools ##");
        //     this.flagForDrawer = true;
        // },
        // deleteTool() {
        //     console.log("## deleteTool in Tools  ##");
        //     this.flagForDrawer = true;
        // }
        fetchProductCSList(skipBarcodeScan, forceView) {
            this.csArray = [];
            this.dataArray = [];
            this.data = [];
            let data3 = [];
            this.data3 = [];
            let urlToFetchProductCSList = "https://cowaylqc.iotinfras.com:8100/product/cs/list_simple?size=10000&page=0";
            this.$http.get(urlToFetchProductCSList)
            .then((response) => {
                if(response.status === 200 && response.data.code === 'success') {
                    this.dataArray = response.data.data.content;
                    this.csArray = _.filter(response.data.data.content, (item) => {
                        return item.state === 'B';
                    });
                    this.csArray = this.csArray.reverse();
                    this.dataArray = _.filter(response.data.data.content, (item) => {
                        return item.state === 'C';
                    });
                    this.dataArray = this.dataArray.reverse();
                    this.dataArray3 = _.filter(response.data.data.content, (item) => {
                        return item.state === 'D' || item.state === 'J';
                    });
                    this.dataArray3 = this.dataArray3.reverse();
                }
                for(let x = 0; x < this.dataArray.length ; x ++) {
                    let tags = ['검사중'];

                    // TO DO - 서버에서 수정한 후 이 코드 삭제 - updated 가 9 시간이 작게 나와서 프론트엔드에서 수정
                    let updatedObj = new Date((new Date(this.dataArray[x].updated)).getTime() + 9 * 60 * 60 * 1000);
                    let year = updatedObj.getFullYear();
                    let month = updatedObj.getMonth() + 1;
                    if (month < 10) {
                        month = '0' + month;
                    }
                    let date = updatedObj.getDate();
                    if (date < 10) {
                        date = '0' + date;
                    }
                    let hour = updatedObj.getHours();
                    if (hour < 10) {
                        hour = '0' + hour;
                    }
                    let minute = updatedObj.getMinutes();
                    if (minute < 10) {
                        minute = '0' + minute;
                    }
                    let second = updatedObj.getSeconds();
                    if (second < 10) {
                        second = '0' + second;
                    }

                    this.data.push({
                        key : x,
                        name : this.dataArray[x].name,
                        barcode : this.dataArray[x].barcode,
                        tags : tags,
                        // updated : this.dataArray[x].updated,
                        updated : year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second,
                        model : this.dataArray[x].clsProduct.productModel,
                        modelDetail : this.dataArray[x].clsProduct.productModelDetail,
                        product : this.dataArray[x].clsProduct.productName,
                        line : this.dataArray[x].line,
                    });
                }

                // 검사완료, 승인완료 정렬 : 검사완료, 승인완료 순으로, 검사완료는 과거 데이터가 먼저 나오도록
                let tempArr1 = _.filter(this.dataArray3, {
                    state: 'D'
                });
                tempArr1 = _.orderBy(tempArr1, ['updated'], ['desc']);  // 수정 날짜 최신순 정렬
                let tempArr2 = _.filter(this.dataArray3, {
                    state: 'J'
                });
                tempArr2 = _.orderBy(tempArr2, ['updated'], ['desc']);  // 수정 날짜 최신순 정렬
                this.dataArray3 = tempArr1.concat(tempArr2);

                for(let x = 0; x < this.dataArray3.length ; x ++) {
                    // TO DO - 서버에서 수정한 후 이 코드 삭제 - updated 가 9 시간이 작게 나와서 프론트엔드에서 수정
                    let updatedObj = new Date((new Date(this.dataArray3[x].updated)).getTime() + 0 * 60 * 60 * 1000);
                    let year = updatedObj.getFullYear();
                    let month = updatedObj.getMonth() + 1;
                    if (month < 10) {
                        month = '0' + month;
                    }
                    let date = updatedObj.getDate();
                    if (date < 10) {
                        date = '0' + date;
                    }
                    let hour = updatedObj.getHours();
                    if (hour < 10) {
                        hour = '0' + hour;
                    }
                    let minute = updatedObj.getMinutes();
                    if (minute < 10) {
                        minute = '0' + minute;
                    }
                    let second = updatedObj.getSeconds();
                    if (second < 10) {
                        second = '0' + second;
                    }

                    let checkState = 'OK';
                    // _.forEach(this.dataArray3[x].listProductCSInpection, function(item) {
                    //     if (item.listProductCSInpectionStandard[0].min === 'NG') {
                    //         checkState = 'NG';
                    //     }
                    // });
                    if (this.dataArray3[x].csTime.match('NG')) {
                        checkState = 'NG';
                    }

                    data3.push({
                        key : x,
                        name : this.dataArray3[x].name,
                        barcode : this.dataArray3[x].barcode,
                        tags : (this.dataArray3[x].state === 'D' ? ['검사완료'] : ['승인완료']),
                        // updated : this.dataArray3[x].updated,
                        updated : year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second,
                        model : this.dataArray3[x].clsProduct.productModel,
                        modelDetail : this.dataArray3[x].clsProduct.productModelDetail,
                        product : this.dataArray3[x].clsProduct.productName,
                        line : this.dataArray3[x].line,
                        checkState: checkState
                    });
                }
                this.data3 = data3;
                this.data3Backup = JSON.parse(JSON.stringify(this.data3));
                this.flagSpinner = false;

                if (skipBarcodeScan && forceView) {   // 바코드 스캔 또는 직접 입력 후 체크시트가 생성된 후의 조회인 경우, 수정, 삭제인 경우
                    this.target = this.dataArray[0];  // 방금 생성하였으므로 맨 처음 체크시트 조회 - 기존에는 맨 끝을 찾았으나 this.dataArray 정렬 순서 변경으로 맨 처음을 찾음
                    this.flagForDrawer = true;
                } else {
                    // 바코드 스캔한 경우
                    if (this.pop) {
                        let barcodeMatch = false;
                        let matchedCS = null;
                        _.forEach(this.csArray, (item) => {
                            if (item.clsProduct.productPop == this.pop && item.state === 'B') {   // CS에서 바코드 스캔된 pop 에 해당하는 것이 있는지 확인. 여러개 있어도 가장 최근 것 하나를 선택
                                barcodeMatch = true;
                                matchedCS = item;
                                return false;
                            }
                        });

                        // 바코드 매칭이 된 경우
                        if (barcodeMatch) {
                            if (confirm(this.barcode + ' 초/중/종품 체크시트를 작성하시겠습니까?')) {
                                // matchedCS 를 복사해서 valid 값을 C 로 하여 체크시트 생성 후 열기
                                this.handlerToCreateCSForProudct(matchedCS);
                            } else {
                                this.pop = '';
                                this.barcode = '';
                                sessionStorage.removeItem('pop');
                                sessionStorage.removeItem('barcode');
                                location.reload();
                            }
                        } else {
                            this.$message.error('인식된 바코드의 제품이 등록되어 있지 않습니다.');
                        }
                    }
                }
            }).catch(error => {
                console.log(error);
                this.flagSpinner = false;
            });
        },
        handlerToConfirmProductCS(dataOffset) {
            if (confirm('완료처리하시겠습니까?')) {
                this.data3.push({
                    key: this.dataArray[dataOffset].x,
                    name: this.dataArray[dataOffset].clsProduct.productName,
                    model: this.dataArray[dataOffset].clsProduct.productModel,
                    cs : this.dataArray[dataOffset].name,
                    id: '',
                    created: this.dataArray[dataOffset].created,
                    updated: this.dataArray[dataOffset].updated,
                    tags: ['완료']
                });

                this.data.splice(dataOffset, 1);
            }
        },
        handlerToViewProductCS(dataOffset, state) {
            this.dataOffset = dataOffset;
            let target;
            if (state === 'C') {
                target = this.dataArray[dataOffset];
            } else if (state === 'D') {
                target = this.dataArray3[dataOffset];
            }

            let urlToFetchProductCSList = "https://cowaylqc.iotinfras.com:8100/product/cs/get_finished_cs_item?seq=" + target.productCsSeq;
                this.$http.get(urlToFetchProductCSList)
                .then((response) => {
                    if(response.status === 200 && response.data.code === 'success') {
                        let item = response.data.data.content[0];
                        this.target = item;

                        // TO DO - 서버에서 수정한 후 이 코드 삭제 - updated 가 9 시간이 작게 나와서 프론트엔드에서 수정
                        let updatedObj = new Date((new Date(this.target.updated)).getTime() + 0 * 60 * 60 * 1000);
                        let year = updatedObj.getFullYear();
                        let month = updatedObj.getMonth() + 1;
                        if (month < 10) {
                            month = '0' + month;
                        }
                        let date = updatedObj.getDate();
                        if (date < 10) {
                            date = '0' + date;
                        }
                        let hour = updatedObj.getHours();
                        if (hour < 10) {
                            hour = '0' + hour;
                        }
                        let minute = updatedObj.getMinutes();
                        if (minute < 10) {
                            minute = '0' + minute;
                        }
                        let second = updatedObj.getSeconds();
                        if (second < 10) {
                            second = '0' + second;
                        }
                        this.target.updated = year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second;

                        this.flagForDrawer = true;
                    }
                });
        },
        handlerToEditProductCS(listItem, state) {
            let generatedObjectToPersist = {
                productCsSeq: listItem.productCsSeq,
                productSeq: listItem.clsProduct.productSeq,
                productCSName: listItem.name,
                productCSInspections: [],
                productCSTimes: [],
                state: state,
                barcode: listItem.barcode,
                csTime: listItem.csTime,
                line: listItem.line,
                manager: listItem.manager,
                approver: (state === 'J' ? sessionStorage.getItem('user_name') : ''),
            };

            _.forEach(listItem.listProductCSInpection, (item) => {
                let obj = {
                    index: '' + item.inspectionIndex,
                    offset: '' + item.inspectionOffset,
                    inspection: item.inspectionName,
                    inspectionTarget: item.inspectionTarget || '',
                    standard: {
                        min: item.listProductCSInpectionStandard.length && item.listProductCSInpectionStandard[0].min || '',
                        max: item.listProductCSInpectionStandard.length && item.listProductCSInpectionStandard[0].max || '',
                        name: item.listProductCSInpectionStandard.length && item.listProductCSInpectionStandard[0].name || '',
                        result: item.listProductCSInpectionStandard.length && item.listProductCSInpectionStandard[0].result || '',
                        ngTitle: item.listProductCSInpectionStandard.length && item.listProductCSInpectionStandard[0].ngTitle || '',
                        ngContent: item.listProductCSInpectionStandard.length && item.listProductCSInpectionStandard[0].ngContent || ''
                    },
                    imageSeq: item.inspectionImageSeq,
                    image2Seq: item.inspectionImage2Seq,
                    description: item.inspectionDescription
                };

                generatedObjectToPersist.productCSInspections.push(obj);
            });

            _.forEach(listItem.listProductCSTime, (item) => {
                let obj = {
                    start: item.timeStart,
                    terms: item.timeTerms
                };

                generatedObjectToPersist.productCSTimes.push(obj);
            });


            let urlForProduct = this.$store.state.hostInService + '/product/cs/edit';
            this.$http.put(urlForProduct,generatedObjectToPersist)
            .then((response) => {
                if (response.status === 200 && response.data.code === 'success') {
                    this.$message.success('저장되었습니다');
                    // this.fetchProductCSList(true);
                    location.reload();
                }  else {
                    this.$message.error('저장되지 않았습니다(1).');
                }
            })
            .catch(error => {
                console.error(error);
                this.$message.error('저장되지 않았습니다(2).');
            });
        },
        handlerForCloseDrawer() {
            this.flagForDrawer = false;
        },
        handlerToDeleteProductCS(dataOffset, state) {
            if (state === 'C') {
                this.target = this.dataArray[dataOffset];
            } else if (state === 'D') {
                this.target = this.dataArray3[dataOffset];
            }

            if (confirm('삭제하시겠습니까?')) {
                let urlForProduct = this.$store.state.hostInService + '/product/cs/delete';
                let listItem = this.target;
                let generatedObjectToPersist = {
                    productCsSeq: listItem.productCsSeq
                };
                this.$http.put(urlForProduct,generatedObjectToPersist)
                    .then((response) => {
                        if (response.status === 200 && response.data.code === 'success') {
                            this.$message.success('삭제되었습니다');
                            this.fetchProductCSList(true);
                        }  else {
                            this.$message.error('삭제되지 않았습니다(1).');
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        this.$message.error('삭제되지 않았습니다(2).');
                    });
            }
        },
        handlerToCreateCSForProudctBarcode() {
            if (!this.barcode) {
                this.$message.error('바코드를 입력해주세요');
                return;
            }
            
            let pop = this.barcode.substr(3, 5);
            if (pop) {
                sessionStorage.setItem('pop', pop);
                sessionStorage.setItem('barcode', this.barcode);
                location.reload();
            } else {
                this.$message.error('인식된 바코드의 제품이 등록되어 있지 않습니다.');
            }
        },
        handlerToCreateCSForProudct(listItem) {

            let generatedObjectToPersist = {
                productSeq: listItem.clsProduct.productSeq,
                productCSName: 'FINISHEDCS-' + listItem.name,
                productCSInspections: [],
                productCSTimes: [],
                state: 'C',
                barcode: this.barcode,
                csTime: ''
            };

            _.forEach(listItem.listProductCSInpection, (item) => {
                let obj = {
                    index: '' + item.inspectionIndex,
                    offset: '' + item.inspectionOffset,
                    inspection: item.inspectionName,
                    inspectionTarget: item.inspectionTarget || '',
                    standard: {
                        min: item.listProductCSInpectionStandard.length && item.listProductCSInpectionStandard[0].min || '',
                        max: item.listProductCSInpectionStandard.length && item.listProductCSInpectionStandard[0].max || '',
                        name: item.listProductCSInpectionStandard.length && item.listProductCSInpectionStandard[0].name || '',
                        result: item.listProductCSInpectionStandard.length && item.listProductCSInpectionStandard[0].result || ''
                    },
                    imageSeq: item.inspectionImageSeq,
                    image2Seq: 0,
                    description: item.inspectionDescription
                };

                generatedObjectToPersist.productCSInspections.push(obj);
            });

            _.forEach(listItem.listProductCSTime, (item) => {
                let obj = {
                    start: item.timeStart,
                    terms: item.timeTerms
                };

                generatedObjectToPersist.productCSTimes.push(obj);
            });


            let urlForProduct = this.$store.state.hostInService + '/product/cs/add';
            this.$http.post(urlForProduct,generatedObjectToPersist)
            .then((response) => {
                if (response.status === 200 && response.data.code === 'success') {
                    this.$message.success('생성되었습니다');
                    this.fetchProductCSList(true, true);
                    this.pop = '';
                    this.barcode = '';
                    sessionStorage.removeItem('pop');
                    sessionStorage.removeItem('barcode');
                }  else {
                    this.$message.error('생성되지 않았습니다(1).');
                }
            })
            .catch(error => {
                console.error(error);
                this.$message.error('생성되지 않았습니다(2).');
            });
        },
        filterCSList() {
            if (this.filterCS || this.filterDateStartCS || this.filterDateEndCS) {
                let tempData = _.cloneDeep(this.data3Backup);

                if (this.filterCS) {
                    let re = new RegExp(this.filterCS, 'gi');

                    tempData = _.filter(tempData, (item) => {
                        // 모델명
                        if (this.filterField === '모델명') {
                            return item.model && item.model.match(re) || item.modelDetail && item.modelDetail.match(re);
                        }
                        // 제품명
                        else if (this.filterField === '제품명') {
                            return item.product && item.product.match(re);
                        }
                        // 검사결과
                        else if (this.filterField === '검사결과') {
                            return item.checkState === this.filterCS.toUpperCase();
                        }
                    });
                }

                if (this.filterDateStartCS) {
                    try {
                        let startTime = (new Date(this.filterDateStartCS)).getTime();
                        tempData = _.filter(tempData, (item) => {
                            return (new Date(item.updated)).getTime() >= startTime;
                        });
                    } catch (e) {
                        console.log(e)
                    }
                }

                if (this.filterDateEndCS) {
                    try {
                        let endTime = (new Date(this.filterDateEndCS)).getTime() + 86400000;
                        tempData = _.filter(tempData, (item) => {
                            return (new Date(item.updated)).getTime() <= endTime;
                        });
                    } catch (e) {
                        console.log(e)
                    }
                }

                this.data3 = tempData;
            } else {
                this.data3 = this.data3Backup;
            }
        }
    }
}
</script>
<style scoped>
</style>