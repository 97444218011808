/****************************************************************************************
 * File Name    : index.js
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  : VUEX-state
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.06.26  	최명호    	Create
   1.0  2021.09.05   	최명호    	addCSForProducts을 이중배열로 하고, 해당 인텍스에 바로 저장
****************************************************************************************/
import Vue from 'vue'
import Vuex from 'vuex'
// DESC : state 를 페이지 Refresh와 상관없이 유지하려면, 소스 아래 Plug-in 옵션과 같이
// import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        flagForDemo         : true,
        // DESC : 로컬테스트
        // hostInService       : 'http://localhost:19108',
        // imageUploadServer   : 'http://localhost:19108/upload/image',
        // DESC : 실서버
        hostInService       : 'https://cowaylqc.iotinfras.com:19108',
        imageUploadServer   : 'https://cowaylqc.iotinfras.com:19108/upload/image',
        productSeq          : -1,
        productCode         : '',
        CSForProductsVer    : 'V1',
        CSForProducts       : [],
        CSForProductsTime   : [],
    },
    mutations: {
        setFlagForDemo  (state,value) { state.flagForDemo   = value; },
        setProductSeq   (state,value) { state.productSeq    = value; },
        setProductCode  (state,value) { state.productCode   = value; },
        // DESC : 초/중/종품 검사 CS  정보
        addCSForProducts(state,value) {
            // DESC : 저장할 2 중 배열의 Index/offset 를 설정한다.
            let index   = value.index*1;
            let offset  = value.offset*1;
            // DESC : 해당 배열이 Undefined 라면 생성한다.
            if(typeof state.CSForProducts[index] === "undefined") {
                state.CSForProducts[index] = [];
                if(typeof state.CSForProducts[index][offset] === "undefined") {
                    state.CSForProducts[index][offset] = [];
                }
            }
            // DESC : 해당 배열에 자료를 저장한다. 자료는 [0,0] 부터가 아닌 [1,1] 부터 저장됨.
            state.CSForProducts[index][offset] = value;
            console.log("-VUEX-CS- : ",state.CSForProducts);
        },
        //  DESC : 초/중/종품 검사 CS 검사시간
        addCSForProductsTime(state,value) {
            state.CSForProductsTime = value;
            console.log("-VUEX-TIME_ : ",state.CSForProductsTime);
        }
    },
    actions: {
    },
    modules: {
    },
    // plugins: [createPersistedState()],
})
