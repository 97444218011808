/****************************************************************************************
 * File Name    : SheetEquipmentControls.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
    <div>
        <a-row :gutter="16">
            <a-col class="gutter-row" :span="12">
                <div class="gutter-box">
                    <PageHeader title = "설비검사 보고서 관리" />
                </div>
            </a-col>
        </a-row>
        <a-divider type = 'horizontal' />
        <!-- <a-space align="end">
            <a-statistic title="작성필요" :value="1128" style="margin-right: 50px">
                <template #prefix>
                    <a-icon type="check-circle" />
                </template>
                <template #suffix>
                    (90%)
                </template>
            </a-statistic>
            <a-statistic title="작성중" :value="60" style="margin-right: 50px">
                <template #prefix>
                    <a-icon type="question-circle" />
                </template>
                <template #suffix>
                    (10%)
                </template>
            </a-statistic>
             <a-statistic title="작성완료" :value="60" style="margin-right: 50px">
                <template #prefix>
                    <a-icon type="exclamation-circle" />
                </template>
                <template #suffix>
                    (10%)
                </template>
            </a-statistic>
            <a-radio-group style = ''>
                <a-radio-button value="작성필요">작성필요 보기</a-radio-button>
                <a-radio-button value="작성중">작성중 보기</a-radio-button>
                <a-radio-button value="작성완료">작성완료 보기</a-radio-button>
                <a-radio-button value="모두">모두보기</a-radio-button>
            </a-radio-group>
        </a-space>
        <p/>
        <a-progress type = 'line' :percent="90" strokeColor = '#C7C6C6'/> -->
        <p/>




        <!-- <a-table :columns="columns2" size = "small" :bordered=false :data-source="inspectionData" :pagination="true" :scroll="{ x: 0, y: '80vh' }">
            <span slot="targetProduct" slot-scope="targetProduct">{{ targetProduct === 'waterpurifier' ? '정수기' : '청정기' }}</span>
            <span slot="action" slot-scope="record">
                <span @click="editSheet(record)" style="cursor:pointer;"><a-icon type="edit" /> Edit</span>
                <a-divider type="vertical" />
                <span @click="handlerToDeleteInspection(record)" style="cursor:pointer;"><a-icon type="delete" /> Delete</span>
            </span>
        </a-table>
        <p/> -->



        <a-space align="end">
            일자 조회
            <a-input default-value="" v-model="filterDateStartCS" v-on:keyup="filterCSList" style="width:100px;" placeholder="0000-00-00"></a-input> ~
            <a-input default-value="" v-model="filterDateEndCS" v-on:keyup="filterCSList" style="width:100px;" placeholder="0000-00-00"></a-input>
        </a-space>

        <p />


        <a-table :columns="columns2" size = "small" :bordered=false :data-source="inspectionData" :pagination="true" :scroll="{ x: 0, y: '100vh' }">
            <span slot="targetProduct" slot-scope="targetProduct">{{ targetProduct === 'waterpurifier' ? '정수기' : '청정기' }}</span>
            <span slot="tags" slot-scope="tags">
                <a-tag v-for="tag in tags" :key="tag" :color="tag === '승인완료' ? '#00b0f0' : '#929292'" >
                    {{ tag.toUpperCase() }}
                </a-tag>
            </span>
            <span slot="name" slot-scope="text">{{ text }}</span>
            <span slot="updated" slot-scope="updated">{{ updated }}</span>
            <span slot="valid" slot-scope="valid">
                <a-tag class="state-btn" :class="{ green: (valid === 'B'), navy: (valid === 'C') }">
                    {{ valid === 'B' ? '검사완료' : '승인완료' }}
                </a-tag>
            </span>
            <span slot="action" slot-scope="record">
                <span style = 'cursor:pointer' @click="editSheet(record)"><a-icon type="eye" /> View</span>
                <a-divider type="vertical" />
                <span style = 'cursor:pointer' @click="handlerToDeleteInspection(record)"><a-icon type="delete" /> Delete</span>
            </span>
        </a-table>
        <p/>
        <!-- <a-form class="" layout = "vertical" >
            <a-card>
                <a-space align="end">
                    설비 검사 현황 조회
                    <a-input default-value="" v-model="filterCS" v-on:keyup="filterCSList">
                        <a-select slot="addonAfter" default-value="공정" v-model="filterField" v-on:change="filterCSList">
                            <a-select-option value="공정">공정</a-select-option>
                            <a-select-option value="단위설비">단위설비</a-select-option>
                        </a-select>
                    </a-input>
                </a-space>
                <p/>
                <a-table :columns="columns3" size = "small" :bordered=false :data-source="data3" :pagination="true" :scroll="{ x: 0, y: '57vh' }">
                    <span slot="tags" slot-scope="tags">
                        <a-tag v-for="tag in tags" :key="tag" :color="tag === '보류' ? '#CDCDCD' : '#929292'" >
                            {{ tag.toUpperCase() }}
                        </a-tag>
                    </span>
                    <span slot="name" slot-scope="text">{{ text }}</span>
                    <span slot="action" slot-scope="record">
                        <span style = 'cursor:pointer' @click="editSheet(record)"><a-icon type="edit" /> Edit</span>
                    </span>
                </a-table>
            </a-card>

        </a-form> -->
        <p/>



        <a-drawer title="" placement="right" :closable="false" :visible="flagForDrawer" @close="handlerForCloseDrawer" width="100%" >
            <EquipmentReportViewer :equipments="data" :lineinfo="lineData" :showSaveBtn = true :target="target" :targetProduct="targetProduct" v-on:flagForDrawer="handlerForCloseDrawer" v-on:editEquipmentSheet="editEquipmentSheet" />
        </a-drawer>
    </div>
</template>
<script>
import _ from "lodash";
import PageHeader from "../components/PageHeader.vue";
import EquipmentReportViewer from "./EquipmentReportViewer.vue" ;
const columns = [
    { title: '구분',dataIndex: 'targetProduct',key: 'targetProduct',  scopedSlots: { customRender: 'targetProduct'},},
    { title: '공정',dataIndex: 'processName',key: 'processName',  scopedSlots: { customRender: 'processName'},},
    { title: '단위설비',dataIndex: 'unitEquipment',key: 'unitEquipment',  scopedSlots: { customRender: 'unitEquipment'},},
    { title: '구성장치',dataIndex: 'devivce',key: 'devivce',  scopedSlots: { customRender: 'devivce'},},
    { title: '점검내용',dataIndex: 'inspectionDescription',key: 'inspectionDescription',scopedSlots: { customRender: 'inspectionDescription'},},
    { title: '요일',dataIndex: 'inspectionCycle',key: 'inspectionCycle',scopedSlots: { customRender: 'inspectionCycle'},},
    { title: '최종수정일',dataIndex: 'updated',key: 'updated',scopedSlots: { customRender: 'updated'},},
    {    title: '관리'                          ,   key: 'action'   ,  scopedSlots: { customRender: 'action'      },  width : 250},
];
const columns2 = [
    {    title: '상태', dataIndex: 'valid'         ,   key: 'valid'   ,  scopedSlots: { customRender: 'valid'      },  width : 250},
    { title: '구분',dataIndex: 'targetProduct',key: 'targetProduct',  scopedSlots: { customRender: 'targetProduct'},},
    { title: '라인',dataIndex: 'line',key: 'line',scopedSlots: { customRender: 'line'},},
    { title: '최종수정일',dataIndex: 'updated2',key: 'updated2',scopedSlots: { customRender: 'updated2'},},
    {    title: '관리'                          ,   key: 'action'   ,  scopedSlots: { customRender: 'action'      },  width : 250},
];
export default {
    name: 'EquipmentReport',
    components: {
        PageHeader      ,
        EquipmentReportViewer,
        // Tool           ,
        // ToolForm
    },
    data() {
        return {
            isDataLoaded : false,
            flagForDrawer: false,
            flagForSpinner: false,
            columns,
            columns2,
            target: {},
            targetProduct: 'waterpurifier',
            data: {
                waterpurifier: [],
                airpurifier: []
            },
            lineData: {
                waterpurifier: [],
                airpurifier: []
            },
            inspectionData: [],
            inspectionDataBackup: [],
            filterCS : '',
            filterField: '모델명',
            filterDateStartCS: '',
            filterDateEndCS: ''
        };
    },
    mounted() {
        this.isDataLoaded = true;
        this.loadList('waterpurifier');
        this.loadList('airpurifier');
        this.loadLineList('waterpurifier');
        this.loadLineList('airpurifier');
        this.loadInspectionList();
    },
    methods : {
        editEquipmentSheet() {
            this.isDataLoaded = true;
            this.loadList('waterpurifier');
            this.loadList('airpurifier');
            this.loadLineList('waterpurifier');
            this.loadLineList('airpurifier');
            this.loadInspectionList();
            this.closeDrawer();
        },
        loadList(targetProduct) {
            this.flagForSpinner = true;
            this.data[targetProduct] = [];
            let urlToFetchProducts = this.$store.state.hostInService + "/equipment/" + targetProduct;
            this.$http.get(urlToFetchProducts)
            .then((response) => {
                if(response.status === 200 && response.data.code === 'success') {
                    let dataArray = response.data.data;
                    for(let x = 0; x < dataArray.length; x ++ ) {
                        this.data[targetProduct].push(dataArray[x]);
                    }
                }
                this.flagForSpinner = false;

            }).catch(error => {
                console.log(error);
                this.flagForSpinner = false;
            });
        },
        loadLineList(targetProduct) {
            this.flagForSpinner = true;
            this.lineData[targetProduct] = [];
            let urlToFetchProducts = this.$store.state.hostInService + "/equipment/lineinfo/" + targetProduct;
            this.$http.get(urlToFetchProducts)
            .then((response) => {
                if(response.status === 200 && response.data.code === 'success') {
                    let dataArray = response.data.data;
                    for(let x = 0; x < dataArray.length; x ++ ) {
                        this.lineData[targetProduct].push(dataArray[x]);
                    }
                }
                this.flagForSpinner = false;

            }).catch(error => {
                console.log(error);
                this.flagForSpinner = false;
            });
        },
        loadInspectionList() {
            this.flagForSpinner = true;
            this.inspectionData = [];
            let urlToFetchProducts = this.$store.state.hostInService + "/equipment/inspection/waterpurifier";
            this.$http.get(urlToFetchProducts)
            .then((response) => {
                if(response.status === 200 && response.data.code === 'success') {
                    let dataArray = response.data.data.content;
                    for(let x = 0; x < dataArray.length; x ++ ) {
                        if (dataArray[x].valid === 'B' || dataArray[x].valid === 'C') { // 검사완료, 승인완료
                            dataArray[x].targetProduct = 'waterpurifier';
                            this.inspectionData.push(dataArray[x]);
                        }
                    }
                }
                
                urlToFetchProducts = this.$store.state.hostInService + "/equipment/inspection/airpurifier";
                this.$http.get(urlToFetchProducts)
                .then((response) => {
                    if(response.status === 200 && response.data.code === 'success') {
                        let dataArray = response.data.data.content;
                        for(let x = 0; x < dataArray.length; x ++ ) {
                            if (dataArray[x].valid === 'B' || dataArray[x].valid === 'C') {    // 검사완료, 승인완료
                                dataArray[x].targetProduct = 'airpurifier';
                                this.inspectionData.push(dataArray[x]);
                            }
                        }
                    }
                    this.flagForSpinner = false;

                    _.forEach(this.inspectionData, (item) => {
                        item.line = item.clsEquipmentLineInfo.lineName;
                        item.processName = item.listEquipmentInspectionComponent[0].clsEquipment.processName;
                        item.unitEquipment = item.listEquipmentInspectionComponent[0].clsEquipment.unitEquipment;
                        item.devivce = item.listEquipmentInspectionComponent[0].clsEquipment.devivce;
                        item.inspectionDescription = item.listEquipmentInspectionComponent[0].clsEquipment.inspectionDescription;
                        item.inspectionCycle = item.listEquipmentInspectionComponent[0].clsEquipment.inspectionCycle;

                        // TO DO - 서버에서 수정한 후 이 코드 삭제 - updated 가 9 시간이 작게 나와서 프론트엔드에서 수정
                        let updatedObj = new Date((new Date(item.updated)).getTime() + 9 * 60 * 60 * 1000);
                        let year = updatedObj.getFullYear();
                        let month = updatedObj.getMonth() + 1;
                        if (month < 10) {
                            month = '0' + month;
                        }
                        let date = updatedObj.getDate();
                        if (date < 10) {
                            date = '0' + date;
                        }
                        let hour = updatedObj.getHours();
                        if (hour < 10) {
                            hour = '0' + hour;
                        }
                        let minute = updatedObj.getMinutes();
                        if (minute < 10) {
                            minute = '0' + minute;
                        }
                        let second = updatedObj.getSeconds();
                        if (second < 10) {
                            second = '0' + second;
                        }
                        item.updated = year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second;
                    });

                    this.inspectionData = _.orderBy(this.inspectionData, ['valid', 'updated2'], ['asc', 'desc']);

                    this.inspectionDataBackup = JSON.parse(JSON.stringify(this.inspectionData));
                }).catch(error => {
                    console.log(error);
                    this.flagForSpinner = false;
                });
            }).catch(error => {
                console.log(error);
                this.flagForSpinner = false;
            });
        },
        showDrawer() {
            this.flagForDrawer = true;
        },
        closeDrawer() {
            this.flagForDrawer = false;
        },
        handlerForCloseDrawer() {
            this.flagForDrawer = false;
        },
        createSheet(targetProduct) {
            this.targetProduct = targetProduct;
            this.target = {};
            this.showDrawer();
        },
        editSheet(item) {
            console.log(item)
            this.targetProduct = item.targetProduct;
            this.target = {};
            this.target = item;
            this.showDrawer();
        },
        handlerToDeleteInspection(item) {
            if (confirm('삭제하시겠습니까?')) {
                let apiUrl = this.$store.state.hostInService + "/equipment/inspection/delete";
                let deleteData = {
                    equipmentInspectionSeq: item.equipmentInspectionSeq
                };
                this.$http.put(apiUrl,deleteData)
                .then((response) => {
                    console.log(response);
                    if(response.status === 200 && response.data.code === 'success') {
                        this.buffer = response.data.data;
                    }
                    this.$message.success("삭제되었습니다.");
                    this.loadInspectionList();
                })
                .catch(error => {
                    console.log(error);
                    this.$message.error("삭제되지 않았습니다.");
                });
            }
        },
        filterCSList() {
            if (this.filterCS || this.filterDateStartCS || this.filterDateEndCS) {
                let tempData = _.cloneDeep(this.inspectionDataBackup);

                if (this.filterCS) {
                    let re = new RegExp(this.filterCS, 'gi');

                    tempData = _.filter(tempData, (item) => {
                        // 모델명
                        if (this.filterField === '모델명') {
                            return item.model && item.model.match(re) || item.modelDetail && item.modelDetail.match(re);
                        }
                        // 제품명
                        else if (this.filterField === '제품명') {
                            return item.product && item.product.match(re);
                        }
                    });
                }

                if (this.filterDateStartCS) {
                    try {
                        let startTime = (new Date(this.filterDateStartCS)).getTime();
                        tempData = _.filter(tempData, (item) => {
                            return (new Date(item.updated)).getTime() >= startTime;
                        });
                    } catch (e) {
                        console.log(e)
                    }
                }

                if (this.filterDateEndCS) {
                    try {
                        let endTime = (new Date(this.filterDateEndCS)).getTime() + 86400000;
                        tempData = _.filter(tempData, (item) => {
                            return (new Date(item.updated)).getTime() <= endTime;
                        });
                    } catch (e) {
                        console.log(e)
                    }
                }

                this.inspectionData = tempData;
            } else {
                this.inspectionData = this.inspectionDataBackup;
            }
        }
    }
}
</script>
<style scoped>
</style>