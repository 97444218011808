/****************************************************************************************
 * File Name    : ProductForm.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
    <div class = ''>
        <a-form :form="form" @submit="onSubmit">
            <a-card>
                <a-form-item label="모델명">
                    <a-input placeholder="모델명" v-decorator="['ele01',{initialValue: productModel, rules: [{ required: true, message: '모델명을 입력하세요' }],},]"/>
                </a-form-item>
                <a-form-item label="세부모델명">
                    <a-input placeholder="세부모델명" v-decorator="['ele08',{initialValue: productModelDetail, rules: [{ required: true, message: '세부모델명을 입력하세요' }],},]"/>
                </a-form-item>
                <a-form-item label="제품명">
                    <a-input placeholder="Name" v-decorator="['ele00',{initialValue: productName, rules: [{ required: true, message: '제품명을 입력하세요' }],},] "/>
                </a-form-item>
                <a-form-item label="제품이미지">
                    <img v-if="image" :src = "image" style="width:100px;">
                    <a-spin :spinning="spinning">
                        <a-upload name="file" :multiple="false" :action="$store.state.imageUploadServer" @change="imageUpload"
                            v-decorator="['ele07',{rules: [{ required: false, message: '제품이미지를 선택하세요' }],},]">
                            <a-button> <a-icon type="camera" /> 제품이미지 선택 </a-button>
                        </a-upload>
                    </a-spin>
                </a-form-item>
                <a-form-item label="POP#">
                    <a-input placeholder="POP#" v-decorator="['ele02',{initialValue: productPop, rules: [{ required: true, message: 'POP#을 입력하세요' }],},]"/>
                </a-form-item>
                <a-form-item label="SAP#">
                    <a-input placeholder="SAP#" v-decorator="['ele03',{initialValue: productSap, rules: [{ required: true, message: 'SAP#을 입력하세요' }],},]"/>
                </a-form-item>
                <!-- <a-form-item label="기능">
                    <a-checkbox-group v-decorator="['ele04',{rules: [{ required: false, message: '기능을 선택하세요' }],},]">
                        <a-row>
                            <a-col :span="24">
                                <a-checkbox value="Cold">Cold</a-checkbox>
                                <a-checkbox value="Hot">Hot</a-checkbox>
                                <a-checkbox value="Purification">Purification</a-checkbox>
                                <a-checkbox value="Ice">Ice</a-checkbox>
                                <a-checkbox value="Sparkling">Sparkling</a-checkbox>
                                <a-checkbox value="Baristar">Baristar</a-checkbox>
                            </a-col>
                        </a-row>
                    </a-checkbox-group>
                </a-form-item>
                <a-form-item label="형태">
                    <a-radio-group  v-decorator="['ele05',{rules: [{ required: false, message: '제품형태를 선택하세요' }],},]" style = 'width:100%'>
                        <a-radio-button value="Desk">Desk-top</a-radio-button>
                        <a-radio-button value="Stand">Stand</a-radio-button>
                        <a-radio-button value="Under">Under sink</a-radio-button>
                    </a-radio-group>
                </a-form-item>
                <a-form-item label="제품유형">
                    <a-checkbox-group v-decorator="['ele06',{rules: [{ required: false, message: '제품유형을 선택하세요' }],},]">
                        <a-row>
                            <a-col :span="24">
                                <a-checkbox value="CIRDO">CIRDO</a-checkbox>
                                <a-checkbox value="Nano">Stand</a-checkbox>
                                <a-checkbox value="IoCare">IoCare</a-checkbox>
                                <a-checkbox value="sterilizing">Self sterilizing</a-checkbox>
                                <a-checkbox value="Returb">Returb</a-checkbox>
                                <a-checkbox value="Handspin">Handspin</a-checkbox>
                                <a-checkbox value="Ceneral">Ceneral</a-checkbox>
                                <a-checkbox value="My">My hands</a-checkbox>
                                <a-checkbox value="maintenance">Self maintenance</a-checkbox>
                            </a-col>
                        </a-row>
                    </a-checkbox-group>
                </a-form-item> -->
                <p/>
            </a-card>
            <p/>
            <a-button type="primary" html-type="submit" :ghost = false size = "default" icon = "plus-circle">Save</a-button>
            <a-divider type = 'vertical' />
            <!-- <a-button type="secondary" html-type="button" :ghost = false  size = "default" icon = "plus-square">Add more</a-button>
            <a-divider type = 'vertical' /> -->
            <a-button type="danger" html-type="reset" :ghost = false  size = "default" icon = "reload">Reset</a-button>
        </a-form>
    </div>

</template>
<script>
import _ from 'lodash';

const ProductFunctions = ['Cold', 'Hot', 'Purification','Ice','Sparkling','Baristar'];
const ProductFunctionsChecked = [];
export default {
    name: 'ProductForm',
    props : ['type', 'productSeq', 'productName', 'productModel', 'productModelDetail', 'productPop', 'productSap', 'image', 'uploadFileSeq', 'waterList', 'airList', 'etcList'],
    data() {
        return {
            formItemLayout: {
                labelCol: { span: 4 },
                wrapperCol: { span: 20 },
            },
            headers: {
                authorization: '',
            },
            fileUploadSeq : 0,
            spinning : false,
            //
            checkedList: ProductFunctionsChecked,
            indeterminate: true,
            checkAll: false,
            ProductFunctions,
            buffer : {},
        };
    },
    beforeCreate() {
        this.form = this.$form.createForm(this, {
                onFieldsChange: (_, changedFields) => {
                    this.$emit('change', changedFields);
                },
                mapPropsToFields: () => {
                    return {
                        username: this.$form.createFormField({
                            value: this.username,
                        }),
                    };
                },
                onValuesChange: (_, values) => {
                    console.log(values);
                },
        });
    },
    methods : {
        onSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                if(!err) {
                    // 수정
                    if (this.productSeq) {
                        let packetForProduct = {};
                        packetForProduct.productSeq          = this.productSeq;
                        packetForProduct.productName         = values.ele00;
                        packetForProduct.productImagePath    = '';
                        packetForProduct.productModel        = values.ele01;
                        packetForProduct.productModelDetail  = values.ele08;
                        packetForProduct.productPop          = values.ele02;
                        packetForProduct.productSap          = values.ele03;
                        // packetForProduct.productInstallatoin = 'Desk';  // 화면단에서 주석 처리 후 서버로 보내는 값은 임의의 값으로 처리
                        // packetForProduct.listProductFunction = ["Cold"];  // 화면단에서 주석 처리 후 서버로 보내는 값은 임의의 값으로 처리
                        // packetForProduct.listProductType     = [this.type]; // 정수기: WATER, 청정기: AIR, 기타기기: ETC
                        packetForProduct.uploadFile          = this.fileUploadSeq || this.uploadFileSeq;
                        // DESC : 제품등록
                        let urlToAddProduct = this.$store.state.hostInService + "/product/edit";
                        this.$http.put(urlToAddProduct,packetForProduct)
                        .then((response) => {
                            console.log(response);
                            if(response.status === 200 && response.data.code === 'success') {
                                this.buffer = response.data.data;
                            }
                            this.$message.success("제품이 수정되었습니다.");
                            this.$emit('addProduct');
                        })
                        .catch(error => {
                            console.log(error);
                            this.$message.error("제품이 수정되지 않았습니다.");
                        });
                    }
                    // 등록
                    else {
                        let currentList;
                        let obj;
                        if (this.type === 'WATER') {
                            currentList = _.cloneDeep(this.waterList);
                        } else if (this.type === 'AIR') {
                            currentList = _.cloneDeep(this.airList);
                        } else if (this.type === 'ETC') {
                            currentList = _.cloneDeep(this.etcList);
                        }

                        if (values.ele08) {
                            obj = _.find(currentList, {
                                model: values.ele01,
                                modelDetail: values.ele08
                            });

                            if (obj) {
                                this.$message.error("동일 정보 제품이 이미 등록되어 있습니다.");
                                return;
                            }
                        }
                        
                        let packetForProduct = {};
                        // console.log('Received values of form: ', values);
                        // this.$emit('addProduct');
                        // this.$store.commit('update', values);
                        // DESC : 제품등록을 위한 Packet 을 설정한다.
                        packetForProduct.productSeq          = 0;
                        packetForProduct.productName         = values.ele00;
                        packetForProduct.productImagePath    = '';
                        packetForProduct.productModel        = values.ele01;
                        packetForProduct.productModelDetail  = values.ele08;
                        packetForProduct.productPop          = values.ele02;
                        packetForProduct.productSap          = values.ele03;
                        // packetForProduct.productInstallatoin = values.ele05;
                        // packetForProduct.listProductFunction = values.ele04;
                        // packetForProduct.listProductType     = values.ele06;
                        packetForProduct.productInstallatoin = 'Desk';  // 화면단에서 주석 처리 후 서버로 보내는 값은 임의의 값으로 처리
                        packetForProduct.listProductFunction = ["Cold"];  // 화면단에서 주석 처리 후 서버로 보내는 값은 임의의 값으로 처리
                        // packetForProduct.listProductType     = ["CIRDO"];  // 화면단에서 주석 처리 후 서버로 보내는 값은 임의의 값으로 처리
                        packetForProduct.listProductType     = [this.type]; // 정수기: WATER, 청정기: AIR, 기타기기: ETC
                        packetForProduct.uploadFile          = this.fileUploadSeq || 1;
                        // DESC : 제품등록
                        let urlToAddProduct = this.$store.state.hostInService + "/product/add";
                        this.$http.post(urlToAddProduct,packetForProduct)
                        .then((response) => {
                            console.log(response);
                            if(response.status === 200 && response.data.code === 'success') {
                                this.buffer = response.data.data;
                            }
                            this.$message.success("제품이 등록되었습니다.");
                            this.$emit('addProduct');
                        })
                        .catch(error => {
                            console.log(error);
                            this.$message.error("제품이 등록되지 않았습니다.");
                        });
                    }
                }
            });
        },
        imageUpload(info) {
            this.spinning = info.file.status === 'uploading' ? true : false;
            if(info.file.status === 'done') {
                this.$message.success(`${info.file.name} file uploaded successfully`);
                this.fileUploadSeq = info.file.response.data.uploadFileSeq;
            } else if(info.file.status === 'error') {
                this.$message.error(`${info.file.name} file upload failed.`);
            }
        },
        handlerForChageProductFunction(checkedList) {
            this.indeterminate = !!checkedList.length && checkedList.length < ProductFunctions.length;
            this.checkAll = checkedList.length === ProductFunctions.length;
        },
        handlerForCheckAll(e) {
            Object.assign(this, {
                checkedList: e.target.checked ? ProductFunctions : [],
                indeterminate: false,
                checkAll: e.target.checked,
            });
        }
    }
}
</script>
<style scoped>
    .image_upload {
        text-align: center;
        background: rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        margin-bottom: 20px;
        padding: 30px 50px;
        margin: 20px 0;
    }
</style>