/****************************************************************************************
 * File Name    : Equipment.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
    <div class = 'div_list'>
        <a-row v-if = "type === 'list'" :gutter="16" type="flex" justify="space-around" align="middle">
            <a-col class="gutter-row" :span="20" style = 'border:0px solid #ccc'>
               <a-space align="baseline" >
                    <a-avatar src = "https://static.turbosquid.com/Preview/001180/025/TE/conveyor-belt-3D_600.jpg" shape="square" :size="50" icon="user" class = "avatar"/>
                    {{name}}
                    <a-divider type = 'vertical' />
                    <a-tag v-for="(item,index) in description" :key='index' :color="index===0 ? '#7F7F7F' : '#5B5B5B'" >{{item}}</a-tag>
                    담당자 : 홍길동
                </a-space>
            </a-col>
            <a-col class="gutter-row" :span="4">
                <a-button type="secondary" shape="" icon="edit" size = "small" @click = 'editEquipment'>Edit</a-button>
                <a-divider type = 'vertical' />
                <a-button type="secondary" shape="" icon="delete" size = "small" @click = 'deleteEquipment'>Delete</a-button>
                <!-- <span class = 'icon_button' @click = 'editEquipment'><a-icon type="edit" /> Edit</span>
                <a-divider type = 'vertical' />
                <span class = 'icon_button' @click = 'deleteEquipment'><a-icon type="delete" /> Delete</span> -->
            </a-col>
        </a-row>
        <!-- <a-row v-else-if = "type === 'operator'" :gutter="16" type="flex" justify="space-around" align="middle"> -->
            <div v-else-if = "type === 'operator'">
                <a-space align="baseline" >
                    <a-avatar src = "https://static.turbosquid.com/Preview/001180/025/TE/conveyor-belt-3D_600.jpg" shape="square" :size="50" icon="user" class = "avatar"/>
                    {{name}}
                    <a-divider type = 'vertical' />
                    <!-- <a-tag v-for="(item,index) in description" :key='index' :color="index===0 ? '#7F7F7F' : '#5B5B5B'" >{{item}}</a-tag> -->
                    <a-tag>담당 : 홍길동</a-tag>
                    <a-tag>적용모델 : 정수기</a-tag>
                    <a-tag>적용모델 : 청정기</a-tag>
                </a-space>
            </div>
            <!-- <a-col class="gutter-row" :span="3">
                <div class="gutter-box">
                    <a-avatar src = "https://static.turbosquid.com/Preview/001180/025/TE/conveyor-belt-3D_600.jpg" shape="square" :size="50" icon="user" class = "avatar"/>
                </div>
            </a-col>
            <a-col class="gutter-row" :span="10">
                <div class="gutter-box">
                    {{name}}
                    <a-divider type = 'vertical' />
                    <a-tag v-for="(item,index) in description" :key='index' :color="index===0 ? '#7F7F7F' : '#5B5B5B'" >{{item}}</a-tag>
                </div>
            </a-col>
            <a-col class="gutter-row" :span="13">
                담당자 : 홍길동
            </a-col> -->
        <!-- </a-row> -->
        <a-row v-else :gutter="16" type="flex" justify="space-around" align="middle">
            <a-col class="gutter-row" :span="3">
                <div class="gutter-box">
                    <a-avatar src = "https://static.turbosquid.com/Preview/001180/025/TE/conveyor-belt-3D_600.jpg" shape="square" :size="50" icon="user" class = "avatar"/>
                </div>
            </a-col>
            <a-col class="gutter-row" :span="10" @click = 'selectEquipment'>
                <div class="gutter-box">
                    {{name}}
                </div>
            </a-col>
            <a-col class="gutter-row" :span="11" style = 'padding-right:10px;text-align:right'>
                <a-tag v-for="(item,index) in description" :key='index' :color="index===0 ? '#7F7F7F' : '#5B5B5B'" >{{item}}</a-tag>
            </a-col>
        </a-row>
    </div>
</template>
<script>
export default {
    name: 'Equipment',
    props : ['name','type','description'],
    methods : {
        selectEquipment() {
            this.$emit('selectEquipment');
        },
        editEquipment() {
            console.log("## editProduct in Equipment ##");
            this.$emit('editEquipment');
        },
        deleteEquipment() {
            console.log("## deleteEquipment in Equipment ##");
            this.$emit('deleteEquipment');
        }
    }
}
</script>
<style scoped>
    .div_list {
        border : 1px solid #ECEAEA;
        padding : 10px 10px 10px 10px;
    }
    .avatar {
        border : 1px solid #ECEAEA;
        padding : 5px 5px 5px 5px;
        /* border-radius: 50%; */
    }

</style>