/****************************************************************************************
 * File Name    : Report.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
    <div>
        <a-result status="404" title="404" sub-title="Sorry, the page you visited does not exist." />
    </div>
</template>
<script>
export default {
    name: 'Report',
    components: {
    },
    data() {
        return {
            isDataLoaded : false,
        };
    },
    mounted() {

        this.isDataLoaded = true;
    },
    methods : {

    }
}
</script>
<style scoped>
</style>