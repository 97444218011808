/****************************************************************************************
 * File Name    : SheetToolControls.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
    <div>
        <a-row :gutter="16">
            <a-col class="gutter-row" :span="12">
                <div class="gutter-box">
                    <PageHeader title = "치공구/계측기 관리 시트  작성" />
                </div>
            </a-col>
            <a-col class="gutter-row" :span="12">
                <div class="gutter-box div_action">
                    <span class = 'icon_button' >
                        <a-tag color="#2980B9">
                            <a-icon type="plus-circle" /> 작성
                        </a-tag>
                    </span>
                </div>
            </a-col>
        </a-row>
        <a-divider type = 'horizontal' />
        <a-space align="end">
            <a-statistic title="작성필요" :value="1128" style="margin-right: 50px">
                <template #prefix>
                    <a-icon type="check-circle" />
                </template>
                <template #suffix>
                    (90%)
                </template>
            </a-statistic>
            <a-statistic title="작성중" :value="60" style="margin-right: 50px">
                <template #prefix>
                    <a-icon type="question-circle" />
                </template>
                <template #suffix>
                    (10%)
                </template>
            </a-statistic>
             <a-statistic title="작성완료" :value="60" style="margin-right: 50px">
                <template #prefix>
                    <a-icon type="exclamation-circle" />
                </template>
                <template #suffix>
                    (10%)
                </template>
            </a-statistic>
            <a-radio-group style = ''>
                <a-radio-button value="작성필요">작성필요 보기</a-radio-button>
                <a-radio-button value="작성중">작성중 보기</a-radio-button>
                <a-radio-button value="작성완료">작성완료 보기</a-radio-button>
                <a-radio-button value="모두">모두보기</a-radio-button>
            </a-radio-group>
        </a-space>
        <p/>
        <a-progress type = 'line' :percent="90" strokeColor = '#C7C6C6'/>
        <p/>
        <a-table :columns="columns" size = "small" :bordered=false :data-source="data" :pagination="false" :scroll="{ x: 0, y: '57vh' }">
            <span slot="tags" slot-scope="tags">
                <a-tag v-for="tag in tags" :key="tag" :color="tag === '보류' ? '#CDCDCD' : '#929292'" >
                    {{ tag.toUpperCase() }}
                </a-tag>
            </span>
            <span slot="name" slot-scope="text">{{ text }}</span>
            <span slot="action" slot-scope="">
                <span><a-icon type="check-circle" /> Confirm</span>
                <a-divider type="vertical" />
                <span><a-icon type="edit" /> Edit</span>
                <a-divider type="vertical" />
                <span><a-icon type="delete" /> Delete</span>
            </span>
        </a-table>
        <p/>
        <a-form class="" :form="form" layout = "vertical" >
            <a-card>
                <a-form-item label="점검시트 작성 일자설정">
                    <a-space align="end">
                        <a-date-picker />
                        <a-radio-group style = ''>
                            <a-radio-button value="작성필요">작성필요 보기</a-radio-button>
                            <a-radio-button value="작성중">작성중 보기</a-radio-button>
                            <a-radio-button value="작성완료">작성완료 보기</a-radio-button>
                            <a-radio-button value="모두">모두보기</a-radio-button>
                        </a-radio-group>
                    </a-space>
                </a-form-item>
                <p/>
                <!--  -->
                <a-table :columns="columns2" size = "small" :bordered=false :data-source="data" :pagination="false" :scroll="{ x: 0, y: '57vh' }">
                    <span slot="tags" slot-scope="tags">
                        <a-tag v-for="tag in tags" :key="tag" :color="tag === '보류' ? '#CDCDCD' : '#929292'" >
                            {{ tag.toUpperCase() }}
                        </a-tag>
                    </span>
                    <span slot="name" slot-scope="text">{{ text }}</span>
                    <span slot="action" slot-scope="">
                        <a-checkbox @change="onChange">
                            선택
                        </a-checkbox>
                    </span>
                </a-table>
                <!--  -->
            </a-card>
        </a-form>
    </div>
</template>
<script>
import PageHeader from "../components/PageHeader.vue";
const columns = [
    {    title: '상태'    ,   dataIndex: 'tags' ,   key: 'tags'     ,  scopedSlots: { customRender: 'tags'        },  width : 100},
    {    title: '이름'    ,   dataIndex: 'name' ,   key: 'name'     ,  scopedSlots: { customRender: 'name'        },},
    {    title: '관리'                          ,   key: 'action'   ,  scopedSlots: { customRender: 'action'      },  width : 250},
];
const columns2 = [
    {    title: '관리'                          ,   key: 'action'   ,  scopedSlots: { customRender: 'action'      },  width : 100},
    {    title: '상태'    ,   dataIndex: 'tags' ,   key: 'tags'     ,  scopedSlots: { customRender: 'tags'        },  width : 100},
    {    title: '이름'    ,   dataIndex: 'name' ,   key: 'name'     ,  scopedSlots: { customRender: 'name'        },},

];
const data = [
  {    key: '1',    name: 'FGCS210505-WP400-V1 보류중 20200401-15:30'   ,  tags: ['필요'],  },
  {    key: '2',    name: 'FGCS210505-WP301-V1 보류중 20200329-09:30'   ,  tags: ['필요'],  },
  {    key: '3',    name: 'PCCS210505-WP400-V1 완료 20200401-15:30'     ,  tags: ['진행'],  },
  {    key: '4',    name: 'PCCS210505-WP301-V1 완료 20200329-09:30'     ,  tags: ['완료'],  },
];
export default {
    name: 'SheetToolControls',
    components: {
        PageHeader      ,
        // Tool           ,
        // ToolForm
    },
    data() {
        return {
            isDataLoaded : false,
            flagForDrawer: false,
            data,
            columns,
            columns2,
        };
    },
    mounted() {
        this.isDataLoaded = true;
    },
    methods : {
        // showDrawer() {
        //     this.flagForDrawer = true;
        // },
        // closeDrawer() {
        //     this.flagForDrawer = false;
        // },
        // editTool() {
        //     console.log("## editTool in Tools ##");
        //     this.flagForDrawer = true;
        // },
        // deleteTool() {
        //     console.log("## deleteTool in Tools  ##");
        //     this.flagForDrawer = true;
        // }
    }
}
</script>
<style scoped>
</style>