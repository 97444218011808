/****************************************************************************************
 * File Name    : Data.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
    <div style="margin-left: -24px; margin-right: -24px;">
        <a-row :gutter="16">
            <a-col :span="3">
                
            </a-col>
            <a-col :span="21">
                <a-row :gutter="16">
                    <a-col class="gutter-row" :span="24">
                        <div class="gutter-box">
                            <PageHeader title = "4M 변경 현황" />
                        </div>
                    </a-col>
                </a-row>
                <a-divider type = 'horizontal' />
                <a-table :columns="columns3" size = "small" :bordered=false :data-source="csTableData5" :pagination="{ pageSize: 15 }" :scroll="{ x: 0, y: '80vh' }">
                </a-table>
                <p/>
            </a-col>
        </a-row>
    </div>
</template>
<script>
const columns3 = [
    {    title: '라인'    ,   dataIndex: 'line'       ,   key: 'line'      , },
    {    title: '모델명'  ,   dataIndex: 'model'   ,   key: 'model'   , },
    {    title: '부품명',   dataIndex: 'part'     ,   key: 'part'     ,     },
    {    title: '변경 내역',   dataIndex: 'change'  ,   key: 'change'  ,  scopedSlots: { customRender: 'control'       } },
    {    title: '최종수정일',  dataIndex: 'updated', key: 'updated'  ,  scopedSlots: { customRender: 'updated'     },},
];

import _ from "lodash";
import PageHeader      from "../components/PageHeader.vue";
export default {
    name: 'DashboardDetailPatrol4m',
    components: {
        PageHeader
    },
    data() {
        return {
            isDataLoaded : false,
            flagForDrawer: false,
            data : [],
            dataArray: [],
            columns3,
            csData2 : [],
            csTableData5 : [],  // 4M 변경 현황
            date: {
                currentYear: null,
                currentMonth: null,
                months: []
            }
        };
    },
    mounted() {
        this.loadList();

        let dateObj = new Date();
        this.date.currentYear = dateObj.getFullYear();
        this.date.currentMonth = dateObj.getMonth() + 1;
        for (let i = 1; i <= this.date.currentMonth; i++) {
            let month = i;
            if (month < 10) {
                month = '0' + i;
            }
            this.date.months.push(month);
        }
        if (this.date.currentMonth < 10) {
            this.date.currentMonth = '0' + this.date.currentMonth;
        }
    },
    methods : {
        loadList() {
            this.csData2 = [];
            let urlToFetchProductCSList = "https://cowaylqc.iotinfras.com:8100/product/cs/list?size=10000&page=0";
            this.$http.get(urlToFetchProductCSList)
            .then((response) => {
                if(response.status === 200 && response.data.code === 'success') {
                    this.csData2 = _.filter(response.data.data.content, (item) => {
                        // return (item.state === 'G' || item.state === 'H') && item.updated.substr(0, 4) == this.date.currentYear;   // 올해 데이터
                        return (item.state === 'H' || item.state === 'L') && item.updated.substr(0, 4) == this.date.currentYear;   // 올해 데이터
                    });
                    this.csData2 = _.orderBy(this.csData2, ['updated'], ['asc']);  // 수정 날짜순 정렬
                }

                this.flagSpinner = false;
                
                // 4M 변경 현황
                _.forEach(this.csData2, (item, i) => {
                    item.ng = false;
                    let obj = {};
                    _.forEach(item.listProductCSInpection, (item2) => {
                        if (item2.inspectionTarget === '4M') {
                            let temp = item2.listProductCSInpectionStandard[0].min.split('|');
                            obj.part = temp[0];

                            let temp2 = item2.listProductCSInpectionStandard[0].result.split('|');
                            obj.change = temp2[0];
                        }
                    });
                    // 4M 변경 현황 테이블 데이터 생성
                    if (obj.part) {
                        obj.key = i;
                        obj.model = item.clsProduct.productModel;
                        obj.line = item.line;
                        obj.updated = item.updated;
                        this.csTableData5.push(obj);
                    }
                });
                this.csTableData5 = this.csTableData5.reverse();
            }).catch(error => {
                console.log(error);
                this.flagSpinner = false;
            });
        },
    }
}
</script>
<style scoped>
</style>