/****************************************************************************************
 * File Name    : EquipmentForm.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
    <div class = ''>
        <!-- v-bind="formItemLayout" -->
        <a-form class="" :form="form" layout = "vertical" @submit="onSubmit">
            <a-card>
                <a-form-item label="라인명">
                    <a-input placeholder="" v-model="selectedLineinfo.lineName" />
                </a-form-item>
                <a-form-item label="점검자">
                    <a-input placeholder="" v-model="selectedLineinfo.manager" />
                </a-form-item>
                <a-form-item label="관리자1">
                    <a-input placeholder="" v-model="selectedLineinfo.admin1" />
                </a-form-item>
                <a-form-item label="관리자2">
                    <a-input placeholder="" v-model="selectedLineinfo.admin2" />
                </a-form-item>
            </a-card>
            <p/>
            <a-button type="primary" html-type="submit" :ghost = false size = "default" icon = "plus-circle">Save</a-button>
        </a-form>

    </div>
</template>
<script>
// import _ from "lodash";
export default {
    name: 'EquipmentLineForm',
    props: ['targetProduct', 'lineinfo', 'selectedLineinfo'],
    data() {
        return {
            formItemLayout: {
                labelCol: { span: 3 },
                wrapperCol: { span: 21 },
            },
        };
    },
    mounted() {
        console.log('lineinfo', this.lineinfo, 'targetProduct', this.targetProduct)
    },
    watch: {
        targetProduct: function(newOne){
            this.targetProduct = newOne;
        },
        selectedLineinfo: function(newOne){
            this.selectedLineinfo = newOne;
        },
    },
    beforeCreate() {
        this.form = this.$form.createForm(this, { name: 'validate_other' });
    },
    methods : {
        onSubmit(e) {
            e.preventDefault();

            let editData = JSON.parse(JSON.stringify(this.selectedLineinfo));
            delete(editData.valid);
            delete(editData.beginDate);
            delete(editData.endDate);

            let apiUrl;

            // 수정
            if (editData.equipmentLineInfoSeq) {
                apiUrl = this.$store.state.hostInService + "/equipment/lineinfo/edit";
                this.$http.put(apiUrl,editData)
                .then((response) => {
                    console.log(response);
                    if(response.status === 200 && response.data.code === 'success') {
                        this.buffer = response.data.data;
                    }
                    this.$message.success("수정되었습니다.");
                    this.$emit('editEquipment');
                })
                .catch(error => {
                    console.log(error);
                    this.$message.error("수정되지 않았습니다.");
                });
            }
            // 생성
            else {
                editData.equipmentLineInfoSeq = 0;
                editData.targetProduct = this.targetProduct;
                apiUrl = this.$store.state.hostInService + "/equipment/lineinfo/add";
                this.$http.post(apiUrl,editData)
                .then((response) => {
                    console.log(response);
                    if(response.status === 200 && response.data.code === 'success') {
                        this.buffer = response.data.data;
                    }
                    this.$message.success("생성되었습니다.");
                    this.$emit('editEquipment');
                })
                .catch(error => {
                    console.log(error);
                    this.$message.error("생성되지 않았습니다.");
                });
            }
        },
    }
}
</script>
<style scoped>
    .ant-form-item  {
        margin-bottom: 5px !important;
    }
    .ant-form-item-label  {
        font-weight: 700  !important;
    }
</style>