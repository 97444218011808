/****************************************************************************************
 * File Name    : PatrolProduct.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
    <div>
         <a-row :gutter="4">
            <a-col class="gutter-row" :span="12">
                <div class="gutter-box">
                    <PageHeader title = "신규 검사 성적서 생성" />
                </div>
            </a-col>
            <a-col class="gutter-row" :span="12">
                <div class="gutter-box div_action">
                </div>
            </a-col>
        </a-row>
        <a-divider type = 'horizontal' />
        <p/>
        <div style = 'margin:auto;width:100%;text-align:left;margin-top:10px'>
            <a-button icon="plus-circle" @click = "handlerForButtonNew" :type = "(isVisibleForTemplate || createFromTemplate) ? 'secondary' : 'primary'"> 새로작성하기 </a-button>
            <a-divider type = 'vertical' />
            <a-button icon="plus-circle" @click = "handlerForButtonTemplate" :type = "(isVisibleForTemplate || createFromTemplate) ? 'primary' : 'secondary'"> Template 불러오기 </a-button>
        </div>
        <div v-if = "isVisibleForTemplate === true" style = 'margin-top:20px;padding:10px 10px 10px 10px;border:0px solid #CCC'>
            <div class = "todo">해당 텝플릿을 클릭하면, '새로작성하기' 의 Step 을 따른다. </div>
            <a-table :columns="columnForTemplate" size = "small" :bordered=false :data-source="data2" :pagination="false" :scroll="{ x: 0, y: '57vh' }">
                <span slot="tags" slot-scope="tag">
                    <a-tag :color="tag === '보류' ? '#CDCDCD' : '#929292'">
                        {{ tag.toUpperCase() }}
                    </a-tag>
                </span>
                <span slot="name" slot-scope="text">{{ text }}</span>
                <span slot="action" slot-scope="record">
                    <span @click="handlerToCreateProductCS(record.key)" style="cursor:pointer;"><a-icon type="edit" /> 템플릿 선택</span>
                </span>
            </a-table>
        </div>
        <div v-else-if = "isVisibleForNew === true" style = 'margin-top:20px;padding:10px 10px 10px 10px;border:0px solid #CCC'>
            <a-row type="flex" justify="center" align="top" >
                <a-col :span="6" style = 'border-right:1px solid #CCC'>
                     <div class = "todo">CS 설정을 단계별로 진행한다</div>
                    <a-steps direction="vertical" :current="step">
                        <a-step title="검사대상 선택" description="" />
                        <a-step title="검사항목 설정" description="" />
                        <a-step title="완료" description="" />
                    </a-steps>
                    <div style = 'width:100%;text-align:center;margin-top:20px'>
                            <a-divider type = 'horizontal' />
                            <div class = 'todo'>Step 상태에 따라 버튼은 Disable / Enable 어야 한다.</div>
                            <a-button icon="rollback" type = 'danger' :ghost=true @click = "setStepPrev" :disabled=false> 이전 </a-button>
                            <a-divider v-if="step != 2" type = 'vertical' />
                            <a-button v-if="step != 2" icon="check" type = 'primary' :ghost=true @click = "setStepNext"> 다음 </a-button>
                    </div>
                </a-col>
                <a-col :span="18">
                    <div style = 'padding:0px 10px 10px 20px'>
                        <div v-if = "step === 0">
                            <div>
                                <a-button @click="category = '원자재'" :type = "(category === '원자재') ? 'primary' : 'secondary'">원자재</a-button>
                                <a-button @click="category = '카본블록'" :type = "(category === '카본블록') ? 'primary' : 'secondary'" style="margin-left:20px;">카본블록</a-button>
                                <a-button @click="category = '엘리먼트'" :type = "(category === '엘리먼트') ? 'primary' : 'secondary'" style="margin-left:20px;">엘리먼트</a-button>
                                <a-button @click="category = '필터'" :type = "(category === '필터') ? 'primary' : 'secondary'" style="margin-left:20px;">필터</a-button>
                            </div>

                            <div style = 'margin:50px 0 20px; width:300px;'>
                                <a-form-item label="부품품명">	
                                    <a-input placeholder="부품품명" v-model="partName" v-decorator="['partName',{rules: [{ required: true, message: '부품품명을 입력하세요' }],initialValue:partName}]"  />	
                                </a-form-item>
                            </div>

                            <div style = 'margin:20px 0 50px; width:300px;'>
                                <a-form-item label="부품코드">	
                                    <a-input placeholder="부품코드" v-model="partCode" v-decorator="['partCode',{rules: [{ required: true, message: '부품코드를 입력하세요' }],initialValue:partCode}]"  />	
                                </a-form-item>
                            </div>

                            <div style = 'margin:50px 0'>
                                <a-spin :spinning="spinning">
                                    <a-upload name="file" :multiple="false" :action="$store.state.imageUploadServer" @change="image1Upload"
                                        v-decorator="['image1',{rules: [{ required: false, message: '사진을 선택하세요' }],},]">
                                        <a-button> <a-icon type="camera" /> 도면 및 관리포인트 </a-button>
                                    </a-upload>
                                </a-spin>
                                <br />
                                <a-spin :spinning="spinning">
                                    <a-upload name="file" :multiple="false" :action="$store.state.imageUploadServer" @change="image2Upload"
                                        v-decorator="['image2',{rules: [{ required: false, message: '사진을 선택하세요' }],},]">
                                        <a-button> <a-icon type="camera" /> 중점관리항목 </a-button>
                                    </a-upload>
                                </a-spin>
                            </div>
                        </div>
                        <div v-if = "step === 1">
                            <a-row type="flex" justify="center" align="top" :gutter="16">
                                <a-col :span="3" >
                                    <a-button icon="plus-circle" @click = "handlerForButtonNewElement" type = 'primary' :ghost=true size = "small"> 검사분류추가 </a-button>
                                </a-col>
                                <a-col :span="1" />
                                <a-col :span="20" style = 'border-left:1px solid #CCC;padding-left:20px'>
                                    <div style = 'height:60vh;overflow:auto;'>
                                        <!-- editable-card -->
                                        <a-tabs v-model="activeKey" hide-add type="editable-card" tab-position="right" @edit="onEdit" :key="keyForTab">
                                            <a-tab-pane v-for="(target, pane_index) in listTarget" :key="pane_index" :tab="'검사분류 '+(pane_index+1)" :closable="listTarget.length > 1">
                                                <table style='display: none; width:100%; border: 1px solid #e8e8e8'>
                                                    <thead class="ant-table-thead">
                                                        <tr>
                                                            <th>검사 대상</th>
                                                            <th>검사 항목</th>
                                                            <th>검사 기준</th>
                                                            <th>이미지</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="ant-table-tbody">
                                                        <tr>
                                                            <td rowspan="6">제품포장</td>
                                                            <td>박스 인쇄</td>
                                                            <td>인쇄상태 이상없을 것</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td rowspan="2">박스 바코드</td>
                                                            <td>바코드 정보 훼손 없을 것</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>제품박스와 바코드 모델 일치할 것</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td rowspan="3">첨가물</td>
                                                            <td>설명서 및 설치자재 누락없을 것</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>아답터 누락 없을것</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>아답터 국가별 사양 일치할 것</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td rowspan="7">제품포장</td>
                                                            <td rowspan="3">프론트커버</td>
                                                            <td>프론트 전면 인쇄상태 이상없을 것</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>외관 스크레치 없을 것</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>주의, 에너지효율 스티커 누락 없을 것</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td rowspan="2">탑커버</td>
                                                            <td>외관 스크레치 없을 것</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>주의 스티커 누락 없을 것</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td rowspan="2">사이드커버</td>
                                                            <td>외관 스크레치 없을 것</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>스티커 부착 상태 이상없을 것</td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <!--BaseInput placeholder="공정명" v-model="listTarget[pane_index].title"/-->	
                                                <a-form :form="form">	
                                                    <a-form-item :label="cInspectionTargetTitle">	
                                                        <a-input placeholder="분류" v-decorator="['inspectionTargetName'+pane_index,{rules: [{ required: true, message: '분류를 입력하세요' }],initialValue:listTarget[pane_index].title}]" v-on:input="event=>{listTarget[pane_index].title = event.target.value} "  />	
                                                    </a-form-item>	
                                                </a-form>	
                                                <div v-for = "(productCSInspection, i) in target.listInspectionItem" :key="'' + pane_index + i">	
                                                    <ModalCheckItemFilter v-model="listTarget[pane_index].listInspectionItem[i]" :viewIndex="i" :parentListSize="listTarget[pane_index].listInspectionItem.length" :tabTitle="target.title"  v-on:removeItem="handlerForButtonRemoveInnerElement"  />	
                                                </div>	
                                                <p/>	
                                                <a-button icon="plus-circle" @click = "handlerForButtonNewInnerElement()" :ghost=true  type = 'primary' size = "small" style = 'margin-right: 10px;'> 검사항목추가</a-button>	
                                                <!-- <a-button v-if = "pane.child.length > 0" icon="minus-circle" @click = "handlerForButtonRemoveInnerElement(pane.key)" :ghost=true  type = 'danger' size = "small" style = 'margin-right: 10px;'> 검사항목삭제</a-button> -->	
                                                <!-- <a-button icon="plus-circle" @click = "handlerForButtonNewInnerElement(pane.key, 'sub')" :ghost=true  type = 'primary' size = "small" style = 'margin-right: 10px;'> 세부검사기준추가</a-button> -->	
                                                <!-- <a-button v-if = "pane.child.length > 0" icon="minus-circle" @click = "handlerForButtonRemoveInnerElement(pane.key)" :ghost=true  type = 'danger' size = "small" style = 'margin-right: 10px;'> 세부검사기준삭제</a-button> -->
                                            </a-tab-pane>
                                        </a-tabs>
                                    </div>
                                </a-col>
                            </a-row>
                        </div>
                        <div v-if = "step === 2">
                            <div class = 'todo'>설정된 CS의 검사시간을 설정한다.</div>
                            <!-- <ModalSetTime /> -->
                            <div style = 'text-align:center;margin-top:50px'>
                                <!-- <div class = 'todo'>'입력내용지우기' 클릭시 입력내용을 지우고, 처음으로, '임시저장' 보류상태로 저장</div>
                                <a-button icon="rollback" type = 'danger' :ghost=true> 입력내용지우기 </a-button>
                                <a-divider type = 'vertical' /> -->
                                <a-button icon="pause" type = 'secondary' @click="handlerToSaveCSForProudct('M')"> 입력내용 임시저장 </a-button>
                                <a-divider type = 'vertical' />
                                <a-button icon="check" type = 'primary' :ghost=true @click="handlerToSaveCSForProudct('N')"> 완료 </a-button>
                            </div>
                        </div>
                    </div>
                </a-col>
            </a-row>
        </div>
        <a-divider type = 'horizontal' />
    </div>
</template>
<script>
import _ from "lodash";
import PageHeader from "../components/PageHeader.vue" ;
import ModalCheckItemFilter from "../modals/ModalCheckItemFilter.vue" ;
// import ModalSetTime from "../modals/ModalSetTime.vue" ;
//import BaseInput from "../components/BaseInput.vue"
const MAX_STEP = 2;
const MIN_STEP = 0;
// TODO : 제품테이블은 공통모듈화 해야함.
const columns = [
    { title: '선택',key: 'action',scopedSlots: { customRender: 'action'},  width : 100},
    { title: 'image',dataIndex: 'image',key: 'image',  scopedSlots: { customRender: 'image'},  width : 150},
    { title: '모델명',dataIndex: 'model',key: 'model',  scopedSlots: { customRender: 'model'},},
    { title: '세부모델명',dataIndex: 'modelDetail',key: 'modelDetail',  scopedSlots: { customRender: 'modelDetail'},},
    { title: '제품명',dataIndex: 'name',key: 'name',  scopedSlots: { customRender: 'name'},},
    { title: 'POP',dataIndex: 'pop',key: 'pop',  scopedSlots: { customRender: 'pop'},},
    { title: 'SAP',dataIndex: 'sap',key: 'sap',  scopedSlots: { customRender: 'sap'},},

];
const data = [];
const columnForTemplate = [
    {    title: '모델명' , dataIndex: 'model' , key: 'model' , scopedSlots: { customRender: 'model' },},	
    {    title: '세부모델명' , dataIndex: 'modelDetail' , key: 'modelDetail' , scopedSlots: { customRender: 'modelDetail' },},	
    {    title: '제품명' , dataIndex: 'product' , key: 'product' , scopedSlots: { customRender: 'name' },},	
    {    title: '최종수정일' , dataIndex: 'updated' , key: 'updated' , scopedSlots: { customRender: 'name' },},	
    {    title: '관리' , key: 'action' , scopedSlots: { customRender: 'action' }, width : 250},
];
const dataForTemplate = [
  {    key: '3', name: 'PCCS210505-WP400-V1 완료 20200401-15:30' ,  tag: '완료',  },
  {    key: '4', name: 'PCCS210505-WP301-V1 완료 20200329-09:30' ,  tag: '완료',  },
];
export default {
    name: 'FilterProduct',
    components: {
        PageHeader,
        ModalCheckItemFilter,
        // ModalSetTime,
        //BaseInput,	
    },
    data() {
        //const panes = [{ title: '공정명 1', content: '', key: '1' , order: 1, child : []},];
        return {
            cInspectionTargetTitle      : '분류',
            MAX_STEP                    ,
            MIN_STEP                    ,
            step                        : 0,
            data                        ,
            dataBackup                  : [],
            columns                     ,
            dataForTemplate             ,
            columnForTemplate           ,
            selectedProduct             : '',
            // itemTotal                   : 1,
            // panes                       ,
            activeKey                   : 0,
            // newTabIndex                 : 1,
            keyForTab                   : 0,
            flagForSpinner              : false,
            isVisibleForNew             : true,
            isVisibleForTemplate        : false,
            // isSelectProduct             : false,
            // isVisibleForModalCheckItem  : false,
            // isVisibleForModalSetTime    : false,
            currentPage                 : 0,
            editCS                      : this.$route.params && this.$route.params.editCS,	
            listTarget                  : [],	
            data2                       : [],	
            dataArray2                  : [],	
            createFromTemplate          : false,	
            form : this.$form.createForm(this, {	
                name: 'ModalCheckItemFilter',	
            }),
            filterProduct               : '',
            spinning : false,
            category: null,
            partName: null,
            partCode: null,
            image1: null,
            image2: null,
        };
    },
    computed : {
        createdCSTotal() {
            if(this.listTarget.length == 1)	
                 return '기본항목이 설정되었습니다';	
            else return '항목 (' + this.listTarget.length + ') 건이 설정되었습니다';
        }
    },
    mounted() {
        this.flagForSpinner = false;
        this.fetchProductCSList(() => { // cs list 를 먼저 조회후 제품 리스트 조회
            this.loadProductList();
        });

        // CS 수정인 경우	
        if (this.$route.params && this.$route.params.editCS){	
            console.log('editCS', this.$route.params.editCS);	
            this.step = 1;
        }
    },
    methods : {
        makeInspection(){	
            let productCSInspectionStandard = {	
                name : '',	
                min : '',	
                max : '',	
                result : '',	
                inspectionImageSeq            : 0,	
            };	
            let productCSInspection = {	
                inspectionIndex               : 0,	
                inspectionOffset              : 0,	
                inspectionName          : '',	
                inspectionTarget    : '',	
                listProductCSInpectionStandard            : [productCSInspectionStandard],	
                //inspectionImageSeq            : 0,	
                inspectionDescription         : '',	
            };	
            return productCSInspection;	
        },
        loadProductList() {
            this.data = [];
            this.flagForSpinner = true;
            let urlForProduct = 'https://cowaylqc.iotinfras.com:8100/product/list?size=1000&page=' + this.currentPage;
            this.$http.get(urlForProduct)
            .then((response) => {
                // 전체자료수 :
                let data = response.data.data;
                console.log("전체자료수 : " + data.totalElements);
                console.log("전체페이지수 : " + data.totalPages);
                console.log("현재페이지 :  " + data.number);
                console.log("현재자료수 :  " + data.numberOfElements);
                console.log(data.content[0]);
                if(response.status === 200 && response.data.code === 'success') {
                    let dataArray = response.data.data.content;
                    dataArray = dataArray.reverse(); // 최종생성된 것이 위로 가도록 정렬
                    for(let x = 0; x < dataArray.length; x ++ ) {
                        let type;

                        // WATER: 정수기, AIR: 청정기, ETC: 기타기기
                        let temp = _.find(dataArray[x].listProductType, {
                            productTypeName: 'WATER'
                        });
                        if (temp) {
                            type = 'WATER';
                        } else {
                            let temp2 = _.find(dataArray[x].listProductType, {
                                productTypeName: 'AIR'
                            });
                            if (temp2) {
                                type = 'AIR';
                            } else {
                                let temp3 = _.find(dataArray[x].listProductType, {
                                    productTypeName: 'ETC'
                                });
                                if (temp3) {
                                    type = 'ETC';
                                }
                            }
                        }

                        if (type === 'WATER' || type === 'AIR' || type === 'ETC') {
                            let disabled = false;
                            _.forEach(this.data2, (product) => {
                                if (product.productSeq == dataArray[x].productSeq) {
                                    disabled = true;
                                }
                            });

                            let element = {
                                key : x,
                                seq : dataArray[x].productSeq,
                                name : dataArray[x].productName,
                                model : dataArray[x].productModel,
                                modelDetail : dataArray[x].productModelDetail,
                                tags : [dataArray[x].productPop,dataArray[x].productSap],
                                pop: dataArray[x].productPop,
                                sap: dataArray[x].productSap,
                                checked : false,
                                enabled : true,
                                image : this.$store.state.hostInService + '/upload/image/download/' + dataArray[x].uploadFileSeq,
                                disabled: disabled
                            }

                            // CS 수정인 경우	
                            if (this.editCS && this.editCS.clsProduct) {	
                                if (element.seq === this.editCS.clsProduct.productSeq) {	
                                    this.listTarget = [];	
                                    this.fetchProductCSList();	
                                    // 생성할 때 선택하였던 제품이 선택되어 있도록	
                                    element.checked = true;	
                                    this.selectedProduct = '';  // 제품명 표시
                                    this.$store.commit('setProductSeq',element.seq);	
                                    this.$store.commit('setProductCode',element.model);	
                                    
                                    let listProductCSInspection = this.editCS.listProductCSInpection;	
                                    // Sort 를 해야 한다 나중에 BackEnd에서 해야 할 듯	
                                    listProductCSInspection.sort((a,b)=> { 	
                                        if(a.inspectionIndex == b.inspectionIndex){	
                                            return a.inspectionOffset - b.inspectionOffset;	
                                        }else{	
                                            return a.inspectionIndex - b.inspectionIndex;	
                                        }	
                                    });  	
                                    // ~~	
                                    console.log('listProductCSInspection -------------------------------------------------------');	
                                    console.log(listProductCSInspection);	
                                    console.log('-------------------------------------------------------');	
                                    let dicInspectionIndex = {};	
                                    for(let i = 0; i < listProductCSInspection.length ; i ++) {	
                                        let productCSInspection = listProductCSInspection[i];	
                                        // Image Seq를  listProductCSInspectionStandard[0] 로 옮긴다 	
                                        productCSInspection.listProductCSInpectionStandard[0].inspectionImageSeq = productCSInspection.inspectionImageSeq; 	
                                        console.log('Target: ',productCSInspection.inspectionTarget,'Index: ',productCSInspection.inspectionIndex, 'Offset: ', productCSInspection.inspectionOffset)	
                                        let key = "" + productCSInspection.inspectionIndex;	
                                        if(dicInspectionIndex[key] == null){	
                                            //공정명이 신규인 경우	
                                            let index = Object.keys(dicInspectionIndex).length	
                                            dicInspectionIndex[key] = index;	
                                            let dicTarget = {	
                                                title: productCSInspection.inspectionTarget,	
                                                listInspectionItem: [productCSInspection]	
                                            }	
                                            //dicTarget['title'] = '공정명 ' + (index +1);	
                                            //dicTarget['listInspectionItem'] = [productCSInspection];	
                                            this.listTarget.push(dicTarget);	
                                        }else{	
                                            // 공정명이 있는 경우	
                                            let index = dicInspectionIndex[key];        // 검사 대상 Index	
                                            // inspectionName 이 있으면 검사항목으로 없으면 Standard 로 	
                                            if(productCSInspection.inspectionName != ""){	
                                                this.listTarget[index].listInspectionItem.push(productCSInspection);	
                                            }else{	
                                                // Standard 의 경우는 list ProductCS Inspection Standard 에 추가 한다 	
                                                this.listTarget[index].listInspectionItem.at(-1).listProductCSInpectionStandard.push(productCSInspection.listProductCSInpectionStandard[0]);	
                                            }	
                                        }	
                                    }	
                                    console.log('listTarget -------------------------------------------------------');	
                                    console.log(this.listTarget);	
                                    console.log('-------------------------------------------------------');	
                                    this.activeKey = 0;	
                                    console.log('listTarget', this.listTarget);	
                                }	
                            }

                            this.data.push(element);
                            this.dataBackup = JSON.parse(JSON.stringify(this.data));
                        }
                    }
                    // 신규 생성인 경우	
                    if(this.listTarget.length == 0){	
                        let dicTarget = {	
                            title: '',	
                            listInspectionItem: [this.makeInspection()]	
                        }	
                        this.listTarget.push(dicTarget);	
                    }
                }
            })
            .catch(error => {
                console.error(error);
            });
        },
        handlerForButtonNewElement() {
            // 공정명 추가 	
            let inspection = this.makeInspection();	
            console.log('handlerForButtonNewElement() inspection',inspection );	
            let dicTarget = {	
               title: "",	
               listInspectionItem: [inspection]	
            };	
            this.listTarget.push(dicTarget);	
            // Active 창을 새로 생성한 target으로 이동	
            this.activeKey = this.listTarget.length -1;	
            console.log('handlerForButtonNewElement() target',dicTarget );	

        },
        onEdit(targetKey, action) {
            this[action](targetKey);
        },
        remove(targetKey) {
            // 공정명을 지우는 경우	
            console.log('remove: targetKey: ', targetKey);	
            if (this.listTarget.length <= 1) {  // 개수가 한개일 경우는 삭제하지 않음 : remove button 이 비 활성화 되기 떄문에 여기 오지 않는다	
                return;	
            }	
            this.listTarget.splice(targetKey,1);	
            if(this.activeKey < targetKey){	
                // active 된 창 뒤의 것을 지운 경우	
                // 	
            }else{	
                // Active 된 창을 지웠거나 이전 것을 지운 경우	
                // Active 창이 첫 index인 경우를 제외하고는 index 을 하나 줄여야 한다 	
                if(this.activeKey >0){	
                    this.activeKey--;	
                }	
            }
        },
        setStepNext() {
            if(this.step < this.MAX_STEP) {
                this.step ++;
            }
        },
        setStepPrev() {
            if(this.step > this.MIN_STEP) {
                this.step --;
            }
        },
        handlerForSwitch(seq) {
            // DESC : 모든 스위치를 끈고, 선택된 제품의 스위치를 켠다
            for(let x = 0; x < this.data.length; x ++ ) this.data[x].checked = false;
            let checkedItem = _.find(this.data, {
                seq: seq
            });
            checkedItem.checked = true;
            // DESC : Phase 에 제품이름을 설정한다.
            this.selectedProduct = checkedItem.name;
            // DESC : 선택한 제품 시퀀스를 Store 에 보관한다.
            this.$store.commit('setProductSeq',checkedItem.seq);
            this.$store.commit('setProductCode',checkedItem.model);
        },
        handlerForButtonTemplate() {
            this.isVisibleForTemplate = true;
            this.isVisibleForNew = false;
        },
        handlerForButtonNew() {
            this.isVisibleForTemplate = false;
            this.isVisibleForNew = true;
        },
        handlerForButtonNewInnerElement() {	
            // 항목 추가 	
            console.log('handlerForButtonNewInnerElement()');	
            this.listTarget[this.activeKey].listInspectionItem.push(this.makeInspection());	
        },	
        handlerForButtonRemoveInnerElement(index) {	
            // 검사 항목 삭제	
            console.log('handlerForButtonRemoveInnerElement()');	
            this.listTarget[this.activeKey].listInspectionItem.splice(index,1);	
        },
        handlerToSaveCSForProudct(state) {
            // 공정명 : inspectionTarget
            // 검사항목 : inspection
            // 세부검사기준 : standard

            let objectToCS = [];

            console.log('listTarget -------------------------------------------------------');
            console.log(this.listTarget);
            console.log('-------------------------------------------------------');



            // listTarget 은 공정명 list 임 
            for(let i=0; i<this.listTarget.length;i++){

                let target = this.listTarget[i];
                // Target 은 title 과 listInspectionItem(검사항목) 로 구성됨
                let offset = 1;
                console.log('i: ',i);
                for(let j=0; j<target.listInspectionItem.length;j++){
                    let productCSInspection = target.listInspectionItem[j];
                    // 검사 항목 명의 유무에 따라 검사 항목인지 세부 검사 기준인지 결정된다 
                    let name =  productCSInspection.inspectionName;
                    if(name == ""){
                        // 사용자가 검사 항목을 넣지 않은 경우 검사항목을 넣는다 
                        name = "검사항목";
                    }
                    console.log('name: ',name);
                    for(let k=0; k<productCSInspection.listProductCSInpectionStandard.length; k++){
                        let standard = productCSInspection.listProductCSInpectionStandard[k];
                        // standard 는 세부 검사 기준임
                        // index 와 offset 은 중간에 편집 과정에서 다르게 설정 될수 있으므로 새롭게 정의 한다 
                        console.log('index: ',i+1, ', offset: ', offset);

                        let inspection = {
                            index: "" + (i+1),
                            offset: "" + offset++,
                            inspection: name,
                            inspectionTarget: target.title,
                            imageSeq: standard.inspectionImageSeq,
                            image2Seq: 0,
                            description: productCSInspection.inspectionDescription,
                            standard: {
                                max: standard.max,
                                min: standard.min,
                                name: standard.name,
                                result: standard.result
                            }
                        };
                        // 처음을 제외하고는 검사 항목을 지워야 한다
                        name = "";
                        objectToCS.push(inspection);
                    }
                }
            }
            console.log('objectToCS -------------------------------------------------------');
            console.log(objectToCS);
            console.log('-------------------------------------------------------');



            let objectToTime = [];
            for(let x = 0 ; x < this.$store.state.CSForProductsTime.length; x ++) {
                if(typeof this.$store.state.CSForProductsTime[0][x] != "undefined") {
                    objectToTime.push({"start":this.$store.state.CSForProductsTime[0][x].time,"terms":this.$store.state.CSForProductsTime[1][x].time});
                }
            }
            let objectToPersist = {
                productSeq : 100,   // 임시 코드
                productCSName : 'FTCS' + this.$moment(new Date()).format('YYYYMMDD') + '-' + this.$store.state.productCode + '-' + this.$store.state.CSForProductsVer,
                state : state,
                productCSInspections : objectToCS,
                productCSTimes : objectToTime
            }

            console.log(objectToPersist);
            
            // CS 수정인 경우
            if (this.editCS && this.editCS.clsProduct && !this.createFromTemplate) {
                objectToPersist.productCsSeq = this.editCS.productCsSeq;

                let urlForProduct = this.$store.state.hostInService + '/product/cs/edit';
                this.$http.put(urlForProduct,objectToPersist)
                .then((response) => {
                    if (response.status === 200 && response.data.code === 'success') {
                        this.$message.success('저장되었습니다');
                        this.$router.push('/filter');
                    }  else {
                        console.error(response);
                        this.$message.error('저장되지 않았습니다(1).');
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.$message.error('저장되지 않았습니다(2).');
                });
            }
            // CS 생성인 경우
            else {
                // 필터 데이터 저장
                this.$http.post('https://cowaylqc.iotinfras.com:8100/product/cs/save_filter_data', {
                    category: this.category,
                    part_name: this.partName,
                    part_code: this.partCode,
                    image1: this.image1,
                    image2: this.image2,
                });

                let urlForProduct = this.$store.state.hostInService + '/product/cs/add';
                this.$http.post(urlForProduct,objectToPersist)
                .then((response) => {
                    if (response.status === 200 && response.data.code === 'success') {
                        this.$message.success('등록되었습니다');
                        this.$router.push('/filter');
                    }  else {
                        console.error(response);
                        this.$message.error('등록되지 않았습니다(1).');
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.$message.error('등록되지 않았습니다(2).');
                });
            }
        },
        fetchProductCSList(callback) {
            this.dataArray2 = [];
            this.data2 = [];
            let urlToFetchProductCSList = "https://cowaylqc.iotinfras.com:8100/product/cs/list_simple?size=10000&page=0";
            this.$http.get(urlToFetchProductCSList)
            .then((response) => {
                if(response.status === 200 && response.data.code === 'success') {
                    this.dataArray2 = response.data.data.content;
                    this.dataArray2 = _.filter(response.data.data.content, (item) => {
                        return item.state === 'N';
                    });
                    this.dataArray2 = this.dataArray2.reverse();
                }
                console.log('CS List', this.dataArray2);
                for(let x = 0; x < this.dataArray2.length ; x ++) {
                    // TO DO - 서버에서 수정한 후 이 코드 삭제 - updated 가 9 시간이 작게 나와서 프론트엔드에서 수정
                    let updatedObj = new Date((new Date(this.dataArray2[x].updated)).getTime() + 9 * 60 * 60 * 1000);
                    let year = updatedObj.getFullYear();
                    let month = updatedObj.getMonth() + 1;
                    if (month < 10) {
                        month = '0' + month;
                    }
                    let date = updatedObj.getDate();
                    if (date < 10) {
                        date = '0' + date;
                    }
                    let hour = updatedObj.getHours();
                    if (hour < 10) {
                        hour = '0' + hour;
                    }
                    let minute = updatedObj.getMinutes();
                    if (minute < 10) {
                        minute = '0' + minute;
                    }
                    let second = updatedObj.getSeconds();
                    if (second < 10) {
                        second = '0' + second;
                    }

                    this.data2.push({
                        key : x,
                        name : this.dataArray2[x].name,
                        // updated : this.dataArray[x].updated,
                        updated : year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second,
                        model : this.dataArray2[x].clsProduct.productModel,
                        modelDetail : this.dataArray2[x].clsProduct.productModelDetail,
                        product : this.dataArray2[x].clsProduct.productName,
                        productSeq : this.dataArray2[x].clsProduct.productSeq,
                    });
                }
                this.flagSpinner = false;

                if (callback) {
                    callback();
                }
            }).catch(error => {
                console.log(error);
                this.flagSpinner = false;
            });
        },
        handlerToCreateProductCS(dataOffset) {
            this.handlerForButtonNew();

            let target = this.dataArray2[dataOffset];

            let urlToFetchProductCSList = "https://cowaylqc.iotinfras.com:8100/product/cs/get_filter_cs_item?seq=" + target.productCsSeq;
                this.$http.get(urlToFetchProductCSList)
                .then((response) => {
                    if(response.status === 200 && response.data.code === 'success') {
                        let item = response.data.data.content[0];
                        this.editCS = item;
                        this.createFromTemplate = true;
                        this.loadProductList();
                    }
                });
        },
        filterProductList() {
            if (this.filterProduct) {
                let re = new RegExp(this.filterProduct, 'gi');

                this.data = _.filter(this.dataBackup, (item) => {
                    return item.model && item.model.match(re) || item.modelDetail && item.modelDetail.match(re) || item.name && item.name.match(re);
                });
            } else {
                this.data = this.dataBackup;
            }
        },
        image1Upload(info) {
            this.spinning = info.file.status === 'uploading' ? true : false;
            if(info.file.status === 'done') {
                this.$message.success(`${info.file.name} file uploaded successfully`);
                this.image1 = info.file.response.data.uploadFileSeq;
            } else if(info.file.status === 'error') {
                this.$message.error(`${info.file.name} file upload failed.`);
            }
        },
        image2Upload(info) {
            this.spinning = info.file.status === 'uploading' ? true : false;
            if(info.file.status === 'done') {
                this.$message.success(`${info.file.name} file uploaded successfully`);
                this.image2 = info.file.response.data.uploadFileSeq;
            } else if(info.file.status === 'error') {
                this.$message.error(`${info.file.name} file upload failed.`);
            }
        },
    }
}
</script>
<style scoped>
    .div_selected_product {
        margin-top : 30px;
    }
</style>