/****************************************************************************************
 * File Name    : SheetPatrol.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
    <div>
        <a-row :gutter="16">
            <a-col class="gutter-row" :span="12">
                <div class="gutter-box">
                    <PageHeader title = "조회하기" />
                </div>
            </a-col>
        </a-row>
        <a-form class="" :form="form" layout = "vertical"  @submit="onSubmit">
            <a-card>
                <a-form-item label="모델명">
                    <a-input placeholder="" readOnly v-decorator="['model',{initialValue: model, rules: [{ required: false, message: '모델명을 입력하세요' }],},] "/>
                </a-form-item>
                <a-form-item label="부품명">
                    <a-input placeholder="" readOnly v-decorator="['part',{initialValue: part, rules: [{ required: false, message: '부품명을 입력하세요' }],},] "/>
                </a-form-item>
                <a-form-item label="변경목적">
                    <a-input placeholder="" readOnly v-decorator="['purpose',{initialValue: purpose, rules: [{ required: false, message: '변경목적을 입력하세요' }],},] "/>
                </a-form-item>
                <a-form-item label="변경내용">
                    <a-textarea placeholder="" rows="5" readOnly v-decorator="['content',{initialValue: content, rules: [{ required: false, message: '변경내용을 입력하세요' }],},] "/>
                </a-form-item>
                <a-form-item label="참고사진">
                    <div v-html="imgHtml"></div>
                </a-form-item>
                <a-form-item label="태블릿 표시">
                    <span v-if="this.tablet">Y</span>
                    <span v-if="!this.tablet">N</span>
                </a-form-item>
                <a-form-item label="공지시작일">
                    <span>{{ this.start }}</span>
                </a-form-item>
                <a-form-item label="공지종료일">
                    <span>{{ this.end }}</span>
                </a-form-item>
            </a-card>
            <p/>
            <a-button type="primary" html-type="button" :ghost = false size = "default" icon = "plus-circle" @click="handlerToList()">목록보기</a-button>
        </a-form>
        <div id="image-viewer-container" style="display:none; position:absolute; top:0; left:0; width:100%; height:100%; background-color:rgba(0,0,0,0.3);">
            <div id="image-viewer" style="position:absolute; top:20%; left:20%; width:60%; height:60%; background-color:#fff; border:1px solid #ccc;">
                <div id="image-viewer-close" @click="imageViewerClose()" style="position:absolute; top:23px; right:20px; cursor:pointer; font-size:34px; font-weight:bold; -webkit-text-stroke:1px #fff; background-color:#eee;     border:1px solid #ccc;     padding:0 10px; line-height: 1.2; border-radius:4px;">X</div>
                <div id="image-viewer-content" style="height:100%; overflow:auto;"></div>
            </div>
        </div>
    </div>
</template>
<script>
import _ from "lodash";
import $ from "jquery";
import 'vue2-datepicker/index.css';
import PageHeader from "../components/PageHeader.vue";
export default {
    name: 'Patrol4m',
    components: {
        PageHeader ,
    },
    data() {
        return {
            isDataLoaded : false,
            flagForDrawer: false,
            fileUploadSeq : '',
            spinning : false,
            image: null,
            model: null,
            part: null,
            purpose: null,
            content: null,
            photo: null,
            tablet: null,
            start: null,
            end: null,
            imgSrc: [],
            imgHtml: '',
            fileDelimiter: '||',
        };
    },
    beforeCreate() {
        this.form = this.$form.createForm(this, { name: 'validate_other' });
    },
    mounted() {
        console.log(_);

        if (this.$route.query.seq) {
            let url = 'https://cowaylqc.iotinfras.com:8100/patrol/4mitem/' + this.$route.query.seq;
            this.$http.get(url)
                .then((response) => {
                    console.log(response.data);
                    let item = response.data;

                    this.model = item.MODEL;
                    this.part = item.PART;
                    this.purpose = item.PURPOSE;
                    this.content = item.CONTENT;
                    this.tablet = item.TABLET === 'Y' ? true : false;
                    this.start = item.START;
                    this.end = item.END;
                    this.fileUploadSeq = item.UPLOAD_FILE_SEQ;

                    let self = this;
                    let html = '';
                    let files = this.fileUploadSeq.split(this.fileDelimiter);
                    _.forEach(files, (seq) => {
                        if (!seq) {
                            return;
                        }
                        
                        let src = this.$store.state.hostInService +'/upload/image/download/' + seq;
                        html += '<a href = "' + src + '" target="_blank" id="view-image-' + seq + '" style="margin-right:20px;"><img src = "' + src + '" style = "max-width:80px; max-height:60px;"/></a> ';

                        ((self) => {
                            // 이미지 보기
                            $('body').off('click.view-image-' + seq).on('click.view-image-' + seq, '#view-image-' + seq , function(e) {
                                e.preventDefault();

                                let html = '<p style="margin:20px; text-align:center;"><img src = "' + src + '" style = "max-width:900px;"/></p>';

                                self.viewInspectionImage(html);
                            });
                        })(self);

                        if (seq) {
                            this.imgSrc.push({
                                seq: seq,
                                src: this.$store.state.hostInService +'/upload/image/download/' + seq
                            });
                        }
                    });
                    self.imgHtml = html;
                })
                .catch(error => {
                    console.log(error);
                });
        }

        const userAgent = navigator.userAgent.toLowerCase();
        const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|samsungbrowser|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);

        if (isTablet) {
            let x;
            let els1 = document.body.querySelectorAll('.ant-layout-header');
            for (x = 0; x < els1.length; x++) {
                els1[x].style.display = 'none';
            }
            let els2 = document.body.querySelectorAll('.ant-breadcrumb');
            for (x = 0; x < els2.length; x++) {
                els2[x].style.display = 'none';
            }
            let els3 = document.body.querySelectorAll('.ant-divider-horizontal');
            for (x = 0; x < els3.length; x++) {
                els3[x].style.display = 'none';
            }
            let els4 = document.body.querySelectorAll('.desktop-only');
            for (x = 0; x < els4.length; x++) {
                els4[x].style.display = 'none';
            }
            let els5 = document.body.querySelectorAll('.ant-layout-sider');
            for (x = 0; x < els5.length; x++) {
                els5[x].style.display = 'none';
            }
        }
    },
    methods : {
        viewInspectionImage(html) {
            document.getElementById('image-viewer-container').style.display = 'block';
            document.getElementById('image-viewer-content').innerHTML = html;
        },
        imageViewerClose() {
            document.getElementById('image-viewer-container').style.display = 'none';
            document.getElementById('image-viewer-content').innerHTML = '';
        },
        deleteImg(seq) {
            if (confirm('선택한 이미지를 삭제하시겠습니까?')) {
                let files = this.fileUploadSeq.split(this.fileDelimiter);
                files = _.reject(files, (item) => {
                    return item == seq;
                });
                this.fileUploadSeq = files.join(this.fileDelimiter);

                this.imgSrc = [];
                _.forEach(files, (seq) => {
                    if (seq) {
                        this.imgSrc.push({
                            seq: seq,
                            src: this.$store.state.hostInService +'/upload/image/download/' + seq
                        });
                    }
                });    
            }
        },
        onSubmit(e) {
            e.preventDefault();

            console.log(this.fileUploadSeq);

            this.form.validateFields((err, values) => {
                if(!err) {
                    if (this.$route.query.seq) {
                        let formData = {};
                        formData.model                = values.model;
                        formData.part              = values.part;
                        formData.purpose               = values.purpose;
                        formData.content          = values.content;
                        formData.tablet               = this.tablet ? 'Y' : 'N';
                        formData.start               = this.start;
                        formData.end                = this.end;
                        formData.upload_file_seq     = this.fileUploadSeq;

                        let url = 'https://cowaylqc.iotinfras.com:8100/patrol/4mupdate/' + this.$route.query.seq;
                        this.$http.put(url,formData)
                            .then((response) => {
                                console.log(response);
                                this.$message.success('저장되었습니다');
                                location = '#/patrol4mList';
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    } else {
                        let formData = {};
                        formData.model                = values.model;
                        formData.part              = values.part;
                        formData.purpose               = values.purpose;
                        formData.content          = values.content;
                        formData.tablet               = this.tablet ? 'Y' : 'N';
                        formData.start               = this.start;
                        formData.end                = this.end;
                        formData.upload_file_seq     = this.fileUploadSeq;
                        
                        let url = 'https://cowaylqc.iotinfras.com:8100/patrol/4mcreate';
                        this.$http.post(url,formData)
                            .then((response) => {
                                console.log(response);
                                this.$message.success('저장되었습니다');
                                location = '#/patrol4mList';
                            })
                            .catch(error => {
                                console.log(error);
                            });
                        }
                    }
            });
        },
        imageUpload(info) {
            this.spinning = info.file.status === 'uploading' ? true : false;
            if(info.file.status === 'done') {
                let files = this.fileUploadSeq.split(this.fileDelimiter);
                files = _.filter(files, (seq) => {
                    return seq;
                });
                this.$message.success(`${info.file.name} file uploaded successfully`);
                files.push(info.file.response.data.uploadFileSeq);
                this.fileUploadSeq = files.join(this.fileDelimiter);
            } else if(info.file.status === 'error') {
                this.$message.error(`${info.file.name} file upload failed.`);
            }
        },
        handlerToList() {
            location = '#/patrol4mList';
        },
    }
}
</script>
<style scoped>
</style>