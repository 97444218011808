/****************************************************************************************
 * File Name    : EquipmentForm.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
    <div class="report-viewer" id="print">
        <div @click="closeDrawer()" style="position:absolute; top:4px; right:18px; cursor:pointer; font-size:34px; font-weight:bold; -webkit-text-stroke:1px #fff; background-color:#eee;     border:1px solid #ccc;     padding:0 10px; line-height: 1.2; border-radius:4px; z-index:10;">X</div>
        <!-- v-bind="formItemLayout" -->
        <a-form class="print-content tool" :form="form" layout = "vertical">
            <a-card title = "치공구 검사 보고서" style="position:relative;">
                <!--  -->
                <div class = 'todo'>상품정보 출력방식 변경</div>
                <a-list item-layout="horizontal" :data-source="[{}]">
                    <a-list-item slot="renderItem">
                        <a-list-item-meta :description="selectedTool.creator || '-'">
                            <span slot="title">검사담당자</span>
                        </a-list-item-meta>
                        <a-list-item-meta :description="selectedTool.creator || '-'">
                            <span slot="title">승인자</span>
                        </a-list-item-meta>
                        <a-list-item-meta :description="selectedTool.etc || '-'">
                            <span slot="title">라인명</span>
                        </a-list-item-meta>
                        <a-list-item-meta :description="selectedTool.updated || '-'">
                            <span slot="title">검사완료일시</span>
                        </a-list-item-meta>
                    </a-list-item>
                </a-list>
                <h3>1. 토오크 관리</h3>
                <table class="report-table" style='width:100%; border: 1px solid #e8e8e8'>
                    <thead class="ant-table-thead">
                        <tr>
                            <th>공정명</th>
                            <th>공구종류</th>
                            <th>관리번호</th>
                            <th>측정값 (Kgm.cm)</th>
                            <th>측정자 성명</th>
                            <th>교환번호</th>
                            <th>비고</th>
                        </tr>
                    </thead>
                    <tbody class="ant-table-tbody">
                        <tr v-for="(item,index) in selectedTool['listTorque']" :key="index">
                            <td>
                                <span>{{item.processName}}</span>
                            </td>
                            <td>
                                <span>{{item.toolKind}}</span>
                            </td>
                            <td>
                                <span>{{item.adminNo}}</span>
                            </td>
                            <td>
                                <span>{{item.value}}</span>
                            </td>
                            <td>
                                <span>{{item.tester}}</span>
                            </td>
                            <td>
                                <span>{{item.exchangeNo}}</span>
                            </td>
                            <td>
                                <span>{{item.etc}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p />
                <h3>2. 가스세이버</h3>
                <table class="report-table" style='width:100%; border: 1px solid #e8e8e8'>
                    <thead class="ant-table-thead">
                        <tr>
                            <th>관리번호</th>
                            <th>LPG공급</th>
                            <th>산소공급</th>
                            <th>용접불꽃상태</th>
                            <th>점화불꽃상태</th>
                            <th>후럭스 청결</th>
                        </tr>
                    </thead>
                    <tbody class="ant-table-tbody">
                        <tr>
                            <td>
                                <span>{{selectedTool.clsGasSaver.manageNo}}</span>
                            </td>
                            <td>
                                <span>{{selectedTool.clsGasSaver.lpg}}</span>
                            </td>
                            <td>
                                <span>{{selectedTool.clsGasSaver.oxygen}}</span>
                            </td>
                            <td>
                                <span>{{selectedTool.clsGasSaver.weldingStatus}}</span>
                            </td>
                            <td>
                                <span>{{selectedTool.clsGasSaver.ignitionStatus}}</span>
                            </td>
                            <td>
                                <span>{{selectedTool.clsGasSaver.fluxStatus}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p />
                <h3>3. 계측기 관리</h3>
                <h4>(1) 냉매 차징기</h4>
                <table class="report-table" style='width:100%; border: 1px solid #e8e8e8'>
                    <thead class="ant-table-thead">
                        <tr>
                            <th>프레온 압력</th>
                            <th>진공도</th>
                            <th>프레온 온도</th>
                            <th>셋팅</th>
                            <th>충진량</th>
                            <th>S/W 상태</th>
                        </tr>
                    </thead>
                    <tbody class="ant-table-tbody">
                        <tr>
                            <td>
                                <span>{{selectedTool.clsRefrigerantCharger.freonPressure}}</span>
                            </td>
                            <td>
                                <span>{{selectedTool.clsRefrigerantCharger.vaccum}}</span>
                            </td>
                            <td>
                                <span>{{selectedTool.clsRefrigerantCharger.freonTemp}}</span>
                            </td>
                            <td>
                                <span>{{selectedTool.clsRefrigerantCharger.setting}}</span>
                            </td>
                            <td>
                                <span>{{selectedTool.clsRefrigerantCharger.fillingAmount}}</span>
                            </td>
                            <td>
                                <span>{{selectedTool.clsRefrigerantCharger.sofwareStatus}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p />
                <h4>(2) 내전압 시험기</h4>
                <table class="report-table" style='width:100%; border: 1px solid #e8e8e8'>
                    <thead class="ant-table-thead">
                        <tr>
                            <th>내전압 설정치</th>
                            <th>절연저항 설정치</th>
                            <th>지침 상태</th>
                            <th>S/W 상태</th>
                        </tr>
                    </thead>
                    <tbody class="ant-table-tbody">
                        <tr>
                            <td>
                                <span>{{selectedTool.clsHipotTester.hipotSetting}}</span>
                            </td>
                            <td>
                                <span>{{selectedTool.clsHipotTester.insulationResistanceSetting}}</span>
                            </td>
                            <td>
                                <span>{{selectedTool.clsHipotTester.indicatorStatus}}</span>
                            </td>
                            <td>
                                <span>{{selectedTool.clsHipotTester.sofwareStatus}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p />
                <h4>(3) 접지 연속성 시험기</h4>
                <table class="report-table" style='width:100%; border: 1px solid #e8e8e8'>
                    <thead class="ant-table-thead">
                        <tr>
                            <th>전류 설정치</th>
                            <th>시간 설정치</th>
                            <th>표시창 상태</th>
                            <th>S/W 상태</th>
                        </tr>
                    </thead>
                    <tbody class="ant-table-tbody">
                        <tr>
                            <td>
                                <span>{{selectedTool.clsEarthTester.currentSetting}}</span>
                            </td>
                            <td>
                                <span>{{selectedTool.clsEarthTester.timeSetting}}</span>
                            </td>
                            <td>
                                <span>{{selectedTool.clsEarthTester.indicatorStatus}}</span>
                            </td>
                            <td>
                                <span>{{selectedTool.clsEarthTester.sofwareStatus}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p />
                <h3>4. 검사장치 관리</h3>
                <h4>(1) 질소 검사</h4>
                <table class="report-table" style='width:100%; border: 1px solid #e8e8e8'>
                    <thead class="ant-table-thead">
                        <tr>
                            <th>기준 압력</th>
                            <th>안정화</th>
                            <th>검사시간</th>
                            <th>판정</th>
                            <th>터치패널 상태</th>
                            <th>압력계 상태</th>
                            <th>S/W 상태</th>
                        </tr>
                    </thead>
                    <tbody class="ant-table-tbody">
                        <tr>
                            <td>
                                <span>{{selectedTool.clsNitrogen.pressure}}</span>
                            </td>
                            <td>
                                <span>{{selectedTool.clsNitrogen.stabilizationTime}}</span>
                            </td>
                            <td>
                                <span>{{selectedTool.clsNitrogen.inspectionTime}}</span>
                            </td>
                            <td>
                                <span>{{selectedTool.clsNitrogen.psi}}</span>
                            </td>
                            <td>
                                <span>{{selectedTool.clsNitrogen.touchPanelStatus}}</span>
                            </td>
                            <td>
                                <span>{{selectedTool.clsNitrogen.gaugeStatus}}</span>
                            </td>
                            <td>
                                <span>{{selectedTool.clsNitrogen.sofwareStatus}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p />
                <h4>(2) 진공 검사</h4>
                <table class="report-table" style='width:100%; border: 1px solid #e8e8e8'>
                    <thead class="ant-table-thead">
                        <tr>
                            <th>주입 시간</th>
                            <th>안정화</th>
                            <th>검사시간</th>
                            <th>판정</th>
                            <th>터치패널 상태</th>
                            <th>압력계 상태</th>
                            <th>S/W 상태</th>
                        </tr>
                    </thead>
                    <tbody class="ant-table-tbody">
                        <tr>
                            <td>
                                <span>{{selectedTool.clsVacuum.injectionTime}}</span>
                            </td>
                            <td>
                                <span>{{selectedTool.clsVacuum.stabilizationTime}}</span>
                            </td>
                            <td>
                                <span>{{selectedTool.clsVacuum.inspectionTime}}</span>
                            </td>
                            <td>
                                <span>{{selectedTool.clsVacuum.psi}}</span>
                            </td>
                            <td>
                                <span>{{selectedTool.clsVacuum.touchPanelStatus}}</span>
                            </td>
                            <td>
                                <span>{{selectedTool.clsVacuum.gaugeStatus}}</span>
                            </td>
                            <td>
                                <span>{{selectedTool.clsVacuum.sofwareStatus}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p />
                <h4>(3) 전류 검사</h4>
                <table class="report-table" style='width:100%; border: 1px solid #e8e8e8'>
                    <thead class="ant-table-thead">
                        <tr>
                            <th>전류 설정치</th>
                            <th>터치패널 상태</th>
                        </tr>
                    </thead>
                    <tbody class="ant-table-tbody">
                        <tr>
                            <td>
                                <span>{{selectedTool.clsCurrent.currentSetting}}</span>
                            </td>
                            <td>
                                <span>{{selectedTool.clsCurrent.touchPanelStatus}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p />
            </a-card>
            <p/>
            <div id="print-button-container" style="text-align:center;">
                <a-button @click="closeDrawer()" type="default" icon="plus-circle" size = "default">
                                취소
                            </a-button>
                <a-button type="default" icon="plus-circle" size = "default" @click="print()" style="margin-left:20px;">
                        인쇄
                    </a-button>
                <a-button v-if="selectedTool.valid === 'B'" type="primary" icon="plus-circle" size = "default" @click="submit('C')" style="margin-left:20px;">
                            승인완료
                </a-button>
                <a-button v-if="selectedTool.valid !== 'B'" type="danger" icon="plus-circle" size = "default" @click="submit('B')" style="margin-left:20px;">
                            승인완료 취소
                        </a-button>
            </div>
        </a-form>

    </div>
</template>
<script>
import _ from "lodash";
export default {
    name: 'ToolReportViewer',
    props: ['selectedTool'],
    data() {
        return {
            formItemLayout: {
                labelCol: { span: 3 },
                wrapperCol: { span: 21 },
            },
        };
    },
    mounted() {
        console.log('selectedTool', this.selectedTool)
    },
    watch: {
        selectedTool: function(newOne){
            this.selectedTool = newOne;
            console.log('selectedTool', this.selectedTool)

            this.selectedTool.creator = sessionStorage.getItem('user_name');

            // TO DO - 서버에서 수정한 후 이 코드 삭제 - updated 가 9 시간이 작게 나와서 프론트엔드에서 수정
            let updatedObj = new Date((new Date(this.selectedTool.updated)).getTime() + 9 * 60 * 60 * 1000);
            let year = updatedObj.getFullYear();
            let month = updatedObj.getMonth() + 1;
            if (month < 10) {
                month = '0' + month;
            }
            let date = updatedObj.getDate();
            if (date < 10) {
                date = '0' + date;
            }
            let hour = updatedObj.getHours();
            if (hour < 10) {
                hour = '0' + hour;
            }
            let minute = updatedObj.getMinutes();
            if (minute < 10) {
                minute = '0' + minute;
            }
            let second = updatedObj.getSeconds();
            if (second < 10) {
                second = '0' + second;
            }

            this.selectedTool.updated = year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second;
        }
    },
    beforeCreate() {
        this.form = this.$form.createForm(this, { name: 'validate_other' });
    },
    methods : {
        submit(valid) {
            let editData = JSON.parse(JSON.stringify(this.selectedTool));

            // 이전 상태가 검사완료 또는 승인완료 이거나 이번에 변경할 상태가 승인완료일 경우 시간을 업데이트 하지 않음
            if (editData.valid === 'B' || editData.valid === 'C' || valid === 'C') {
                // 시간을 업데이트 하지 않음
            } else {
                editData.updated2 = this.timeToDatestr(new Date());
            }

            let message = '';
            if (valid !== 'B') {
                message = editData.updated.substr(0, 10) + ' ' + editData.etc + ' 보고서를 승인하시겠습니까?';
            } else {
                message = editData.updated.substr(0, 10) + ' ' + editData.etc + ' 보고서를 승인취소하시겠습니까?';
            }

            if (confirm(message)) {
                editData.equipmentLineInfoSeq = editData.clsEquipmentLineInfo.equipmentLineInfoSeq;

                delete(editData.created);
                delete(editData.creator);
                delete(editData.updated);
                delete(editData.updater);
                delete(editData.valid);
                delete(editData.no);
                delete(editData.clsEquipmentLineInfo);

                editData.valid = valid;

                _.forEach(editData.listTorque, function(item) {
                    delete(item.created);
                    delete(item.creator);
                    delete(item.updated);
                    delete(item.updater);
                    delete(item.valid);
                });

                delete(editData.clsGasSaver.created);
                delete(editData.clsGasSaver.creator);
                delete(editData.clsGasSaver.updated);
                delete(editData.clsGasSaver.updater);
                delete(editData.clsGasSaver.valid);

                delete(editData.clsRefrigerantCharger.created);
                delete(editData.clsRefrigerantCharger.creator);
                delete(editData.clsRefrigerantCharger.updated);
                delete(editData.clsRefrigerantCharger.updater);
                delete(editData.clsRefrigerantCharger.valid);

                delete(editData.clsHipotTester.created);
                delete(editData.clsHipotTester.creator);
                delete(editData.clsHipotTester.updated);
                delete(editData.clsHipotTester.updater);
                delete(editData.clsHipotTester.valid);

                delete(editData.clsEarthTester.created);
                delete(editData.clsEarthTester.creator);
                delete(editData.clsEarthTester.updated);
                delete(editData.clsEarthTester.updater);
                delete(editData.clsEarthTester.valid);

                delete(editData.clsNitrogen.created);
                delete(editData.clsNitrogen.creator);
                delete(editData.clsNitrogen.updated);
                delete(editData.clsNitrogen.updater);
                delete(editData.clsNitrogen.valid);

                delete(editData.clsVacuum.created);
                delete(editData.clsVacuum.creator);
                delete(editData.clsVacuum.updated);
                delete(editData.clsVacuum.updater);
                delete(editData.clsVacuum.valid);

                delete(editData.clsCurrent.created);
                delete(editData.clsCurrent.creator);
                delete(editData.clsCurrent.updated);
                delete(editData.clsCurrent.updater);
                delete(editData.clsCurrent.valid);

                editData.listTorqueVo = editData.listTorque;
                editData.clsGasSaverVo = editData.clsGasSaver;
                editData.clsRefrigerantChargerVo = editData.clsRefrigerantCharger;
                editData.clsHipotTesterVo = editData.clsHipotTester;
                editData.clsEarthTesterVo = editData.clsEarthTester;
                editData.clsNitrogenVo = editData.clsNitrogen;
                editData.clsVacuumVo = editData.clsVacuum;
                editData.clsCurrentVo = editData.clsCurrent;

                delete(editData.listTorque);
                delete(editData.clsGasSaver);
                delete(editData.clsRefrigerantCharger);
                delete(editData.clsHipotTester);
                delete(editData.clsEarthTester);
                delete(editData.clsNitrogen);
                delete(editData.clsVacuum);
                delete(editData.clsCurrent);

                let apiUrl = this.$store.state.hostInService + "/tool/inspection/edit";
                this.$http.put(apiUrl,editData)
                .then((response) => {
                    console.log(response);
                    if(response.status === 200 && response.data.code === 'success') {
                        this.buffer = response.data.data;
                    }
                    this.$message.success("저장되었습니다.");
                    this.$emit('editToolInfo');
                })
                .catch(error => {
                    console.log(error);
                    this.$message.error("저장되지 않았습니다.");
                });
            }
        },
        closeDrawer() {
            this.$emit('flagForDrawer');
        },
        print() {
            // Get HTML to print from element
            const prtHtml = document.getElementById('print').innerHTML;

            // Get all stylesheets HTML
            let stylesHtml = '';
            for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
            stylesHtml += node.outerHTML;
            }

            // Open the print window
            const WinPrint = window.open('', '', 'left=0,top=0,width=1600,height=900,toolbar=0,scrollbars=0,status=0');

            WinPrint.document.write(`<!DOCTYPE html>
            <html>
            <head>
                ${stylesHtml}
            </head>
            <body>
                ${prtHtml}
            </body>
            </html>`);

            WinPrint.document.close();
            WinPrint.focus();
            WinPrint.onload = function() {
                setTimeout(function() {
                    WinPrint.print();
                    WinPrint.close();
                }, 1000);
            };
        },
    }
}
</script>
<style scoped>
    .ant-form-item  {
        margin-bottom: 5px !important;
    }
    .ant-form-item-label  {
        font-weight: 700  !important;
    }
</style>