/****************************************************************************************
 * File Name    : PageHeader.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
        <div>
            <p/>
            <h3><a-icon type="check-circle" /> {{title}}</h3>
            <p/>
        </div>
</template>
<script>
export default {
    name: 'PageHeader',
    props : ['title']
}
</script>
<style scoped>
</style>