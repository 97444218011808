/****************************************************************************************
 * File Name    : Equipments.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
    <div>
        <div v-if = "flagForSpinner==false">
            <a-row :gutter="16">
                <a-col class="gutter-row" :span="12">
                    <div class="gutter-box">
                        <PageHeader title = "등록설비 " />
                    </div>
                </a-col>
            </a-row>
            <a-row type="flex" justify="center" align="top" :gutter="8">
                <a-col :span="12">
                    <a-card hoverable style="height: 50%">
                        <img slot="cover" alt="example" style = 'display:block; margin:0 auto -50px; width:auto; height:230px;' src="https://mall.cowaystatic.com/static/upload/product/product/ID0101_2077_attimg_org.png" />
                        <a-card-meta title="" class = 'card_action'>
                            <template slot="description" >
                                <a-button type="primary" icon="plus-circle" size = "default" @click="targetProduct = 'waterpurifier'; showDrawer();" >
                                    정수기 설비 수정
                                </a-button>
                            </template>
                        </a-card-meta>
                    </a-card>
                </a-col>
                <a-col :span="12">
                    <a-card hoverable style="height: 50%">
                        <img slot="cover" alt="example" style = 'display:block; margin:0 auto -50px; width:auto; height:230px;' src="https://mall.cowaystatic.com/static/upload/product/product/ID0101_20_attimg_org.png" />
                        <a-card-meta title="" class = 'card_action'>
                            <template slot="description" >
                                <a-button type="primary" icon="plus-circle" size = "default" @click="targetProduct = 'airpurifier'; showDrawer();" >
                                    청정기 설비 수정
                                </a-button>
                            </template>
                        </a-card-meta>
                    </a-card>
                </a-col>
            </a-row>
            <p/>
            <a-row type="flex" justify="center" align="top" :gutter="8">
                <a-col :span="12">
                    <a-table :columns="columns1" size = "small" :bordered=false :data-source="data['waterpurifier']" :pagination="true" :scroll="{ x: 0, y: '40vh' }">
                    </a-table>
                </a-col>
                <a-col :span="12">
                    <a-table :columns="columns1" size = "small" :bordered=false :data-source="data['airpurifier']" :pagination="true" :scroll="{ x: 0, y: '40vh' }">
                    </a-table>
                </a-col>
            </a-row>
            <p/>
            <a-row type="flex" justify="center" align="top" :gutter="8" style="display:none;">
                <a-col :span="12">
                    <a-table :columns="columns2" size = "small" :bordered=false :data-source="lineData['waterpurifier']" :pagination="true" :scroll="{ x: 0, y: '20vh' }">
                        <span slot="action" slot-scope="record">
                            <span class = 'icon_button' @click="editLineinfo(record)"><a-icon type="edit" /> </span>
                            <a-divider type = 'vertical' />
                            <a-icon type="delete" @click="deleteLineinfo(record)" style="cursor:pointer;" />
                        </span>
                    </a-table>
                </a-col>
                <a-col :span="12">
                    <a-table :columns="columns2" size = "small" :bordered=false :data-source="lineData['airpurifier']" :pagination="true" :scroll="{ x: 0, y: '20vh' }">
                        <span slot="action" slot-scope="record">
                            <span class = 'icon_button' @click="editLineinfo(record)"><a-icon type="edit" /> </span>
                            <a-divider type = 'vertical' />
                            <a-icon type="delete" @click="deleteLineinfo(record)" style="cursor:pointer;" />
                        </span>
                    </a-table>
                </a-col>
            </a-row>
            <p style="display:none;"/>
        </div>
        <a-skeleton v-else />
        <!-- DESC : 제품등록폼 -->
        <a-drawer title="설비 수정" placement="right" width="70vw" :closable="false" :visible="flagForDrawer1" :get-container="false" @close="closeDrawer" >
            <!-- <EquipmentForm :type = "productType" :productSeq="selectedProduct.productSeq" :productName="selectedProduct.name" :productModel="selectedProduct.model" :productModelDetail="selectedProduct.modelDetail" :productPop="selectedProduct.pop" :productSap="selectedProduct.sap" :image="selectedProduct.image" :uploadFileSeq="selectedProduct.uploadFileSeq" v-on:addProduct="addProduct"/> -->
            <EquipmentForm :targetProduct="targetProduct" :equipments="data" v-on:editEquipment="editEquipment" />
        </a-drawer>
        <a-drawer title="라인 수정" placement="right" width="70vw" :closable="false" :visible="flagForDrawer2" :get-container="false" @close="closeDrawer2" >
            <EquipmentLineForm :targetProduct="targetProduct" :lineinfo="lineData" :selectedLineinfo="selectedLineinfo" v-on:editEquipment="editEquipment" />
        </a-drawer>
    </div>
</template>
<script>
// TODO : DB작업후 실제 자료로 변경해야함.
// TODO : 제품 테이블을 공통 모듈로 수정해야 한다.
const columns1 = [
    { title: '공정',dataIndex: 'processName',key: 'processName',  scopedSlots: { customRender: 'processName'}, width: 70},
    { title: '단위설비',dataIndex: 'unitEquipment',key: 'unitEquipment',  scopedSlots: { customRender: 'unitEquipment'}, width: 120},
    { title: '구성장치',dataIndex: 'devivce',key: 'devivce',  scopedSlots: { customRender: 'devivce'}, width: 120},
    { title: '점검내용',dataIndex: 'inspectionDescription',key: 'inspectionDescription',scopedSlots: { customRender: 'inspectionDescription'},},
    { title: '요일',dataIndex: 'inspectionCycle',key: 'inspectionCycle',scopedSlots: { customRender: 'inspectionCycle'}, width: 50},
];

const columns2 = [
    { title: '라인명',dataIndex: 'lineName',key: 'lineName',  scopedSlots: { customRender: 'lineName'},},
    { title: '점검자',dataIndex: 'manager',key: 'manager',  scopedSlots: { customRender: 'manager'},},
    { title: '관리자1',dataIndex: 'admin1',key: 'admin1',  scopedSlots: { customRender: 'admin1'},},
    { title: '관리자2',dataIndex: 'admin2',key: 'admin2',scopedSlots: { customRender: 'admin2'},},
    { title: '관리',key: 'action',scopedSlots: { customRender: 'action'},  width : 100},
];

import _ from "lodash";
import PageHeader from "../components/PageHeader.vue"     ;
import EquipmentForm from "../components/EquipmentForm.vue"    ;
import EquipmentLineForm from "../components/EquipmentLineForm.vue"    ;
export default {
    name: 'Equipments',
    components: {
        PageHeader  ,
        EquipmentForm ,
        EquipmentLineForm ,
    },
    data() {
        return {
            flagForSpinner : false,
            flagForDrawer1: false,
            flagForDrawer2: false,
            targetProduct: 'waterpurifier',
            data: {
                waterpurifier: [],
                airpurifier: []
            },
            lineData: {
                waterpurifier: [],
                airpurifier: []
            },
            selectedLineinfo: {},
            columns1,
            columns2,
        };
    },
    mounted() {
        this.loadList('waterpurifier');
        this.loadList('airpurifier');
        this.loadLineList('waterpurifier');
        this.loadLineList('airpurifier');
    },
    methods : {
        editEquipment() {
            this.loadList('waterpurifier');
            this.loadList('airpurifier');
            this.loadLineList('waterpurifier');
            this.loadLineList('airpurifier');

            this.closeDrawer();
        },
        loadList(targetProduct) {
            this.flagForSpinner = true;
            this.data[targetProduct] = [];
            let urlToFetchProducts = this.$store.state.hostInService + "/equipment/" + targetProduct;
            this.$http.get(urlToFetchProducts)
            .then((response) => {
                if(response.status === 200 && response.data.code === 'success') {
                    let dataArray = response.data.data;
                    for(let x = 0; x < dataArray.length; x ++ ) {
                        let obj = _.cloneDeep(dataArray[x]);
                        obj.key = x;
                        this.data[targetProduct].push(obj);
                    }
                }
                this.flagForSpinner = false;

            }).catch(error => {
                console.log(error);
                this.flagForSpinner = false;
            });
        },
        loadLineList(targetProduct) {
            this.flagForSpinner = true;
            this.lineData[targetProduct] = [];
            let urlToFetchProducts = this.$store.state.hostInService + "/equipment/lineinfo/" + targetProduct;
            this.$http.get(urlToFetchProducts)
            .then((response) => {
                if(response.status === 200 && response.data.code === 'success') {
                    let dataArray = response.data.data;
                    for(let x = 0; x < dataArray.length; x ++ ) {
                        let obj = _.cloneDeep(dataArray[x]);
                        obj.key = x;
                        this.lineData[targetProduct].push(obj);
                    }
                }
                this.flagForSpinner = false;

            }).catch(error => {
                console.log(error);
                this.flagForSpinner = false;
            });
        },
        showDrawer() {
            this.selectedProduct = {};
            this.flagForDrawer1 = true;
        },
        closeDrawer() {
            this.flagForDrawer1 = false;
        },
        showDrawer2() {
            this.flagForDrawer2 = true;
        },
        closeDrawer2() {
            this.flagForDrawer2 = false;
        },
        editLineinfo(item) {
            this.targetProduct = item.targetProduct;
            this.selectedLineinfo = item;
            this.flagForDrawer2 = true;
        },
        deleteLineinfo(item) {
            if (confirm('삭제하시겠습니까?')) {
                let apiUrl = this.$store.state.hostInService + "/equipment/lineinfo/delete";
                let deleteData = {
                    equipmentLineInfoSeq: item.equipmentLineInfoSeq
                };
                this.$http.put(apiUrl,deleteData)
                .then((response) => {
                    console.log(response);
                    if(response.status === 200 && response.data.code === 'success') {
                        this.buffer = response.data.data;
                    }
                    this.$message.success("삭제되었습니다.");
                    this.loadLineList(item.targetProduct);
                })
                .catch(error => {
                    console.log(error);
                    this.$message.error("삭제되지 않았습니다.");
                });
            }
        },
        addLineinfo(targetProduct) {
            this.targetProduct = targetProduct;
            this.selectedLineinfo = {};
            this.flagForDrawer2 = true;
        }
    }
}
</script>
<style scoped>
    .div_action {
        width : 100%;
        text-align: right;
        padding-bottom: 10px;
        margin-top:15px;
    }
    .card_action {
        text-align:right;
    }
</style>