/****************************************************************************************
 * File Name    : AccountForm.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
    <div class = ''>
        <!-- v-bind="formItemLayout" -->
        <a-form class="" :form="form" layout = "vertical"  @submit="onSubmit">
            <a-card>
                <a-form-item label="이름">
                    <a-input placeholder="Name" v-decorator="['name',{initialValue: name, rules: [{ required: true, message: '이름을 입력하세요' }],},] "/>
                </a-form-item>
                <a-form-item label="사번">
                    <a-input placeholder="Number" v-decorator="['number',{initialValue: number, rules: [{ required: true, message: '사번을 입력하세요' }],},]"/>
                </a-form-item>
                <a-form-item label="비밀번호">
                    <a-input placeholder="Password" type="password" v-decorator="['pass',{initialValue: pass, rules: [{ required: true, message: '비밀번호를 입력하세요' }],},]"/>
                </a-form-item>
                <!-- <a-form-item label="부서">
                    <a-radio-group  v-decorator="['Department',{rules: [{ required: true, message: 'Please select 부서!' }],},]" style = 'width:100%'>
                        <a-radio-button value="생산팀">생산팀</a-radio-button>
                        <a-radio-button value="관리팀">관리팀</a-radio-button>
                    </a-radio-group>
                </a-form-item>
                <a-form-item label="직책">
                    <a-radio-group  v-decorator="['Title',{rules: [{ required: true, message: 'Please select 직책!' }],},]" style = 'width:100%'>
                        <a-radio-button value="사원">사원</a-radio-button>
                        <a-radio-button value="대리">대리</a-radio-button>
                    </a-radio-group>
                </a-form-item> -->
                <a-form-item label="Photo">
                    <img v-if="image" :src = "image" style="width:100px;">
                    <a-spin :spinning="spinning">
                        <a-upload name="file" :multiple="false" :action="$store.state.imageUploadServer" @change="imageUpload"
                            v-decorator="['photo',{rules: [{ required: false, message: '사진을 선택하세요' }],},]">
                            <a-button> <a-icon type="camera" /> 사진 선택 </a-button>
                        </a-upload>
                    </a-spin>
                    <div style="margin-top:10px;">
                        * 사진 용량은 100KB 이하로 올려주십시오<br>
                        * 가로, 세로 사이즈가 동일하도록 올려주십시오
                    </div>
                </a-form-item>
                <a-form-item label="Email">
                    <a-input placeholder="Email" v-decorator="['email',{initialValue: email, rules: [{ required: true, message: '이메일을 입력하세요' }],},]"/>
                </a-form-item>
                <a-form-item label="계정종류">
                    <a-radio-group  v-decorator="['kind',{initialValue: kind, rules: [{ required: true, message: '계정종류를 선택하세요' }],},]" style = 'width:100%'>
                        <a-radio-button value="관리자">관리자</a-radio-button>
                        <a-radio-button value="작업자">작업자</a-radio-button>
                    </a-radio-group>
                </a-form-item>
                <!-- <a-form-item label="상태">
                    <a-radio-group  v-decorator="['Title',{rules: [{ required: true, message: 'Please select 게정종류!' }],},]" style = 'width:100%'>
                        <a-radio-button value="사용가능">사용가능</a-radio-button>
                        <a-radio-button value="사용불가">사용불가</a-radio-button>
                    </a-radio-group>
                </a-form-item> -->
                <p/>
            </a-card>
            <p/>
            <a-button type="primary" html-type="submit" :ghost = false size = "default" icon = "plus-circle">Save</a-button>
            <a-divider type = 'vertical' />
            <!-- <a-button type="secondary" html-type="button" :ghost = false  size = "default" icon = "plus-square">Add more</a-button>
            <a-divider type = 'vertical' /> -->
            <a-button type="danger" html-type="reset" :ghost = false  size = "default" icon = "reload">Reset</a-button>
        </a-form>

    </div>
</template>
<script>
export default {
    name: 'AccountForm',
    props : ['accountSeq', 'name', 'number', 'pass', 'email', 'kind', 'profileImageSeq', 'image'],
    data() {
        return {
            formItemLayout: {
                labelCol: { span: 3 },
                wrapperCol: { span: 21 },
            },
            fileUploadSeq : 0,
            spinning : false,
        };
    },
    beforeCreate() {
        this.form = this.$form.createForm(this, { name: 'validate_other' });
    },
    methods : {
        onSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                if(!err) {
                    if (this.accountSeq) {
                        let packetForAccount = {};
                        packetForAccount.accountSeq          = this.accountSeq;
                        packetForAccount.name                = values.name;
                        packetForAccount.number              = values.number;
                        packetForAccount.pass               = values.pass;
                        packetForAccount.department          = '관리팀';
                        packetForAccount.title               = '사원';
                        packetForAccount.email               = values.email;
                        packetForAccount.kind                = values.kind;
                        packetForAccount.profileImageSeq     = this.fileUploadSeq || this.profileImageSeq;
                        packetForAccount.status              = '사용가능';

                        // DESC : 사용자수정
                        let urlToEditAccount = this.$store.state.hostInService + "/account/edit";
                        this.$http.put(urlToEditAccount,packetForAccount)
                        .then((response) => {
                            console.log(response);
                            if(response.status === 200 && response.data.code === 'success') {
                                this.buffer = response.data.data;
                            }
                            this.$message.success("사용자가 수정되었습니다.");
                            this.$emit('addAccount');
                        })
                        .catch(error => {
                            console.log(error);
                            this.$message.error("사용자가 수정되지 않았습니다.");
                        });
                    } else {
                        let packetForAccount = {};
                        packetForAccount.name                = values.name;
                        packetForAccount.number              = values.number;
                        packetForAccount.pass               = values.pass;
                        packetForAccount.department          = '관리팀';
                        packetForAccount.title               = '사원';
                        packetForAccount.email               = values.email;
                        packetForAccount.kind                = values.kind;
                        packetForAccount.profileImageSeq     = this.fileUploadSeq || this.profileImageSeq;
                        packetForAccount.status              = '사용가능';
                        
                        // DESC : 사용자등록
                        let urlToAddAccount = this.$store.state.hostInService + "/account/add";
                        this.$http.post(urlToAddAccount,packetForAccount)
                        .then((response) => {
                            console.log(response);
                            if(response.status === 200 && response.data.code === 'success') {
                                this.buffer = response.data.data;
                            }
                            this.$message.success("사용자가 등록되었습니다.");
                            this.$emit('addAccount');
                        })
                        .catch(error => {
                            console.log(error);
                            this.$message.error("사용자가 등록되지 않았습니다.");
                        });
                    }
                }
            });
        },
        imageUpload(info) {
            this.spinning = info.file.status === 'uploading' ? true : false;
            if(info.file.status === 'done') {
                this.$message.success(`${info.file.name} file uploaded successfully`);
                this.fileUploadSeq = info.file.response.data.uploadFileSeq;
            } else if(info.file.status === 'error') {
                this.$message.error(`${info.file.name} file upload failed.`);
            }
        }
    }
}
</script>
<style scoped>
    .ant-form-item  {
        margin-bottom: 5px !important;
    }
    .ant-form-item-label  {
        font-weight: 700  !important;
    }
</style>