/****************************************************************************************
 * File Name    : EquipmentForm.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
    <div class = ''>
        <!-- v-bind="formItemLayout" -->
        <a-form class="" :form="form" layout = "vertical" @submit="onSubmit">
            <a-card>
                <div v-for="(item,index) in equipments[this.targetProduct]" :key="index" style="position:relative; padding-top:20px;">
                    <a-button icon="plus-circle" @click="addItem(item.equipmentSeq)" type = 'primary' size = "small" style = 'position:absolute; top:0; right:80px;'> 추가</a-button>
                    <a-button icon="minus-circle" @click="removeItem(item.equipmentSeq)" type = 'danger' size = "small" style = 'position:absolute; top:0; right:0;'> 삭제</a-button>
                    <a-form-item label="공정">
                        <a-input placeholder="" v-model="item.processName" />
                    </a-form-item>
                    <a-form-item label="단위설비">
                        <a-input placeholder="" v-model="item.unitEquipment" />
                    </a-form-item>
                    <a-form-item label="구성장치">
                        <a-input placeholder="" v-model="item.devivce" />
                    </a-form-item>
                    <a-form-item label="점검내용">
                        <a-input placeholder="" v-model="item.inspectionDescription" />
                    </a-form-item>
                    <div class="ant-col ant-form-item-label"><label title="요일" class="">요일</label></div>
                    <select v-model="item.inspectionCycle"   style="padding:4px; width: 70px" >
                        <option value="매일"> 매일 </option>
                        <option value="매주"> 매주 </option>
                    </select>
                    <a-divider type = 'horizontal' style="margin:24px 0 !important; background-color:#1890ff;" />
                </div>
            </a-card>
            <p/>
            <a-button type="primary" html-type="submit" :ghost = false size = "default" icon = "plus-circle">Save</a-button>
        </a-form>

    </div>
</template>
<script>
import _ from "lodash";
export default {
    name: 'EquipmentForm',
    props: ['targetProduct', 'equipments'],
    data() {
        return {
            formItemLayout: {
                labelCol: { span: 3 },
                wrapperCol: { span: 21 },
            },
        };
    },
    mounted() {
        console.log('equipments', this.equipments, 'targetProduct', this.targetProduct)
    },
    watch: {
        targetProduct: function(newOne){
            this.targetProduct = newOne;
        }
    },
    beforeCreate() {
        this.form = this.$form.createForm(this, { name: 'validate_other' });
    },
    methods : {
        onSubmit(e) {
            e.preventDefault();
            console.log('equipments', this.equipments)

            let editData = [];

            _.forEach(this.equipments[this.targetProduct], (item) => {
                let newItem = JSON.parse(JSON.stringify(item));

                delete(newItem.created);
                delete(newItem.creator);
                delete(newItem.beginDate);
                delete(newItem.updated);
                delete(newItem.updater);
                delete(newItem.valid);

                editData.push(newItem);
            });

            let apiUrl = this.$store.state.hostInService + "/equipment/edit";
            this.$http.put(apiUrl,editData)
            .then((response) => {
                console.log(response);
                if(response.status === 200 && response.data.code === 'success') {
                    this.buffer = response.data.data;
                }
                this.$message.success("수정되었습니다.");
                this.$emit('editEquipment');
            })
            .catch(error => {
                console.log(error);
                this.$message.error("수정되지 않았습니다.");
            });
        },
        getMaxEquipmentSeq() {
            let temp = _.map(this.equipments['waterpurifier'], 'equipmentSeq');
            let temp2 = _.map(this.equipments['airpurifier'], 'equipmentSeq');
            let temp3 = temp.concat(temp2);

            return (_.max(temp3) + 1);
        },
        addItem(equipmentSeq) {
            let seq = this.getMaxEquipmentSeq();
            let newArr = [];

            _.forEach(this.equipments[this.targetProduct], (item) => {
                newArr.push(item);

                if (item.equipmentSeq === equipmentSeq) {
                    let clone = _.cloneDeep(item);
                    clone.equipmentSeq = seq;
                    clone.processName = '';
                    clone.unitEquipment = '';
                    clone.devivce = '';
                    clone.inspectionDescription = '';
                    newArr.push(clone);
                }
            });

            this.equipments[this.targetProduct] = newArr;
        },
        removeItem(equipmentSeq) {
            if (confirm('삭제하시겠습니까?')) {
                this.equipments[this.targetProduct] = _.reject(this.equipments[this.targetProduct], {
                    equipmentSeq: equipmentSeq
                });
            }
        }
    }
}
</script>
<style scoped>
    .ant-form-item  {
        margin-bottom: 5px !important;
    }
    .ant-form-item-label  {
        font-weight: 700  !important;
    }
</style>