/****************************************************************************************
 * File Name    : ModalCheckItem.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  : : Modal->Tab Pane
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.__.__  	최명호    	Create
   1.1  2021.09.05      최명호      저장버튼클릭시 VueX 저장->입력항목 수정시 저장되도록
****************************************************************************************/
<template>
    <div>
        <a-form :form="form">
            
            <a-form-item :label="cInspectionTitle" v-show="true">
                <a-button icon="minus-circle" v-show="this.parentListSize > 1" @click="removeMe()" :ghost=true  type = 'danger' size = "small" style = 'position:absolute; top:-46px; left:170px;'> 삭제</a-button>
                <!-- <a-button icon="plus-circle" @click="addStandardItem()" :ghost=true  type = 'primary' size = "small" style = 'position:absolute; top:-46px; left:250px;'> 세부검사기준추가</a-button> -->
                <!--baseInput placeholder="검사항목" v-model="productCSInspection.inspectionName"/-->
                <a-input placeholder="항목" v-decorator="['inspectionName',{rules: [{ required: true, message: '검사항목을 입력하세요' }],initialValue:productCSInspection.inspectionName}]" v-on:input="event=>{productCSInspection.inspectionName = event.target.value} "  />
            </a-form-item>
        </a-form>
        <div v-for="(productCSInspectionStandard, i) in productCSInspection.listProductCSInpectionStandard" :key="i">
            <modal-check-item-standard-filter v-model="productCSInspection.listProductCSInpectionStandard[i]" :parentListSize="productCSInspection.listProductCSInpectionStandard.length" type="filter" :viewIndex="i" v-on:removeStarndardItem='removeStarndardItem'/>
        </div>
        <a-divider type = 'horizontal' />
    </div>
</template>
<script>
//import _ from "lodash";
//import BaseInput from "../components/BaseInput.vue"
import ModalCheckItemStandardFilter from "./ModalCheckItemStandardFilter.vue"
export default {
    name: 'ModalCheckItemFilter2',

    model: {prop: 'productCSInspection'},

    props : ['productCSInspection', 'viewIndex', 'parentListSize', 'tabTitle'],
    components: {
        //'baseInput': BaseInput,
        'modalCheckItemStandardFilter' : ModalCheckItemStandardFilter
    },
    data() {
        if (this.tabTitle === '4M 변경 현황') {
            this.cInspectionTagetTitle = '부품명';
            this.cInspectionTitle = '변경 내용';
        } else {
            this.cInspectionTagetTitle = '공정명';
            this.cInspectionTitle = '항목';
        }
        
        return {
            isDataLoaded    : false,
            spinning        : false,
            form : this.$form.createForm(this, {
                name: 'ModalCheckItemFilter',
            }),
        };
    },
    beforeCreate() {
    },

    mounted() {
        console.log('ModalCheckItemFilter2: produtCSInspection', this.productCSInspection);
        console.log('parentListSize:', this.parentListSize);
        //console.log(this.main_offset, this.sub_offset, this.inspection)
        this.isDataLoaded = true;
    },
    methods : {
        removeStarndardItem(index){
            this.productCSInspection.listProductCSInpectionStandard.splice(index,1);
        },
        removeMe() {
            this.$emit('removeItem', this.viewIndex);
        },
        addStandardItem() {
            //this.$emit('addStandardItem', this.type, this.main_offset, this.sub_offset);
            let productCSInspectionStandard = {
                index: 0,
                name : '',
                min : '',
                max : '',
                result : '',
                inspectionImageSeq            : '',
                inspectionDescription         : '',
            };
            //productCSInspectionStandard.index = this.productCSInspection.listProductCSInpectionStandard.length;

            this.productCSInspection.listProductCSInpectionStandard.push(productCSInspectionStandard);

        },
        updateInput(event) {
            console.log("event:", event);
            this.productCSInspection.inspectionTarget = event.target.value;
        }
    }
}
</script>
<style scoped>
</style>