/****************************************************************************************
 * File Name    : EquipmentForm.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
    <div class = ''>
        <div @click="closeDrawer()" style="position:absolute; top:10px; right:32px; font-size:16px; font-weight:bold; cursor:pointer;">X</div>
        <!-- v-bind="formItemLayout" -->
        <a-form class="" :form="form" layout = "vertical">
            <div>
                <div style="position:absolute; top: 12px; right:60px;">
                    라인
                    <select v-model="selectedTool.etc" style="margin-left:5px; margin-right:20px; padding:4px;" >
                        <option value="A라인">A라인</option>
                        <option value="B라인">B라인</option>
                        <option value="C라인">C라인</option>
                        <option value="D라인">D라인</option>
                        <option value="E라인">E라인</option>
                        <option value="F라인">F라인</option>
                        <option value="G라인">G라인</option>
                        <option value="H라인">H라인</option>
                        <option value="I라인">I라인</option>
                        <option value="J라인">J라인</option>
                        <option value="K라인">K라인</option>
                        <option value="L라인">L라인</option>
                        <option value="M라인">M라인</option>
                        <option value="N라인">N라인</option>
                        <option value="O라인">O라인</option>
                        <option value="P라인">P라인</option>
                        <option value="Q라인">Q라인</option>
                        <option value="R라인">R라인</option>
                        <option value="S라인">S라인</option>
                        <option value="T라인">T라인</option>
                        <option value="U라인">U라인</option>
                        <option value="V라인">V라인</option>
                        <option value="W라인">W라인</option>
                        <option value="X라인">X라인</option>
                        <option value="Y라인">Y라인</option>
                        <option value="Z라인">Z라인</option>
                    </select>
                    담당자
                    <a-input v-model="selectedTool.creator" style="margin-left:5px; width: 80px"/>
                </div>
            </div>
            <a-card>
                <h3>1. 토오크 관리</h3>
                <table style='width:100%; border: 1px solid #e8e8e8'>
                    <thead class="ant-table-thead">
                        <tr>
                            <th>공정명</th>
                            <th>공구종류</th>
                            <th>관리번호</th>
                            <th>측정값 (Kgm.cm)</th>
                            <th>측정자 성명</th>
                            <th>교환번호</th>
                            <th>비고</th>
                        </tr>
                    </thead>
                    <tbody class="ant-table-tbody">
                        <tr v-for="(item,index) in selectedTool['listTorque']" :key="index">
                            <td>
                                <a-input placeholder="" v-model="item.processName" />
                            </td>
                            <td>
                                <select v-model="item.toolKind"   style="padding:4px; width: 70px" >
                                    <option value="에어"> 에어 </option>
                                    <option value="전기"> 전기 </option>
                                    <option value="기타"> 기타 </option>
                                </select>
                            </td>
                            <td>
                                <a-input placeholder="" v-model="item.adminNo" />
                            </td>
                            <td>
                                <a-input placeholder="" v-model="item.value" />
                            </td>
                            <td>
                                <a-input placeholder="" v-model="item.tester" />
                            </td>
                            <td>
                                <a-input placeholder="" v-model="item.exchangeNo" />
                            </td>
                            <td>
                                <a-input placeholder="" v-model="item.etc" />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p />
                <h3>2. 가스세이버</h3>
                <table style='width:100%; border: 1px solid #e8e8e8'>
                    <thead class="ant-table-thead">
                        <tr>
                            <th>관리번호</th>
                            <th>LPG공급</th>
                            <th>산소공급</th>
                            <th>용접불꽃상태</th>
                            <th>점화불꽃상태</th>
                            <th>후럭스 청결</th>
                        </tr>
                    </thead>
                    <tbody class="ant-table-tbody">
                        <tr>
                            <td>
                                <a-input placeholder="" v-model="selectedTool.clsGasSaver.manageNo" />
                            </td>
                            <td>
                                <a-input placeholder="" v-model="selectedTool.clsGasSaver.lpg" />
                            </td>
                            <td>
                                <a-input placeholder="" v-model="selectedTool.clsGasSaver.oxygen" />
                            </td>
                            <td>
                                <select v-model="selectedTool.clsGasSaver.weldingStatus"   style="padding:4px; width: 70px" >
                                    <option value="OK"> OK </option>
                                    <option value="NG"> NG </option>
                                </select>
                            </td>
                            <td>
                                <select v-model="selectedTool.clsGasSaver.ignitionStatus"   style="padding:4px; width: 70px" >
                                    <option value="OK"> OK </option>
                                    <option value="NG"> NG </option>
                                </select>
                            </td>
                            <td>
                                <select v-model="selectedTool.clsGasSaver.fluxStatus"   style="padding:4px; width: 70px" >
                                    <option value="OK"> OK </option>
                                    <option value="NG"> NG </option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p />
                <h3>3. 계측기 관리</h3>
                <h4>(1) 냉매 차징기</h4>
                <table style='width:100%; border: 1px solid #e8e8e8'>
                    <thead class="ant-table-thead">
                        <tr>
                            <th>프레온 압력</th>
                            <th>진공도</th>
                            <th>프레온 온도</th>
                            <th>셋팅</th>
                            <th>충진량</th>
                            <th>S/W 상태</th>
                        </tr>
                    </thead>
                    <tbody class="ant-table-tbody">
                        <tr>
                            <td>
                                <a-input placeholder="" v-model="selectedTool.clsRefrigerantCharger.freonPressure" />
                            </td>
                            <td>
                                <a-input placeholder="" v-model="selectedTool.clsRefrigerantCharger.vaccum" />
                            </td>
                            <td>
                                <a-input placeholder="" v-model="selectedTool.clsRefrigerantCharger.freonTemp" />
                            </td>
                            <td>
                                <a-input placeholder="" v-model="selectedTool.clsRefrigerantCharger.setting" />
                            </td>
                            <td>
                                <a-input placeholder="" v-model="selectedTool.clsRefrigerantCharger.fillingAmount" />
                            </td>
                            <td>
                                <select v-model="selectedTool.clsRefrigerantCharger.sofwareStatus"   style="padding:4px; width: 70px" >
                                    <option value="OK"> OK </option>
                                    <option value="NG"> NG </option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p />
                <h4>(2) 내전압 시험기</h4>
                <table style='width:100%; border: 1px solid #e8e8e8'>
                    <thead class="ant-table-thead">
                        <tr>
                            <th>내전압 설정치</th>
                            <th>절연저항 설정치</th>
                            <th>지침 상태</th>
                            <th>S/W 상태</th>
                        </tr>
                    </thead>
                    <tbody class="ant-table-tbody">
                        <tr>
                            <td>
                                <a-input placeholder="" v-model="selectedTool.clsHipotTester.hipotSetting" />
                            </td>
                            <td>
                                <a-input placeholder="" v-model="selectedTool.clsHipotTester.insulationResistanceSetting" />
                            </td>
                            <td>
                                <select v-model="selectedTool.clsHipotTester.indicatorStatus"   style="padding:4px; width: 70px" >
                                    <option value="OK"> OK </option>
                                    <option value="NG"> NG </option>
                                </select>
                            </td>
                            <td>
                                <select v-model="selectedTool.clsHipotTester.sofwareStatus"   style="padding:4px; width: 70px" >
                                    <option value="OK"> OK </option>
                                    <option value="NG"> NG </option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p />
                <h4>(3) 접지 연속성 시험기</h4>
                <table style='width:100%; border: 1px solid #e8e8e8'>
                    <thead class="ant-table-thead">
                        <tr>
                            <th>전류 설정치</th>
                            <th>시간 설정치</th>
                            <th>표시창 상태</th>
                            <th>S/W 상태</th>
                        </tr>
                    </thead>
                    <tbody class="ant-table-tbody">
                        <tr>
                            <td>
                                <a-input placeholder="" v-model="selectedTool.clsEarthTester.currentSetting" />
                            </td>
                            <td>
                                <a-input placeholder="" v-model="selectedTool.clsEarthTester.timeSetting" />
                            </td>
                            <td>
                                <select v-model="selectedTool.clsEarthTester.indicatorStatus"   style="padding:4px; width: 70px" >
                                    <option value="OK"> OK </option>
                                    <option value="NG"> NG </option>
                                </select>
                            </td>
                            <td>
                                <select v-model="selectedTool.clsEarthTester.sofwareStatus"   style="padding:4px; width: 70px" >
                                    <option value="OK"> OK </option>
                                    <option value="NG"> NG </option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p />
                <h3>4. 검사장치 관리</h3>
                <h4>(1) 질소 검사</h4>
                <table style='width:100%; border: 1px solid #e8e8e8'>
                    <thead class="ant-table-thead">
                        <tr>
                            <th>기준 압력</th>
                            <th>안정화</th>
                            <th>검사시간</th>
                            <th>판정</th>
                            <th>터치패널 상태</th>
                            <th>압력계 상태</th>
                            <th>S/W 상태</th>
                        </tr>
                    </thead>
                    <tbody class="ant-table-tbody">
                        <tr>
                            <td>
                                <a-input placeholder="" v-model="selectedTool.clsNitrogen.pressure" />
                            </td>
                            <td>
                                <a-input placeholder="" v-model="selectedTool.clsNitrogen.stabilizationTime" />
                            </td>
                            <td>
                                <a-input placeholder="" v-model="selectedTool.clsNitrogen.inspectionTime" />
                            </td>
                            <td>
                                <a-input placeholder="" v-model="selectedTool.clsNitrogen.psi" />
                            </td>
                            <td>
                                <select v-model="selectedTool.clsNitrogen.touchPanelStatus"   style="padding:4px; width: 70px" >
                                    <option value="OK"> OK </option>
                                    <option value="NG"> NG </option>
                                </select>
                            </td>
                            <td>
                                <select v-model="selectedTool.clsNitrogen.gaugeStatus"   style="padding:4px; width: 70px" >
                                    <option value="OK"> OK </option>
                                    <option value="NG"> NG </option>
                                </select>
                            </td>
                            <td>
                                <select v-model="selectedTool.clsNitrogen.sofwareStatus"   style="padding:4px; width: 70px" >
                                    <option value="OK"> OK </option>
                                    <option value="NG"> NG </option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p />
                <h4>(2) 진공 검사</h4>
                <table style='width:100%; border: 1px solid #e8e8e8'>
                    <thead class="ant-table-thead">
                        <tr>
                            <th>주입 시간</th>
                            <th>안정화</th>
                            <th>검사시간</th>
                            <th>판정</th>
                            <th>터치패널 상태</th>
                            <th>압력계 상태</th>
                            <th>S/W 상태</th>
                        </tr>
                    </thead>
                    <tbody class="ant-table-tbody">
                        <tr>
                            <td>
                                <a-input placeholder="" v-model="selectedTool.clsVacuum.injectionTime" />
                            </td>
                            <td>
                                <a-input placeholder="" v-model="selectedTool.clsVacuum.stabilizationTime" />
                            </td>
                            <td>
                                <a-input placeholder="" v-model="selectedTool.clsVacuum.inspectionTime" />
                            </td>
                            <td>
                                <a-input placeholder="" v-model="selectedTool.clsVacuum.psi" />
                            </td>
                            <td>
                                <select v-model="selectedTool.clsVacuum.touchPanelStatus"   style="padding:4px; width: 70px" >
                                    <option value="OK"> OK </option>
                                    <option value="NG"> NG </option>
                                </select>
                            </td>
                            <td>
                                <select v-model="selectedTool.clsVacuum.gaugeStatus"   style="padding:4px; width: 70px" >
                                    <option value="OK"> OK </option>
                                    <option value="NG"> NG </option>
                                </select>
                            </td>
                            <td>
                                <select v-model="selectedTool.clsVacuum.sofwareStatus"   style="padding:4px; width: 70px" >
                                    <option value="OK"> OK </option>
                                    <option value="NG"> NG </option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p />
                <h4>(3) 전류 검사</h4>
                <table style='width:100%; border: 1px solid #e8e8e8'>
                    <thead class="ant-table-thead">
                        <tr>
                            <th>전류 설정치</th>
                            <th>터치패널 상태</th>
                        </tr>
                    </thead>
                    <tbody class="ant-table-tbody">
                        <tr>
                            <td>
                                <a-input placeholder="" v-model="selectedTool.clsCurrent.currentSetting" />
                            </td>
                            <td>
                                <select v-model="selectedTool.clsCurrent.touchPanelStatus"   style="padding:4px; width: 70px" >
                                    <option value="OK"> OK </option>
                                    <option value="NG"> NG </option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p />
            </a-card>
            <p/>
            <div style="text-align:center;">
                <a-button @click="closeDrawer()" type="default" icon="plus-circle" size = "default">
                                취소
                            </a-button>
                <a-button type="default" icon="plus-circle" size = "default" @click="submit('A')" style="margin-left:20px;">
                            임시저장
                </a-button>
                <a-button type="primary" icon="plus-circle" size = "default" @click="submit('B')" style="margin-left:20px;">
                            완료
                        </a-button>
            </div>
        </a-form>

    </div>
</template>
<script>
import _ from "lodash";
export default {
    name: 'ToolForm',
    props: ['selectedTool'],
    data() {
        return {
            formItemLayout: {
                labelCol: { span: 3 },
                wrapperCol: { span: 21 },
            },
        };
    },
    mounted() {
        console.log('selectedTool', this.selectedTool)
    },
    watch: {
        selectedTool: function(newOne){
            this.selectedTool = newOne;
            console.log('selectedTool', this.selectedTool)

            this.selectedTool.creator = sessionStorage.getItem('user_name');
        }
    },
    beforeCreate() {
        this.form = this.$form.createForm(this, { name: 'validate_other' });
    },
    methods : {
        timeToDatestr(timestamp) {
            let dateObj = new Date(timestamp);

            let year = dateObj.getFullYear();
            let month = dateObj.getMonth() + 1;
            if (month < 10) {
                month = '0' + month;
            }
            let date = dateObj.getDate();
            if (date < 10) {
                date = '0' + date;
            }
            let hour = dateObj.getHours();
            if (hour < 10) {
                hour = '0' + hour;
            }
            let minute = dateObj.getMinutes();
            if (minute < 10) {
                minute = '0' + minute;
            }
            let second = dateObj.getSeconds();
            if (second < 10) {
                second = '0' + second;
            }

            return year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second;
        },
        submit(valid) {

            let editData = JSON.parse(JSON.stringify(this.selectedTool));
            editData.equipmentLineInfoSeq = editData.clsEquipmentLineInfo.equipmentLineInfoSeq;

            // 이전 상태가 검사완료 또는 승인완료 이거나 이번에 변경할 상태가 승인완료일 경우 시간을 업데이트 하지 않음
            if (editData.valid === 'B' || editData.valid === 'C' || valid === 'C') {
                // 시간을 업데이트 하지 않음
            } else {
                editData.updated2 = this.timeToDatestr(new Date());
            }

            if (!editData.etc) {
                this.$message.error("라인을 선택해주십시오");
                return;
            }

            delete(editData.created);
            // delete(editData.creator);
            delete(editData.updated);
            delete(editData.updater);
            delete(editData.valid);
            delete(editData.no);
            delete(editData.clsEquipmentLineInfo);

            editData.valid = valid;

            _.forEach(editData.listTorque, function(item) {
                delete(item.created);
                delete(item.creator);
                delete(item.updated);
                delete(item.updater);
                delete(item.valid);
            });

            delete(editData.clsGasSaver.created);
            delete(editData.clsGasSaver.creator);
            delete(editData.clsGasSaver.updated);
            delete(editData.clsGasSaver.updater);
            delete(editData.clsGasSaver.valid);

            delete(editData.clsRefrigerantCharger.created);
            delete(editData.clsRefrigerantCharger.creator);
            delete(editData.clsRefrigerantCharger.updated);
            delete(editData.clsRefrigerantCharger.updater);
            delete(editData.clsRefrigerantCharger.valid);

            delete(editData.clsHipotTester.created);
            delete(editData.clsHipotTester.creator);
            delete(editData.clsHipotTester.updated);
            delete(editData.clsHipotTester.updater);
            delete(editData.clsHipotTester.valid);

            delete(editData.clsEarthTester.created);
            delete(editData.clsEarthTester.creator);
            delete(editData.clsEarthTester.updated);
            delete(editData.clsEarthTester.updater);
            delete(editData.clsEarthTester.valid);

            delete(editData.clsNitrogen.created);
            delete(editData.clsNitrogen.creator);
            delete(editData.clsNitrogen.updated);
            delete(editData.clsNitrogen.updater);
            delete(editData.clsNitrogen.valid);

            delete(editData.clsVacuum.created);
            delete(editData.clsVacuum.creator);
            delete(editData.clsVacuum.updated);
            delete(editData.clsVacuum.updater);
            delete(editData.clsVacuum.valid);

            delete(editData.clsCurrent.created);
            delete(editData.clsCurrent.creator);
            delete(editData.clsCurrent.updated);
            delete(editData.clsCurrent.updater);
            delete(editData.clsCurrent.valid);

            editData.listTorqueVo = editData.listTorque;
            editData.clsGasSaverVo = editData.clsGasSaver;
            editData.clsRefrigerantChargerVo = editData.clsRefrigerantCharger;
            editData.clsHipotTesterVo = editData.clsHipotTester;
            editData.clsEarthTesterVo = editData.clsEarthTester;
            editData.clsNitrogenVo = editData.clsNitrogen;
            editData.clsVacuumVo = editData.clsVacuum;
            editData.clsCurrentVo = editData.clsCurrent;

            delete(editData.listTorque);
            delete(editData.clsGasSaver);
            delete(editData.clsRefrigerantCharger);
            delete(editData.clsHipotTester);
            delete(editData.clsEarthTester);
            delete(editData.clsNitrogen);
            delete(editData.clsVacuum);
            delete(editData.clsCurrent);

            // 생성
            if (!editData.toolInspectionSeq) {
                let apiUrl = this.$store.state.hostInService + "/tool/inspection/add";
                this.$http.post(apiUrl,editData)
                .then((response) => {
                    console.log(response);
                    if(response.status === 200 && response.data.code === 'success') {
                        this.buffer = response.data.data;
                    }
                    this.$message.success("생성되었습니다.");
                    this.$emit('editToolInfo');
                })
                .catch(error => {
                    console.log(error);
                    this.$message.error("생성되지 않았습니다.");
                });
            }
            // 수정
            else {
                let apiUrl = this.$store.state.hostInService + "/tool/inspection/edit";
                this.$http.put(apiUrl,editData)
                .then((response) => {
                    console.log(response);
                    if(response.status === 200 && response.data.code === 'success') {
                        this.buffer = response.data.data;
                    }
                    this.$message.success("수정되었습니다.");
                    this.$emit('editToolInfo');
                })
                .catch(error => {
                    console.log(error);
                    this.$message.error("수정되지 않았습니다.");
                });
            }
        },
        closeDrawer() {
            this.$emit('flagForDrawer');
        }
    }
}
</script>
<style scoped>
    .ant-form-item  {
        margin-bottom: 5px !important;
    }
    .ant-form-item-label  {
        font-weight: 700  !important;
    }
</style>