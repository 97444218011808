/****************************************************************************************
 * File Name    : SheetPatrol.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
    <div>
        <a-row :gutter="16">
            <a-col class="gutter-row" :span="12">
                <div class="gutter-box">
                    <PageHeader title = "등록하기" />
                </div>
            </a-col>
        </a-row>
        <a-form class="" :form="form" layout = "vertical"  @submit="onSubmit">
            <a-card>
                <a-form-item label="모델명">
                    <a-input placeholder="" v-decorator="['model',{initialValue: model, rules: [{ required: true, message: '모델명을 입력하세요' }],},] "/>
                </a-form-item>
                <a-form-item label="부품명">
                    <a-input placeholder="" v-decorator="['part',{initialValue: part, rules: [{ required: true, message: '부품명을 입력하세요' }],},] "/>
                </a-form-item>
                <a-form-item label="변경목적">
                    <a-input placeholder="" v-decorator="['purpose',{initialValue: purpose, rules: [{ required: true, message: '변경목적을 입력하세요' }],},] "/>
                </a-form-item>
                <a-form-item label="변경내용">
                    <a-textarea placeholder="" rows="5" v-decorator="['content',{initialValue: content, rules: [{ required: true, message: '변경내용을 입력하세요' }],},] "/>
                </a-form-item>
                <a-form-item label="참고사진">
                    <div v-if="imgSrc.length">
                        <span v-for="(item) in imgSrc" :key="item.seq" style="margin-right:20px;">
                            <img :src = "item.src" style="max-width:200px;">
                            <span @click="deleteImg(item.seq);" style="cursor:pointer; vertical-align:bottom;">X</span>
                        </span>
                    </div>
                    <a-spin :spinning="spinning">
                        <a-upload name="file" :multiple="false" :action="$store.state.imageUploadServer" @change="imageUpload"
                            v-decorator="['photo',{rules: [{ required: false, message: '사진을 선택하세요' }],},]">
                            <a-button> <a-icon type="camera" /> 사진 선택 </a-button>
                        </a-upload>
                    </a-spin>
                </a-form-item>
                <a-form-item label="태블릿 표시">
                    <input type="checkbox" v-model="tablet" value="Y" />
                </a-form-item>
                <a-form-item label="공지시작일">
                    <date-picker v-model="start" valueType="format"></date-picker>
                </a-form-item>
                <a-form-item label="공지종료일">
                    <date-picker v-model="end" valueType="format"></date-picker>
                </a-form-item>
            </a-card>
            <p/>
            <a-button type="primary" html-type="submit" :ghost = false size = "default" icon = "plus-circle">저장</a-button>
            <a-divider type = 'vertical' />
            <a-button type="danger" html-type="reset" :ghost = false  size = "default" icon = "reload">초기화</a-button>
        </a-form>
    </div>
</template>
<script>
import _ from "lodash";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import PageHeader from "../components/PageHeader.vue";
export default {
    name: 'Patrol4m',
    components: {
        PageHeader ,
        DatePicker, 
    },
    data() {
        return {
            isDataLoaded : false,
            flagForDrawer: false,
            fileUploadSeq : '',
            spinning : false,
            image: null,
            model: null,
            part: null,
            purpose: null,
            content: null,
            photo: null,
            tablet: null,
            start: null,
            end: null,
            imgSrc: [],
            fileDelimiter: '||',
        };
    },
    beforeCreate() {
        this.form = this.$form.createForm(this, { name: 'validate_other' });
    },
    mounted() {
        console.log(_);

        if (this.$route.query.seq) {
            let url = 'https://cowaylqc.iotinfras.com:8100/patrol/4mitem/' + this.$route.query.seq;
            this.$http.get(url)
                .then((response) => {
                    console.log(response.data);
                    let item = response.data;

                    this.model = item.MODEL;
                    this.part = item.PART;
                    this.purpose = item.PURPOSE;
                    this.content = item.CONTENT;
                    this.tablet = item.TABLET === 'Y' ? true : false;
                    this.start = item.START;
                    this.end = item.END;
                    this.fileUploadSeq = item.UPLOAD_FILE_SEQ;

                    let files = this.fileUploadSeq.split(this.fileDelimiter);
                    _.forEach(files, (seq) => {
                        if (seq) {
                            this.imgSrc.push({
                                seq: seq,
                                src: this.$store.state.hostInService +'/upload/image/download/' + seq
                            });
                        }
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    },
    methods : {
        deleteImg(seq) {
            if (confirm('선택한 이미지를 삭제하시겠습니까?')) {
                let files = this.fileUploadSeq.split(this.fileDelimiter);
                files = _.reject(files, (item) => {
                    return item == seq;
                });
                this.fileUploadSeq = files.join(this.fileDelimiter);

                this.imgSrc = [];
                _.forEach(files, (seq) => {
                    if (seq) {
                        this.imgSrc.push({
                            seq: seq,
                            src: this.$store.state.hostInService +'/upload/image/download/' + seq
                        });
                    }
                });    
            }
        },
        onSubmit(e) {
            e.preventDefault();

            console.log(this.fileUploadSeq);

            this.form.validateFields((err, values) => {
                if(!err) {
                    if (this.$route.query.seq) {
                        let formData = {};
                        formData.model                = values.model;
                        formData.part              = values.part;
                        formData.purpose               = values.purpose;
                        formData.content          = values.content;
                        formData.tablet               = this.tablet ? 'Y' : 'N';
                        formData.start               = this.start;
                        formData.end                = this.end;
                        formData.upload_file_seq     = this.fileUploadSeq;

                        let url = 'https://cowaylqc.iotinfras.com:8100/patrol/4mupdate/' + this.$route.query.seq;
                        this.$http.put(url,formData)
                            .then((response) => {
                                console.log(response);
                                this.$message.success('저장되었습니다');
                                location = '#/patrol4mList';
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    } else {
                        let formData = {};
                        formData.model                = values.model;
                        formData.part              = values.part;
                        formData.purpose               = values.purpose;
                        formData.content          = values.content;
                        formData.tablet               = this.tablet ? 'Y' : 'N';
                        formData.start               = this.start;
                        formData.end                = this.end;
                        formData.upload_file_seq     = this.fileUploadSeq;
                        
                        let url = 'https://cowaylqc.iotinfras.com:8100/patrol/4mcreate';
                        this.$http.post(url,formData)
                            .then((response) => {
                                console.log(response);
                                this.$message.success('저장되었습니다');
                                location = '#/patrol4mList';
                            })
                            .catch(error => {
                                console.log(error);
                            });
                        }
                    }
            });
        },
        imageUpload(info) {
            this.spinning = info.file.status === 'uploading' ? true : false;
            if(info.file.status === 'done') {
                let files = this.fileUploadSeq.split(this.fileDelimiter);
                files = _.filter(files, (seq) => {
                    return seq;
                });
                this.$message.success(`${info.file.name} file uploaded successfully`);
                files.push(info.file.response.data.uploadFileSeq);
                this.fileUploadSeq = files.join(this.fileDelimiter);
            } else if(info.file.status === 'error') {
                this.$message.error(`${info.file.name} file upload failed.`);
            }
        }
    }
}
</script>
<style scoped>
</style>