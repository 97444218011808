/****************************************************************************************
 * File Name    : Data.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
    <div style="margin-left: -24px; margin-right: -24px;">
        <a-row :gutter="16">
            <a-col :span="3">
                
            </a-col>
            <a-col :span="21">
                <a-row :gutter="16">
                    <a-col class="gutter-row" :span="24">
                        <div class="gutter-box">
                            <PageHeader title = "초/중/종품검사 부적합 현황" />
                        </div>
                    </a-col>
                </a-row>
                <a-divider type = 'horizontal' />
                <img id="loading-table" class="more-btn" src="../assets/images/Loading_2.gif" style="position:absolute; top:65%; left:50%; margin-top:-30px; margin-left:-30px; width:60px; z-index:10;" />
                <a-table :columns="columns4" size = "small" :bordered=false :data-source="csTableData3" :pagination="{ pageSize: 15 }" :scroll="{ x: 0, y: '80vh' }">
                    <span slot="avatar" slot-scope="">
                        <a-avatar src = "https://static.turbosquid.com/Preview/001180/025/TE/conveyor-belt-3D_600.jpg" shape="square" icon="user" class = "avatar" style="width:auto; height:80px;" />
                    </span>
                </a-table>
                <p/>
            </a-col>
        </a-row>
    </div>
</template>
<script>
const columns4 = [
    // {    title: '번호'    ,   dataIndex: 'key'       ,   key: 'key'      ,  scopedSlots: { customRender: 'name'          }, width: 50 },
    // {    title: '이미지'  ,   dataIndex: 'images'   ,   key: 'images'   ,  scopedSlots: { customRender: 'avatar'        }, width: 110 },
    {    title: '라인'    ,   dataIndex: 'line'     ,   key: 'line'     ,  scopedSlots: { customRender: 'tags'          },  },
    {    title: '모델명'    ,   dataIndex: 'model'     ,   key: 'model'     ,  scopedSlots: { customRender: 'tags'          },  },
    {    title: '검사항목'    ,   dataIndex: 'inspectionName'     ,   key: 'inspectionName'     ,  scopedSlots: { customRender: 'tags'          },  },
    {    title: '부적합 내역'    ,   dataIndex: 'detail'     ,   key: 'detail'     ,  scopedSlots: { customRender: 'name'          },              },
    {    title: '최종수정일',  dataIndex: 'updated', key: 'updated'  ,  scopedSlots: { customRender: 'updated'     },},
];

import _ from "lodash";
import PageHeader      from "../components/PageHeader.vue";
export default {
    name: 'DashboardDetailFinishedNg',
    components: {
        PageHeader
    },
    data() {
        return {
            isDataLoaded : false,
            flagForDrawer: false,
            data : [],
            dataArray: [],
            columns4,
            csData1 : [],
            csTableData3 : [],  // 초/중/종품검사 부적합 현황
            date: {
                currentYear: null,
                currentMonth: null,
                months: []
            }
        };
    },
    mounted() {
        this.loadList();

        let dateObj = new Date();
        this.date.currentYear = dateObj.getFullYear();
        this.date.currentMonth = dateObj.getMonth() + 1;
        for (let i = 1; i <= this.date.currentMonth; i++) {
            let month = i;
            if (month < 10) {
                month = '0' + i;
            }
            this.date.months.push(month);
        }
        if (this.date.currentMonth < 10) {
            this.date.currentMonth = '0' + this.date.currentMonth;
        }
    },
    methods : {
        loadList() {
            this.csData1 = [];
            let csTableData3 = [];
            let urlToFetchProductCSList = "https://cowaylqc.iotinfras.com:8100/product/cs/list_year?size=10000&page=0";
            this.$http.get(urlToFetchProductCSList)
            .then((response) => {
                if(response.status === 200 && response.data.code === 'success') {
                    this.csData1 = _.filter(response.data.data.content, (item) => {
                        // return (item.state === 'C' || item.state === 'D') && item.updated.substr(0, 4) == this.date.currentYear;   // 올해 데이터
                        return (item.state === 'D' || item.state === 'J') && item.updated.substr(0, 4) == this.date.currentYear;   // 올해 데이터
                    });
                    this.csData1 = _.orderBy(this.csData1, ['updated'], ['asc']);  // 수정 날짜순 정렬
                }

                this.flagSpinner = false;
                document.getElementById('loading-table').style.display = 'none';
                
                // 초/중/종품검사 부적합 현황
                let count1 = 0;
                _.forEach(this.csData1, (item) => {
                    item.ng = false;
                    _.forEach(item.listProductCSInpection, (item2) => {
                        _.forEach(item2.listProductCSInpectionStandard, (item3) => {
                            if (item3.min === 'NG') {
                                count1++;
                                item.ng = true;

                                // TO DO - 서버에서 수정한 후 이 코드 삭제 - updated 가 9 시간이 작게 나와서 프론트엔드에서 수정
                                let updatedObj = new Date((new Date(item.updated)).getTime() + 0 * 60 * 60 * 1000);
                                let year = updatedObj.getFullYear();
                                let month = updatedObj.getMonth() + 1;
                                if (month < 10) {
                                    month = '0' + month;
                                }
                                let date = updatedObj.getDate();
                                if (date < 10) {
                                    date = '0' + date;
                                }
                                let hour = updatedObj.getHours();
                                if (hour < 10) {
                                    hour = '0' + hour;
                                }
                                let minute = updatedObj.getMinutes();
                                if (minute < 10) {
                                    minute = '0' + minute;
                                }
                                let second = updatedObj.getSeconds();
                                if (second < 10) {
                                    second = '0' + second;
                                }

                                // 초/중/종품검사 부적합 현황 테이블 데이터 생성
                                csTableData3.push({
                                    key: count1,
                                    images: this.$store.state.hostInService +'/upload/image/download/' + item2.inspectionImageSeq2,
                                    detail: item3.ngTitle,
                                    model: item.clsProduct.productModel,
                                    line: item.line,
                                    inspectionName: item2.inspectionName,
                                    updated : year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second,
                                });
                            }
                        });
                    });
                });
                csTableData3 = csTableData3.reverse();
                this.csTableData3 = csTableData3;
            }).catch(error => {
                console.log(error);
                this.flagSpinner = false;
            });
        },
    }
}
</script>
<style scoped>
</style>