/****************************************************************************************
 * File Name    : EquipmentControls.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
    <div>
        <a-row :gutter="16">
            <a-col class="gutter-row" :span="24">
                <div class="gutter-box">
                    <PageHeader title = "설비 점검 검사" />
                </div>
            </a-col>
            <!-- <a-col class="gutter-row" :span="12">
                <div class="gutter-box div_action">
                    <span class = 'icon_button' @click="handlerForButtonNew">
                        <a-tag color="#2980B9">
                            <a-icon type="plus-circle" /> 설치점검항목 등록
                        </a-tag>
                    </span>
                </div>
            </a-col> -->
        </a-row>
        <a-divider type = 'horizontal' />
        <div v-if = "1===2">
            <a-row type="flex" justify="center" align="top" :gutter="8" >
                <a-col :span="24">
                    <EquipmentControl name = "컨베이어벨트" type = "list" :description = "['공통']"
                        v-on:addEquipmentControl="addEquipmentControl" v-on:editEquipmentControl="editEquipmentControl" v-on:deleteEquipmentControl="deleteEquipmentControl"/>
                </a-col>
                <a-col :span="24" v-if="isSelectedEquipment === true">
                    <EquipmentControl name = "컨베이어벨트" type = "list" :description = "['공통']"
                        v-on:addEquipmentControl="addEquipmentControl" v-on:editEquipmentControl="editEquipmentControl" v-on:deleteEquipmentControl="deleteEquipmentControl"/>
                </a-col>
            </a-row>
        </div>
        <p/>
        <div class = 'todo'>검색필터 : 구분,구성항목,이름 등.</div>
        <a-space align="end">
            <a-statistic title="등록된 자료수" :value="1128" style="margin-right: 50px">
                <template #prefix>
                    <a-icon type="check-circle" />
                </template>
            </a-statistic>
            <a-radio-group style = ''>
                <a-radio-button value="정수기전용">정수기전용</a-radio-button>
                <a-radio-button value="공통">공통</a-radio-button>
                <a-radio-button value="모두">모두보기</a-radio-button>
            </a-radio-group>
            <a-radio-group style = ''>
                <a-radio-button value="배관부">배관부</a-radio-button>
                <a-radio-button value="제어부">제어부</a-radio-button>
                <a-radio-button value="모두">모두보기</a-radio-button>
            </a-radio-group>
            <a-input-search style = '' placeholder="검색할 이름을 입력하세요"  />
            <!-- <router-link to="/equipmentControlAdd">
                <a-button type="primary" icon="plus-circle" size = "default" >
                    설비 점검 항목 등록
                </a-button>
            </router-link> -->
        </a-space>
        <p/>
        <div class = 'todo'>'항목'은 설비등록시 등록된 '구성장치'를 출력한다.</div>
        <a-table :columns="columns" size = "small" :bordered=false :data-source="data" :pagination="false" :scroll="{ x: 0, y: '57vh' }">
            <span slot="tags" slot-scope="tags">
                <a-tag v-for="tag in tags" :key="tag" :color="tag === '보류' ? '#CDCDCD' : '#929292'" >
                    {{ tag.toUpperCase() }}
                </a-tag>
            </span>
            <span slot="avatar" slot-scope="">
                <a-avatar src = "https://static.turbosquid.com/Preview/001180/025/TE/conveyor-belt-3D_600.jpg" shape="square" icon="user" class = "avatar"/>
            </span>
            <span slot="name" slot-scope="text">{{ text }}</span>
            <span slot="items" slot-scope="items">
                 <a-tag v-for="item in items" :key="item" :color="item === '보류' ? '#CDCDCD' : '#929292'" >
                    {{ item.toUpperCase() }}
                </a-tag>
            </span>
            <span slot="action" slot-scope="record">
                <!-- <span @click = "addEquipmentControl"><a-icon type="plus" /> Add</span>
                <a-divider type="vertical" /> -->
                 <div v-if = "record.control === true">
                    <span @click = "editToolControl"><a-icon type="edit" /> Edit</span>
                    <a-divider type="vertical" />
                    <span @click = "editToolControl"><a-icon type="delete" /> Delete</span>
                </div>
            </span>
            <span slot="control" slot-scope="control">
                <a-icon v-if = "control === true" type="check-circle" />
                <a-button  v-else type="primary" icon="plus" size = "small" ghost=true @click="editToolControl">Add</a-button>
            </span>
        </a-table>
        <p/>
        <a-modal v-model="isVisibleForModalEquipmentList" title="" @ok="onClickButtonInModalEquipmentList" :footer = null width = "550px">
            <ModalEquipmentList v-on:selectEquipment="selectEquipment"/>
        </a-modal>
        <a-drawer title="설비점검항목 등록/수정/삭제" placement="right" width="70vw" :closable="false" :visible="flagForDrawer" :get-container="false" @close="closeDrawer" >
            <EquipmentControlsAdd />
        </a-drawer>
    </div>
</template>
<script>
import PageHeader           from "../components/PageHeader.vue"             ;
import ModalEquipmentList   from "../modals/ModalEquipmentList.vue"         ;
import EquipmentControl     from "../components/EquipmentControl.vue"       ;
import EquipmentControlsAdd from "../contents/EquipmentControlsAdd.vue"   ;
const columns = [
    {    title: '번호'    ,   dataIndex: 'no'       ,   key: 'key'      ,  scopedSlots: { customRender: 'name'          },  width : 50 },
    {    title: '이미지'  ,   dataIndex: 'images'   ,   key: 'images'   ,  scopedSlots: { customRender: 'avatar'        },  width : 100 },
    {    title: '구분'    ,   dataIndex: 'tags'     ,   key: 'tags'     ,  scopedSlots: { customRender: 'tags'          },  width : 150 },
    {    title: '설비이름',   dataIndex: 'name'     ,   key: 'name'     ,  scopedSlots: { customRender: 'name'          },              },
    {    title: '항목'    ,   dataIndex: 'items'    ,   key: 'items'    ,  scopedSlots: { customRender: 'items'         },  },
    {    title: '담당자'  ,   dataIndex: 'op'       ,   key: 'op'       ,  scopedSlots: { customRender: 'name'          },  width : 150 },
    {    title: '점검항목',   dataIndex: 'control'  ,   key: 'control'  ,  scopedSlots: { customRender: 'control'       },  width : 100 },
    {    title: '관리'                              ,   key: 'action'   ,  scopedSlots: { customRender: 'action'        },  width : 250 },
];
const data = [
  {    key: '1',    control : true  ,   items : ['배관부','제어부'],  name: '컨베이어벨트'        ,  tags: ['공통']       ,  op : '홍길동',   images : 'https://static.turbosquid.com/Preview/001180/025/TE/conveyor-belt-3D_600.jpg'},
  {    key: '2',    control : false ,   items : ['배관부','진공펌프'],  name: '진공펌프진공펌프'    ,  tags: ['정수기전용'] ,  op : '홍길동',   images : 'https://static.turbosquid.com/Preview/001180/025/TE/conveyor-belt-3D_600.jpg'},
  {    key: '3',    control : true  ,   items : ['배관부'],  name: '진공펌프진공펌프'    ,  tags: ['정수기전용'] ,  op : '홍길동',   images : 'https://static.turbosquid.com/Preview/001180/025/TE/conveyor-belt-3D_600.jpg'},
  {    key: '4',    control : true  ,   items : ['진공펌프'],  name: '진공펌프진공펌프'    ,  tags: ['정수기전용'] ,  op : '홍길동',   images : 'https://static.turbosquid.com/Preview/001180/025/TE/conveyor-belt-3D_600.jpg'},
];
export default {
    name: 'EquipmentControls',
    components: {
        PageHeader,
        ModalEquipmentList,
        EquipmentControl,
        EquipmentControlsAdd,
    },
    data() {
        return {
            isDataLoaded : false,
            isVisibleForModalEquipmentList : false,
            isSelectedEquipment : false,
            flagForDrawer : false,
            data,
            columns,
        };
    },
    mounted() {
        this.isDataLoaded = true;
    },
    methods : {
        editToolControl() {
             this.flagForDrawer = true;
        },
        showDrawer() {
            this.flagForDrawer = true;
        },
        closeDrawer() {
            this.flagForDrawer = false;
        },
        handlerForButtonNew() {
            this.showModalEquipmentList();
        },
        showModalEquipmentList() {
            this.isVisibleForModalEquipmentList = true;
        },
        selectEquipment() {
            this.isVisibleForModalEquipmentList = false;
            this.isSelectedEquipment = true;
        },
        addEquipmentControl() {
            console.log("## addEquipmentControl in EquipmentControls ##");
            this.showDrawer();
        },
        editEquipmentControl() {
            console.log("## editEquipmentControl in EquipmentControls ##");
            this.showDrawer();
        },
        deleteEquipmentControl() {
            console.log("## deleteEquipmentControl in EquipmentControls ##");
            this.showDrawer();
        },
        onClickButtonInModalEquipmentList(e) {
            console.log(e);
            this.isVisibleForModalEquipmentList = false;
        },
    }
}
</script>
<style scoped>
</style>