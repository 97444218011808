/****************************************************************************************
 * File Name    : Dashboard.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
    <div class="dashboard-container" style="margin-left: -24px; margin-right: -24px;">
        <a-row :gutter="16">
            <a-col :span="3">
                <a-row :gutter="16">
                    <a-col :span="24">
                        <div class="left-stat-container color-border red">
                            <div class="section">
                                <div class="section-title">{{('' +  date.currentYear).substr(2,2)}}년 종합 검사 현황</div>
                                <ul>
                                    <li>
                                        <span class="label">검사 현황</span>
                                        <span class="value">{{statData.year.all}}건</span>
                                    </li>
                                    <li>
                                        <span class="label">부적합 현황</span>
                                        <span class="value">{{statData.year.ng}}건</span>
                                    </li>
                                    <li>
                                        <span class="label">부적합율</span>
                                        <span class="value">{{statData.year.ngRate}}%</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <p />
                        <div class="left-stat-container color-border green">
                            <div class="section">
                                <div class="section-title">{{date.currentMonth}}월 정수기 검사 현황</div>
                                <ul>
                                    <li>
                                        <span class="label">검사 현황</span>
                                        <span class="value">{{statData.month.water.all}}건</span>
                                    </li>
                                    <li>
                                        <span class="label">부적합 현황</span>
                                        <span class="value">{{statData.month.water.ng}}건</span>
                                    </li>
                                    <li>
                                        <span class="label">부적합율</span>
                                        <span class="value">{{statData.month.water.ngRate}}%</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <p />
                        <div class="left-stat-container color-border blue">
                            <div class="section">
                                <div class="section-title">{{date.currentMonth}}월 청정기 검사 현황</div>
                                <ul>
                                    <li>
                                        <span class="label">검사 현황</span>
                                        <span class="value">{{statData.month.air.all}}건</span>
                                    </li>
                                    <li>
                                        <span class="label">부적합 현황</span>
                                        <span class="value">{{statData.month.air.ng}}건</span>
                                    </li>
                                    <li>
                                        <span class="label">부적합율</span>
                                        <span class="value">{{statData.month.air.ngRate}}%</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </a-col>
                </a-row>
            </a-col>
            <a-col :span="21">
                <a-row :gutter="16">
                    <a-col :span="16">
                        <a-card title="종합 검사 현황" class="color-border red">
                            <a slot="extra" href="#" @click="viewDetail('finished')"><img class="more-btn" src="../assets/images/btn-more.jpg" /></a>
                            <div>
                                <highcharts :options="chartOptions1" />
                            </div>
                        </a-card>
                    </a-col>
                    <a-col :span="8">
                        <a-card title="초/중/종품 부적합 현황" class="bold-border">
                            <a slot="extra" href="#" @click="viewDetail('finishedNg')"><img class="more-btn" src="../assets/images/btn-more.jpg" /></a>
                            <div style="height:180px; position:relative;" class="dashboard-table">
                                <img id="loading-finished" class="more-btn" src="../assets/images/Loading_2.gif" style="position:absolute; top:50%; left:50%; margin-top:-30px; margin-left:-30px; width:60px; z-index:10;" />
                                <a-table :columns="columns4" size = "small" :bordered=false :data-source="csTableData3" :pagination="false" :scroll="{ x: 0, y: '40vh' }">
                                </a-table>
                            </div>
                        </a-card>
                    </a-col>
                </a-row>
                <p />
                <a-row :gutter="16">
                    <a-col :span="16">
                        <a-card title="정수기 검사 현황 주별" class="color-border green">
                            <a slot="extra" href="#" @click="viewDetail('finished1')"><img class="more-btn" src="../assets/images/btn-more.jpg" /></a>
                            <div>
                                <a-row :gutter="16">
                                    <a-col :span="24">
                                        <highcharts :options="chartOptions2" />
                                    </a-col>
                                </a-row>
                            </div>
                        </a-card>
                    </a-col>
                    <a-col :span="8">
                        <a-card title="공정순회 부적합 현황" class="bold-border">
                            <a slot="extra" href="#" @click="viewDetail('patrolNg')"><img class="more-btn" src="../assets/images/btn-more.jpg" /></a>
                            <div style="height:180px; position:relative;">
                                <img id="loading-patrol" class="more-btn" src="../assets/images/Loading_2.gif" style="position:absolute; top:50%; left:50%; margin-top:-30px; margin-left:-30px; width:60px; z-index:10;" />
                                <a-table :columns="columns4" size = "small" :bordered=false :data-source="csTableData4" :pagination="false" :scroll="{ x: 0, y: '40vh' }" class="dashboard-table">
                                </a-table>
                            </div>
                        </a-card>
                    </a-col>
                </a-row>
                <p />
                <a-row :gutter="16">
                    <a-col :span="16">
                        <a-card title="청정기 검사 현황 주별" class="color-border blue">
                            <a slot="extra" href="#" @click="viewDetail('finished2')"><img class="more-btn" src="../assets/images/btn-more.jpg" /></a>
                            <div>
                                <a-row :gutter="16">
                                    <a-col :span="24">
                                        <highcharts :options="chartOptions3" />
                                    </a-col>
                                </a-row>
                            </div>
                        </a-card>
                    </a-col>
                    <a-col :span="8">
                        <a-card title="4M 변경 현황" class="bold-border">
                            <a slot="extra" href="#" @click="viewDetail('patrol4m')"><img class="more-btn" src="../assets/images/btn-more.jpg" /></a>
                            <div style="height:180px; position:relative;">
                                <img id="loading-4m" class="more-btn" src="../assets/images/Loading_2.gif" style="position:absolute; top:50%; left:50%; margin-top:-30px; margin-left:-30px; width:60px; z-index:10;" />
                                <a-table :columns="columns3" size = "small" :bordered=false :data-source="csTableData5" :pagination="false" :scroll="{ x: 0, y: '57vh' }" class="dashboard-table">
                                </a-table>
                            </div>
                        </a-card>
                    </a-col>
                </a-row>
                <p />
            </a-col>
        </a-row>
    </div>
</template>
<script>
import _ from "lodash";
const columns = [
    {    title: '순위'    ,   dataIndex: 'key'       ,   key: 'key'      , },
    {    title: '모델명' , dataIndex: 'model' , key: 'model' , scopedSlots: { customRender: 'model' },},
    {    title: '세부모델명' , dataIndex: 'modelDetail' , key: 'modelDetail' , scopedSlots: { customRender: 'modelDetail' },},
    {    title: '제품명' , dataIndex: 'product' , key: 'product' , scopedSlots: { customRender: 'name' },},
    {    title: '부적합 건수'    ,   dataIndex: 'count'    ,   key: 'count'    , scopedSlots: { customRender: 'count' }  },
    {    title: '진행사항',   dataIndex: 'state'  ,   key: 'state'  ,  scopedSlots: { customRender: 'control'       } },
];
const columns3 = [
    {    title: '라인'    ,   dataIndex: 'line'       ,   key: 'line'      , },
    {    title: '모델명'  ,   dataIndex: 'model'   ,   key: 'model'   , },
    {    title: '부품명',   dataIndex: 'part'     ,   key: 'part'     ,     },
    {    title: '변경 내역',   dataIndex: 'change'  ,   key: 'change'  ,  scopedSlots: { customRender: 'control'       } },
];
const columns4 = [
    // {    title: '번호'    ,   dataIndex: 'key'       ,   key: 'key'      ,  scopedSlots: { customRender: 'name'          }, width: 50 },
    // {    title: '이미지'  ,   dataIndex: 'images'   ,   key: 'images'   ,  scopedSlots: { customRender: 'avatar'        }, width: 110 },
    {    title: '라인'    ,   dataIndex: 'line'     ,   key: 'line'     ,  scopedSlots: { customRender: 'tags'          },  },
    {    title: '모델명'    ,   dataIndex: 'model'     ,   key: 'model'     ,  scopedSlots: { customRender: 'tags'          },  },
    {    title: '검사항목'    ,   dataIndex: 'inspectionName'     ,   key: 'inspectionName'     ,  scopedSlots: { customRender: 'tags'          },  },
    {    title: '부적합 내역'    ,   dataIndex: 'detail'     ,   key: 'detail'     ,  scopedSlots: { customRender: 'name'          },              },
];
const data = [
  {    key: '1',  model: 'APMS-1020A', count: 100, part: '부품', detail: '부품 파손됨', num: 3, state: 'ing'},
  {    key: '2',  model: 'CP-7210N(WT)', count: 100, part: '부품', detail: '부품 파손됨', num: 3, state: 'ing'},
  {    key: '3',  model: 'BA36-A', count: 100, part: '부품', detail: '부품 파손됨', num: 3, state: 'ing'},
  {    key: '4',  model: 'CP-7210N(WT)', count: 100, part: '부품', detail: '부품 파손됨', num: 3, state: 'ing'},
];
const data2 = [
  {    key: '1',  model: 'APMS-1020A', count: 100, part: '부품', detail: '부품 파손됨', num: 3, state: 'ing'},
  {    key: '2',  model: 'CP-7210N(WT)', count: 100, part: '부품', detail: '부품 파손됨', num: 3, state: 'ing'},
  {    key: '3',  model: 'BA36-A', count: 100, part: '부품', detail: '부품 파손됨', num: 3, state: 'ing'},
  {    key: '4',  model: 'CP-7210N(WT)', count: 100, part: '부품', detail: '부품 파손됨', num: 3, state: 'ing'},
];
const data3 = [
//   {    key: '1',  model: 'CHPI-6500L', count: 100, part: '부품', detail: '부품 파손됨', num: 3, state: 'ing'},
//   {    key: '2',  model: 'CHPI-7510L', count: 100, part: '부품', detail: '부품 파손됨', num: 3, state: 'ing'},
//   {    key: '3',  model: 'FAD-015', count: 100, part: '부품', detail: '부품 파손됨', num: 3, state: 'ing'},
//   {    key: '4',  model: 'CHP-5710L', count: 100, part: '부품', detail: '부품 파손됨', num: 3, state: 'ing'},
//   {    key: '5',  model: 'E815-A', count: 100, part: '부품', detail: '부품 파손됨', num: 3, state: 'ing'},
//   {    key: '6',  model: 'APMS', count: 100, part: '부품', detail: '부품 파손됨', num: 3, state: 'ing'},
];
const data4 = [
  {    key: '1',    total : 2,  model: '토오크'    ,  tags: ['에어','전기','기타']   ,  detail : '부품 파손됨',   images : 'https://static.turbosquid.com/Preview/001180/025/TE/conveyor-belt-3D_600.jpg'},
  {    key: '2',    total : 5,  model: '토오크'    ,  tags: ['에어']                 ,  detail : '부품 파손됨',   images : 'https://static.turbosquid.com/Preview/001180/025/TE/conveyor-belt-3D_600.jpg'},
  {    key: '3',    total : 9,  model: '토오크'    ,  tags: ['전기']                 ,  detail : '부품 파손됨',   images : 'https://static.turbosquid.com/Preview/001180/025/TE/conveyor-belt-3D_600.jpg'},
];
export default {
    name: 'Dashboard',
    components: {
    },
    data() {
        return {
            isDataLoaded : false,
            data,
            data2,
            data3,
            data4,
            columns,
            columns3,
            columns4,
            csData1 : [],
            csData2 : [],
            csTableData1 : [],  // 초/중/종품 검사 현황 월 누계
            csTableData2 : [],  // 초/중/종품 검사 현황 주별 일별 누계
            csTableData3 : [],  // 초/중/종품검사 부적합 현황
            csTableData4 : [],  // 공정순회 부적합 현황
            csTableData5 : [],  // 4M 변경 현황
            statData: {
                year: {
                    all: 0,
                    ng: 0,
                    ngRate: 0
                },
                month: {
                    water: {
                        all: 0,
                        ng: 0,
                        ngRate: 0
                    },
                    air: {
                        all: 0,
                        ng: 0,
                        ngRate: 0
                    }
                }
            },
            chartOptions1: {
                chart: {
                    height: 180
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: ''
                },
                xAxis: {
                    categories: []
                },
                yAxis: [{
                    title: {
                        enabled: false
                    },
                    min: 0,
                    tickInterval: 10,
                    labels: {
                        formatter: function() {
                            return parseInt(this.value, 10);
                        }
                    }
                }, {
                    title: {
                        enabled: false
                    },
                    opposite: true,
                    min: 0
                }],
                series: [{
                    type: 'column',
                    name: '검사수',
                    data: [],
                    color: '#7496F8',
                    yAxis: 0
                }, {
                    type: 'column',
                    name: '부적합',
                    data: [],
                    color: '#FD3995',
                    yAxis: 0
                }, {
                    type: 'line',
                    name: '부적합률',
                    data: [],
                    color: '#0099DC',
                    marker: {
                        lineWidth: 2,
                        lineColor: '#0099DC',
                        fillColor: '#ffffff'
                    },
                    yAxis: 1
                }]
            },
            chartOptions2: {
                chart: {
                    height: 180
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: ''
                },
                xAxis: {
                    categories: [],
                    min: 23,
                    max: 29,
                    scrollbar: {
                        enabled: true
                    }
                },
                yAxis: [{
                    title: {
                        enabled: false
                    },
                    min: 0,
                    tickInterval: 1
                }, {
                    title: {
                        enabled: false
                    },
                    opposite: true,
                    min: 0,
                    max: 100
                }],
                series: [{
                    type: 'column',
                    name: '검사수',
                    data: [],
                    color: '#7496F8',
                    yAxis: 0
                }, {
                    type: 'column',
                    name: '부적합',
                    data: [],
                    color: '#FD3995',
                    yAxis: 0
                }, {
                    type: 'line',
                    name: '부적합률',
                    data: [],
                    color: '#0099DC',
                    marker: {
                        lineWidth: 2,
                        lineColor: '#0099DC',
                        fillColor: '#ffffff'
                    },
                    yAxis: 1
                }]
            },
            chartOptions3: {
                chart: {
                    height: 180
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: ''
                },
                xAxis: {
                    categories: [],
                    min: 23,
                    max: 29,
                    scrollbar: {
                        enabled: true
                    }
                },
                yAxis: [{
                    title: {
                        enabled: false
                    },
                    min: 0,
                    tickInterval: 1
                }, {
                    title: {
                        enabled: false
                    },
                    opposite: true,
                    min: 0,
                    max: 100
                }],
                series: [{
                    type: 'column',
                    name: '검사수',
                    data: [],
                    color: '#7496F8',
                    yAxis: 0
                }, {
                    type: 'column',
                    name: '부적합',
                    data: [],
                    color: '#FD3995',
                    yAxis: 0
                }, {
                    type: 'line',
                    name: '부적합률',
                    data: [],
                    color: '#0099DC',
                    marker: {
                        lineWidth: 2,
                        lineColor: '#0099DC',
                        fillColor: '#ffffff'
                    },
                    yAxis: 1
                }]
            },
            chartOptions4: {
                chart: {
                    height: 180
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: ''
                },
                xAxis: {
                    categories: []
                },
                yAxis: [{
                    title: {
                        enabled: false
                    }
                }, {
                    title: {
                        enabled: false
                    },
                    opposite: true,
                    min: 0
                }],
                series: [{
                    type: 'column',
                    name: '접수',
                    data: [],
                    color: '#7496F8',
                    yAxis: 0
                }, {
                    type: 'column',
                    name: '완료',
                    data: [],
                    color: '#FD3995',
                    yAxis: 0
                }, {
                    type: 'line',
                    name: '완료율',
                    data: [],
                    color: '#0099DC',
                    marker: {
                        lineWidth: 2,
                        lineColor: '#0099DC',
                        fillColor: '#ffffff'
                    },
                    yAxis: 1
                }]
            },
            date: {
                currentYear: null,
                currentMonth: null,
                months: [],
                dates: []
            }
        };
    },
    mounted() {

        this.isDataLoaded = true;

        this.fetchProductCSList(); // 초/중/종품

        // setTimeout(() => {
        //     this.chartOptions1.series[0].data = [1,2,3,4,5]
        // }, 10000);

        let dateObj = new Date();
        this.date.currentYear = dateObj.getFullYear();
        this.date.currentMonth = dateObj.getMonth() + 1;
        for (let i = 1; i <= this.date.currentMonth; i++) {
            let month = i;
            if (month < 10) {
                month = '0' + i;
            }
            this.date.months.push(month);
        }
        if (this.date.currentMonth < 10) {
            this.date.currentMonth = '0' + this.date.currentMonth;
        }
        
        // 최근 30일
        let lastDate = this.getDateStr(dateObj);
        for (let i = 0; i < 30; i++) {
            let timestamp = (new Date(lastDate)).getTime() - i * 86400 * 1000;
            this.date.dates.push(this.getDateStr(new Date(timestamp)));
        }
        this.date.dates = this.date.dates.reverse();
    },
    watch: {
        csData1: function(newOne){
            this.csData1 = newOne;
            this.updateChart1();
            this.updateChart2();
            this.updateChart3();
        }
    },
    methods : {
        fetchProductCSList() {
            this.csData1 = [];
            let urlToFetchProductCSList = "https://cowaylqc.iotinfras.com:8100/product/cs/list_simple?size=10000&page=0";
            this.$http.get(urlToFetchProductCSList)
            .then((response) => {
                if(response.status === 200 && response.data.code === 'success') {
                    this.csData1 = _.filter(response.data.data.content, (item) => {
                        // return (item.state === 'C' || item.state === 'D') && item.updated.substr(0, 4) == this.date.currentYear;   // 올해 데이터
                        return (item.state === 'D' || item.state === 'J') && item.updated.substr(0, 4) == this.date.currentYear;   // 올해 데이터
                    });
                    this.csData1 = _.orderBy(this.csData1, ['updated'], ['asc']);  // 수정 날짜순 정렬

                    this.csData2 = _.filter(response.data.data.content, (item) => {
                        // return (item.state === 'G' || item.state === 'H') && item.updated.substr(0, 4) == this.date.currentYear;   // 올해 데이터
                        return (item.state === 'H' || item.state === 'L') && item.updated.substr(0, 4) == this.date.currentYear;   // 올해 데이터
                    });
                    this.csData2 = _.orderBy(this.csData2, ['updated'], ['asc']);  // 수정 날짜순 정렬
                }

                this.updateChart1();    // 초/중/종품 검사 현황
                this.updateChart2();    // 정수기 검사 현황 주별
                this.updateChart3();    // 청정기 검사 현황 주별
                this.updateChart4();    // 공정순회 부적합 현황

                this.flagSpinner = false;

                // 좌측 누적 통계
                this.statData.year.all = this.csData1.length;
                this.statData.year.ng = 0;
                if (this.statData.year.all) {
                    _.forEach(this.csData1, (item) => {
                        if (item.csTime.match('NG')) {
                            this.statData.year.ng++;
                        }
                    });
                    this.statData.year.ngRate = +((this.statData.year.ng / this.statData.year.all * 100).toFixed(2));
                } else {
                    this.statData.year.ngRate = 0;
                }

                // 좌측 월별 통계
                let arr11 = _.filter(this.csData1, (item) => {
                    return (item.updated.substr(0, 7) === this.date.currentYear + '-' + this.date.currentMonth) && item.clsProduct.listProductType[0].productTypeName === 'WATER';
                });
                let arr12 = _.filter(this.csData1, (item) => {
                    return (item.updated.substr(0, 7) === this.date.currentYear + '-' + this.date.currentMonth) && item.clsProduct.listProductType[0].productTypeName === 'AIR';
                });

                // (1) 정수기
                this.statData.month.water.all = arr11.length;
                this.statData.month.water.ng = 0;
                if (this.statData.month.water.all) {
                    _.forEach(arr11, (item) => {
                        if (item.csTime.match('NG')) {
                            this.statData.month.water.ng++;
                        }
                    });
                    this.statData.month.water.ngRate = +((this.statData.month.water.ng / this.statData.month.water.all * 100).toFixed(2));
                } else {
                    this.statData.month.water.ngRate = 0;
                }

                // (2) 청정기
                this.statData.month.air.all = arr12.length;
                this.statData.month.air.ng = 0;
                if (this.statData.month.air.all) {
                    _.forEach(arr12, (item) => {
                        if (item.csTime.match('NG')) {
                            this.statData.month.air.ng++;
                        }
                    });
                    this.statData.month.air.ngRate = +((this.statData.month.air.ng / this.statData.month.air.all * 100).toFixed(2));
                } else {
                    this.statData.month.air.ngRate = 0;
                }

                // 초/중/종품 검사 현황 월 누계
                let arr1 = _.filter(this.csData1, (item) => {
                    return item.updated.substr(0, 7) === this.date.currentYear + '-' + this.date.currentMonth;
                });
                _.forEach(arr1, (item, i) => {
                    let count = 0;
                    _.forEach(item.listProductCSInpection, (item2) => {
                        _.forEach(item2.listProductCSInpectionStandard, (item3) => {
                            if (item3.min === 'NG') {
                                count++;
                            }
                        });
                    });

                    this.csTableData1.push({
                        key: i + 1,
                        name : item.name,
                        model : item.clsProduct.productModel,
                        modelDetail : item.clsProduct.productModelDetail,
                        product : item.clsProduct.productName,
                        state: item.state,
                        count: count
                    });
                });

                // 초/중/종품 검사 현황 주별 일별 누계
                // 이번주 월요일 날짜 구하기 - https://stackoverflow.com/questions/5210376/how-to-get-first-and-last-day-of-the-current-week-in-javascript
                var curr = new Date; // get current date
                var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
                var firstday = new Date(curr.setDate(first));   // 일요일
                firstday = new Date(firstday.getTime() + 86400 * 1000); // 월요일
                firstday = this.getDateStr(firstday);
                
                let arr5 = _.filter(this.csData1, (item) => {
                    return (new Date(item.updated)).getTime() >= (new Date(firstday).getTime());
                });
                _.forEach(arr5, (item, i) => {
                    let count = 0;
                    _.forEach(item.listProductCSInpection, (item2) => {
                        _.forEach(item2.listProductCSInpectionStandard, (item3) => {
                            if (item3.min === 'NG') {
                                count++;
                            }
                        });
                    });

                    this.csTableData2.push({
                        key: i + 1,
                        name : item.name,
                        model : item.clsProduct.productModel,
                        modelDetail : item.clsProduct.productModelDetail,
                        product : item.clsProduct.productName,
                        state: item.state,
                        count: count
                    });
                });
                
                // 초/중/종품검사 부적합 현황
                let count1 = 0;
                _.forEach(this.csData1, (item) => {
                    item.ng = false;
                    _.forEach(item.listProductCSInpection, (item2) => {
                        _.forEach(item2.listProductCSInpectionStandard, (item3) => {
                            if (item3.min === 'NG') {
                                count1++;
                                item.ng = true;

                                // 초/중/종품검사 부적합 현황 테이블 데이터 생성
                                this.csTableData3.push({
                                    key: count1,
                                    images: this.$store.state.hostInService +'/upload/image/download/' + item2.inspectionImageSeq2,
                                    detail: item3.ngTitle,
                                    model: item.clsProduct.productModel,
                                    line: item.line,
                                    inspectionName: item2.inspectionName
                                });
                            }
                        });
                    });
                });
                this.csTableData3 = (this.csTableData3.reverse()).slice(0, 4);

                // 공정순회 부적합 현황
                let count2 = 0;
                _.forEach(this.csData2, (item) => {
                    item.ng = false;
                    _.forEach(item.listProductCSInpection, (item2) => {
                        _.forEach(item2.listProductCSInpectionStandard, (item3) => {
                            let temp = item3.min.split('|');
                            let temp2 = item3.result.split('|');
                            _.forEach(temp, (checkState, i) => {
                                if (checkState === 'NG') {
                                    count2++;
                                    item.ng = true;

                                    // 공정순회 부적합 현황 테이블 데이터 생성
                                    this.csTableData4.push({
                                        key: count2,
                                        images: this.$store.state.hostInService +'/upload/image/download/' + item2.inspectionImageSeq2,
                                        detail: temp2[i],
                                        model: item.clsProduct.productModel,
                                        line: item.line,
                                        inspectionName: item2.inspectionName
                                    });
                                }
                            });
                        });
                    });
                });
                this.csTableData4 = (this.csTableData4.reverse()).slice(0, 4);

                // 4M 변경 현황
                _.forEach(this.csData2, (item, i) => {
                    item.ng = false;
                    let obj = {};
                    _.forEach(item.listProductCSInpection, (item2) => {
                        if (item2.inspectionTarget === '4M') {
                            let temp = item2.listProductCSInpectionStandard[0].min.split('|');
                            obj.part = temp[0];

                            let temp2 = item2.listProductCSInpectionStandard[0].result.split('|');
                            obj.change = temp2[0];
                        }
                    });
                    // 4M 변경 현황 테이블 데이터 생성
                    if (obj.part) {
                        obj.key = i;
                        obj.model = item.clsProduct.productModel;
                        obj.line = item.line;
                        this.csTableData5.push(obj);
                    }
                });
                this.csTableData5 = (this.csTableData5.reverse()).slice(0, 4);

                this.fetchProductCSList2();
            }).catch(error => {
                console.log(error);
                this.flagSpinner = false;
            });
        },
        fetchProductCSList2() {
            let urlToFetchProductCSList = "https://cowaylqc.iotinfras.com:8100/product/cs/list_month?size=10000&page=0";
            this.$http.get(urlToFetchProductCSList)
            .then((response) => {
                let csData1 = [];
                let csData2 = [];
                let csTableData1 = [];
                let csTableData2 = [];
                this.csTableData3 = [];
                this.csTableData4 = [];
                this.csTableData5 = [];

                let statData = {
                    year: {
                        all: 0,
                        ng: 0,
                        ngRate: 0
                    },
                    month: {
                        water: {
                            all: 0,
                            ng: 0,
                            ngRate: 0
                        },
                        air: {
                            all: 0,
                            ng: 0,
                            ngRate: 0
                        }
                    }
                };

                document.getElementById('loading-finished').style.display = 'none';
                document.getElementById('loading-patrol').style.display = 'none';
                document.getElementById('loading-4m').style.display = 'none';

                if(response.status === 200 && response.data.code === 'success') {
                    csData1 = _.filter(response.data.data.content, (item) => {
                        // return (item.state === 'C' || item.state === 'D') && item.updated.substr(0, 4) == this.date.currentYear;   // 올해 데이터
                        return (item.state === 'D' || item.state === 'J') && item.updated.substr(0, 4) == this.date.currentYear;   // 올해 데이터
                    });
                    csData1 = _.orderBy(csData1, ['updated'], ['asc']);  // 수정 날짜순 정렬

                    csData2 = _.filter(response.data.data.content, (item) => {
                        // return (item.state === 'G' || item.state === 'H') && item.updated.substr(0, 4) == this.date.currentYear;   // 올해 데이터
                        return (item.state === 'H' || item.state === 'L') && item.updated.substr(0, 4) == this.date.currentYear;   // 올해 데이터
                    });
                    csData2 = _.orderBy(csData2, ['updated'], ['asc']);  // 수정 날짜순 정렬
                }

                // 좌측 누적 통계
                statData.year.all = csData1.length;
                statData.year.ng = 0;
                if (statData.year.all) {
                    _.forEach(csData1, (item) => {
                        if (item.csTime.match('NG')) {
                            statData.year.ng++;
                        }
                    });
                    statData.year.ngRate = +((statData.year.ng / statData.year.all * 100).toFixed(2));
                } else {
                    statData.year.ngRate = 0;
                }

                // 좌측 월별 통계
                let arr11 = _.filter(csData1, (item) => {
                    return (item.updated.substr(0, 7) === this.date.currentYear + '-' + this.date.currentMonth) && item.clsProduct.listProductType[0].productTypeName === 'WATER';
                });
                let arr12 = _.filter(csData1, (item) => {
                    return (item.updated.substr(0, 7) === this.date.currentYear + '-' + this.date.currentMonth) && item.clsProduct.listProductType[0].productTypeName === 'AIR';
                });

                // (1) 정수기
                statData.month.water.all = arr11.length;
                statData.month.water.ng = 0;
                if (statData.month.water.all) {
                    _.forEach(arr11, (item) => {
                        if (item.csTime.match('NG')) {
                            statData.month.water.ng++;
                        }
                    });
                    statData.month.water.ngRate = +((statData.month.water.ng / statData.month.water.all * 100).toFixed(2));
                } else {
                    statData.month.water.ngRate = 0;
                }

                // (2) 청정기
                statData.month.air.all = arr12.length;
                statData.month.air.ng = 0;
                if (statData.month.air.all) {
                    _.forEach(arr12, (item) => {
                        if (item.csTime.match('NG')) {
                            statData.month.air.ng++;
                        }
                    });
                    statData.month.air.ngRate = +((statData.month.air.ng / statData.month.air.all * 100).toFixed(2));
                } else {
                    statData.month.air.ngRate = 0;
                }

                // 초/중/종품 검사 현황 월 누계
                let arr1 = _.filter(csData1, (item) => {
                    return item.updated.substr(0, 7) === this.date.currentYear + '-' + this.date.currentMonth;
                });
                _.forEach(arr1, (item, i) => {
                    let count = 0;
                    _.forEach(item.listProductCSInpection, (item2) => {
                        _.forEach(item2.listProductCSInpectionStandard, (item3) => {
                            if (item3.min === 'NG') {
                                count++;
                            }
                        });
                    });

                    csTableData1.push({
                        key: i + 1,
                        name : item.name,
                        model : item.clsProduct.productModel,
                        modelDetail : item.clsProduct.productModelDetail,
                        product : item.clsProduct.productName,
                        state: item.state,
                        count: count
                    });
                });

                // 초/중/종품 검사 현황 주별 일별 누계
                // 이번주 월요일 날짜 구하기 - https://stackoverflow.com/questions/5210376/how-to-get-first-and-last-day-of-the-current-week-in-javascript
                var curr = new Date; // get current date
                var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
                var firstday = new Date(curr.setDate(first));   // 일요일
                firstday = new Date(firstday.getTime() + 86400 * 1000); // 월요일
                firstday = this.getDateStr(firstday);
                
                let arr5 = _.filter(csData1, (item) => {
                    return (new Date(item.updated)).getTime() >= (new Date(firstday).getTime());
                });
                _.forEach(arr5, (item, i) => {
                    let count = 0;
                    _.forEach(item.listProductCSInpection, (item2) => {
                        _.forEach(item2.listProductCSInpectionStandard, (item3) => {
                            if (item3.min === 'NG') {
                                count++;
                            }
                        });
                    });

                    csTableData2.push({
                        key: i + 1,
                        name : item.name,
                        model : item.clsProduct.productModel,
                        modelDetail : item.clsProduct.productModelDetail,
                        product : item.clsProduct.productName,
                        state: item.state,
                        count: count
                    });
                });
                
                // 초/중/종품검사 부적합 현황
                let count1 = 0;
                _.forEach(csData1, (item) => {
                    item.ng = false;
                    _.forEach(item.listProductCSInpection, (item2) => {
                        _.forEach(item2.listProductCSInpectionStandard, (item3) => {
                            if (item3.min === 'NG') {
                                count1++;
                                item.ng = true;

                                // 초/중/종품검사 부적합 현황 테이블 데이터 생성
                                this.csTableData3.push({
                                    key: count1,
                                    images: this.$store.state.hostInService +'/upload/image/download/' + item2.inspectionImageSeq2,
                                    detail: item3.ngTitle,
                                    model: item.clsProduct.productModel,
                                    line: item.line,
                                    inspectionName: item2.inspectionName
                                });
                            }
                        });
                    });
                });
                this.csTableData3 = (this.csTableData3.reverse()).slice(0, 4);

                // 공정순회 부적합 현황
                let count2 = 0;
                _.forEach(csData2, (item) => {
                    item.ng = false;
                    _.forEach(item.listProductCSInpection, (item2) => {
                        _.forEach(item2.listProductCSInpectionStandard, (item3) => {
                            let temp = item3.min.split('|');
                            let temp2 = item3.result.split('|');
                            _.forEach(temp, (checkState, i) => {
                                if (checkState === 'NG') {
                                    count2++;
                                    item.ng = true;

                                    // 공정순회 부적합 현황 테이블 데이터 생성
                                    this.csTableData4.push({
                                        key: count2,
                                        images: this.$store.state.hostInService +'/upload/image/download/' + item2.inspectionImageSeq2,
                                        detail: temp2[i],
                                        model: item.clsProduct.productModel,
                                        line: item.line,
                                        inspectionName: item2.inspectionName
                                    });
                                }
                            });
                        });
                    });
                });
                this.csTableData4 = (this.csTableData4.reverse()).slice(0, 4);

                // 4M 변경 현황
                _.forEach(csData2, (item, i) => {
                    item.ng = false;
                    let obj = {};
                    _.forEach(item.listProductCSInpection, (item2) => {
                        if (item2.inspectionTarget === '4M') {
                            let temp = item2.listProductCSInpectionStandard[0].min.split('|');
                            obj.part = temp[0];

                            let temp2 = item2.listProductCSInpectionStandard[0].result.split('|');
                            obj.change = temp2[0];
                        }
                    });
                    // 4M 변경 현황 테이블 데이터 생성
                    if (obj.part) {
                        obj.key = i;
                        obj.model = item.clsProduct.productModel;
                        obj.line = item.line;
                        this.csTableData5.push(obj);
                    }
                });
                this.csTableData5 = (this.csTableData5.reverse()).slice(0, 4);
            }).catch(error => {
                console.log(error);
            });
        },
        getDateStr(dateObj) {
            let year = dateObj.getFullYear();
            let month = dateObj.getMonth() + 1;
            if (month < 10) {
                month = '0' + month;
            }
            let date = dateObj.getDate();
            if (date < 10) {
                date = '0' + date;
            }

            return year + '-' + month + '-' + date;
        },
        updateChart1() {
            let completeArr = _.cloneDeep(this.csData1);
            
            let categories = [];
            _.forEach(this.date.months, (item) => {
                categories.push(this.date.currentYear + '-' + item);
            });
            
            this.chartOptions1.xAxis.categories = categories;
            
            let data1 = [];
            let data2 = [];
            let data3 = [];

            _.forEach(categories, (item) => {
                let temp = _.filter(completeArr, (item2) => {
                    return item2.updated.substr(0, 7) === item;
                });
                data1.push(temp.length);
            });

            _.forEach(categories, (item4) => {
                let count = 0;
                _.forEach(completeArr, (item) => {
                    if (item.csTime.match('NG') && (item4 === item.updated.substr(0, 7))) {
                        count++;
                    }
                });
                data2.push(count);
            });

            _.forEach(data1, (item, i) => {
                let temp = +((data2[i] / data1[i] * 100).toFixed(2));
                data3.push(temp);
            });
            
            this.chartOptions1.series[0].data = data1;
            this.chartOptions1.series[1].data = data2;
            this.chartOptions1.series[2].data = data3;
        },
        updateChart2() {
            let completeArr = _.filter(this.csData1, function(item) {
                return (item.state === 'D' || item.state === 'J') && item.clsProduct.listProductType[0].productTypeName === 'WATER';
            });
            
            let categories = _.cloneDeep(this.date.dates);
            this.chartOptions2.xAxis.categories = categories;
            
            let data1 = [];
            let data2 = [];
            let data3 = [];

            _.forEach(categories, (item) => {
                let temp = _.filter(completeArr, (item2) => {
                    return item2.updated.substr(0, 10) === item;
                });
                data1.push(temp.length);
            });

            _.forEach(categories, (item4) => {
                let count = 0;
                _.forEach(completeArr, (item) => {
                    if (item.csTime.match('NG') && (item4 === item.updated.substr(0, 10))) {
                        count++;
                    }
                });
                data2.push(count);
            });

            _.forEach(data1, (item, i) => {
                let temp = +((data2[i] / data1[i] * 100).toFixed(2));
                data3.push(temp);
            });
            
            this.chartOptions2.series[0].data = data1;
            this.chartOptions2.series[1].data = data2;
            this.chartOptions2.series[2].data = data3;
        },
        updateChart3() {
            let completeArr = _.filter(this.csData1, function(item) {
                return (item.state === 'D' || item.state === 'J') && item.clsProduct.listProductType[0].productTypeName === 'AIR';
            });
            
            let categories = _.cloneDeep(this.date.dates);
            this.chartOptions3.xAxis.categories = categories;
            
            let data1 = [];
            let data2 = [];
            let data3 = [];

            _.forEach(categories, (item) => {
                let temp = _.filter(completeArr, (item2) => {
                    return item2.updated.substr(0, 10) === item;
                });
                data1.push(temp.length);
            });

            _.forEach(categories, (item4) => {
                let count = 0;
                _.forEach(completeArr, (item) => {
                    if (item.csTime.match('NG') && (item4 === item.updated.substr(0, 7))) {
                        count++;
                    }
                });
                data2.push(count);
            });

            _.forEach(data1, (item, i) => {
                let temp = +((data2[i] / data1[i] * 100).toFixed(2));
                data3.push(temp);
            });
            
            this.chartOptions3.series[0].data = data1;
            this.chartOptions3.series[1].data = data2;
            this.chartOptions3.series[2].data = data3;
        },
        updateChart4() {
            let completeArr = _.filter(this.csData2, {
                state: 'H'
            });
            
            let categories = [];
            _.forEach(this.date.months, (item) => {
                categories.push(this.date.currentYear + '-' + item);
            });
            
            this.chartOptions4.xAxis.categories = categories;
            
            let data1 = [];
            let data2 = [];
            let data3 = [];

            _.forEach(categories, (item) => {
                let temp = _.filter(this.csData2, (item2) => {
                    return item2.updated.substr(0, 7) === item;
                });
                data1.push(temp.length);
            });

            _.forEach(categories, (item) => {
                let temp = _.filter(completeArr, (item2) => {
                    return item2.updated.substr(0, 7) === item;
                });
                data2.push(temp.length);
            });

            _.forEach(data1, (item, i) => {
                let temp = +((data2[i] / data1[i] * 100).toFixed(2));
                data3.push(temp);
            });
            
            this.chartOptions4.series[0].data = data1;
            this.chartOptions4.series[1].data = data2;
            this.chartOptions4.series[2].data = data3;
        },
        viewDetail(page) {
            switch (page) {
                case 'finished':
                    this.$router.push('/finishedReport');
                    break;

                case 'finished1':
                    this.$router.push('/dashboardDetailFinished1');
                    break;

                case 'finished2':
                    this.$router.push('/dashboardDetailFinished2');
                    break;

                case 'finishedNg':
                    this.$router.push('/dashboardDetailFinishedNg');
                    break;

                case 'patrolNg':
                    this.$router.push('/dashboardDetailPatrolNg');
                    break;

                case 'patrol4m':
                    this.$router.push('/dashboardDetailPatrol4m');
                    break;
            }

            // 페이지 이동 후 /#/ 으로 다시 이동하는 현상이 있어서 아래와 같이 처리
            setTimeout(() => {
                history.go(-1);
            }, 0);
        }
    }
}
</script>
<style scoped>
    .left-stat-container {
        background-color: #fff;
        border: 1px solid #ccc;
    }

    .left-stat-container .section {
        padding: 20px;
        border-top: 1px solid #ccc;
        box-sizing: border-box;
        height: 287px;
    }

    .left-stat-container .section:first-child {
        border-top: none;
    }

    .left-stat-container .section .section-title {
        padding-left: 7px;
        height: 18px;
        font-size: 14px;
        font-weight: bold;
        color: #0099DC;
        border-left: 2px solid #0099DC;
    }

    .left-stat-container .section ul {
        margin: 30px 0 0 0;
        padding: 0;
        width: 100%;
        list-style: none;
    }

    .left-stat-container .section li {
        position: relative;
        margin-bottom: 20px;
        width: 100%;
        height: 40px;
    }

    .left-stat-container .section li .label {
        position: absolute;
        top: 0;
        left: 0;
        color: #50649C;
        font-size: 16px;
    }

    .left-stat-container .section li .value {
        position: absolute;
        bottom: 0;
        right: 0;
        color: #2C3A65;
        font-weight: bold;
        font-size: 20px;
    }

    .dashboard-container .more-btn {
        width: 70px;
    }

    .ant-card.ant-card-bordered.bold-border {
        border: 1px solid #ccc;
    }

    .color-border.red {
        border-color: #fd8fc3;
    }

    .color-border.green {
        border-color: #52c41a;
    }

    .color-border.blue {
        border-color: #7496f8;
    }
</style>