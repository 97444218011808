/****************************************************************************************
 * File Name    : EquipmentControlForm.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
    <div class = ''>
        <!-- v-bind="formItemLayout" -->
        <a-card v-if = "1===2">
            <Equipment name = "진공펌프" type = "operator" :description = "['정수기전용']" />
        </a-card>
        <p/>
        <a-form class="" :form="form" layout = "vertical" >
            <!-- <a-card title = "구성장치"> -->
                <a-row :gutter="16" type="flex" justify="space-around" align="top">
                    <!-- <a-col class="gutter-row" :span="3">
                        {{title}}
                    </a-col> -->
                    <a-col class="gutter-row" :span="24">
                        <!--  -->
                        <a-form-item label="점검항목">
                            <a-input placeholder="점검항목" v-decorator="['Name',{rules: [{ required: true, message: '점검항목 입력하세요' }],},] "/>
                        </a-form-item>
                        <a-form-item label="점검기준">
                            <a-space align="baseline" style = 'margin-bottom:10px'>
                                <a-input placeholder="충전기 에어압력" v-decorator="['Name',{rules: [{ required: true, message: '입력하세요' }],},] "/>
                                <a-divider type = 'vertical' />
                                <a-input placeholder="5" style="width: 50px"/>
                                ~
                                <a-input placeholder="7" style="width: 50px"/>
                                <a-select default-value="Bar" >
                                    <a-select-option value="Bar">
                                        Bar
                                    </a-select-option>
                                </a-select>
                                <a-input placeholder="입력" />
                                <a-button type="primary" icon="plus" size = "small" ghost=true />
                                <a-button type="danger" icon="minus" size = "small" ghost=true />
                            </a-space>
                            <a-space align="baseline">
                                <a-input placeholder="충전기 에어압력" v-decorator="['Name',{rules: [{ required: true, message: '입력하세요' }],},] "/>
                                <a-divider type = 'vertical' />
                                <a-input placeholder="5" style="width: 50px"/>
                                ~
                                <a-input placeholder="7" style="width: 50px"/>
                                <a-select default-value="Bar" >
                                    <a-select-option value="Bar">
                                        Bar
                                    </a-select-option>
                                </a-select>
                                <a-input placeholder="입력" />
                                <a-button type="primary" icon="plus" size = "small" ghost =true />
                                <a-button type="danger" icon="minus" size = "small" ghost =true />
                            </a-space>
                        </a-form-item>
                        <!--  -->
                    </a-col>
                </a-row>
                <!-- <a-row :gutter="16" type="flex" justify="space-around" align="top">
                    <a-col class="gutter-row" :span="3">
                         제어부
                    </a-col>
                    <a-col class="gutter-row" :span="21">
                        <a-form-item label="점검항목">
                            <a-input placeholder="점검항목" v-decorator="['Name',{rules: [{ required: true, message: '점검항목 입력하세요' }],},] "/>
                        </a-form-item>
                        <a-form-item label="점검기준">
                            <a-space align="baseline">
                                <a-input placeholder="충전기 에어압력" v-decorator="['Name',{rules: [{ required: true, message: '입력하세요' }],},] "/>
                                <a-divider type = 'vertical' />
                                <a-input placeholder="5" style="width: 50px"/>
                                ~
                                <a-input placeholder="7" style="width: 50px"/>
                                <a-select default-value="OK/NG" >
                                    <a-select-option value="Bar">
                                        OK
                                    </a-select-option>
                                </a-select>
                                <a-divider type = 'vertical' />
                                <a-icon type="plus-square" />
                                <a-divider type = 'vertical' />
                                <a-icon type="minus-square" />
                            </a-space>
                        </a-form-item>
                    </a-col>
                </a-row> -->
            <!-- </a-card> -->
            <!-- <p/>
            <a-button type="secondary" html-type="submit" :ghost = false size = "small" icon = "plus-circle">Save</a-button>
            <a-divider type = 'vertical' />
            <a-button type="secondary" html-type="button" :ghost = false  size = "small" icon = "plus-square">Add more</a-button>
            <a-divider type = 'vertical' />
            <a-button type="secondary" html-type="reset" :ghost = false  size = "small" icon = "reload">Reset</a-button> -->
        </a-form>

    </div>
</template>
<script>
import Equipment from "../components/Equipment.vue" ;
export default {
    name: 'EquipmentControlForm',
    components: {
        Equipment,
    },
    props : ['title'],
    data() {
        return {
            formItemLayout: {
                labelCol: { span: 3 },
                wrapperCol: { span: 21 },
            },
        };
    },
    beforeCreate() {
        this.form = this.$form.createForm(this, { name: 'validate_other' });
    },
}
</script>
<style scoped>
    .ant-form-item  {
        margin-bottom: 5px !important;
    }
    .ant-form-item-label  {
        font-weight: 700  !important;
    }
</style>