/****************************************************************************************
 * File Name    : Patrol.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
    <div class="">
        <div v-if = "flagSpinner === false">
            <a-row :gutter="16">
                <a-col class="gutter-row" :span="24">
                    <div class="gutter-box">
                        <PageHeader title = "공정 순회검사 체크시트 등록" />
                    </div>
                </a-col>
            </a-row>
            <a-divider type = 'horizontal' />
            <a-space align="end" style="padding-top:10px;">
                <a-radio-group  v-model="filterState">
                    <a-radio-button @click="changeFilterState('등록중')" value="등록중">등록중만 보기</a-radio-button>
                    <a-radio-button @click="changeFilterState('등록완료')" value="등록완료">등록완료만 보기</a-radio-button>
                    <a-radio-button @click="changeFilterState('모두')" value="모두">모두보기</a-radio-button>
                </a-radio-group>
                <a-input-search style = '' placeholder="검색할 이름을 입력하세요"  v-model="filterCS" v-on:keyup="filterCSList" />
                <a-divider type = 'vertical' />
                <div class="border:1px solid red;width:100%">
                    <router-link to="/patrolProduct">
                        <a-button type="primary" icon="plus-circle" size = "default" >
                            공정순회 검사 체크시트 생성
                        </a-button>
                    </router-link>
                </div>
            </a-space>
            <p/>
            <a-progress type = 'line' :percent="validRate" strokeColor = '#C7C6C6'/>
            <p/>
            <div class = 'todo'>검색필터 등 추가. 현재는 '완료'상태만 표시됨. pagination 추가</div>
            <a-table :columns="columns" size = "small" :bordered=false :data-source="data" :pagination="true" :scroll="{ x: 0, y: '57vh' }">
                <span slot="tags" slot-scope="tag">
                    <a-tag class="state-btn" :class="{ red: (tag === '등록중'), green: (tag === '등록완료') }">
                        {{ tag.toUpperCase() }}
                    </a-tag>
                </span>
                <span slot="name" slot-scope="text">{{ text }}</span>
                <span slot="action" slot-scope="record">
                    <span style = 'cursor:pointer' @click="handlerToCreateProductCS(record.key)"><a-icon type="save" /> Edit</span>
                    <!-- <span style = 'cursor:pointer'><a-icon type="edit" /> Edit</span> -->
                    <a-divider type="vertical" />
                    <span style = 'cursor:pointer' @click="handlerToDeleteProductCS(record.key)"><a-icon type="delete" /> Delete</span>
                    <a-divider type="vertical" />
                    <span style = 'cursor:pointer' @click="handlerToViewProductCS(record.key)"><a-icon type="eye" /> view</span>
                </span>
            </a-table>
        </div>
        <a-skeleton v-else />
        <a-drawer title="" placement="right" :closable="false" :visible="flagForDrawer" @close="handlerForCloseDrawer" width="50vw" >
            <PatrolViewer :target = "dataArray[dataOffset]" v-on:flagForDrawer="handlerForCloseDrawer" />
        </a-drawer>
    </div>
</template>
<script>
// state 값
// 초/중/종품 등록중 - A
// 초/중/종품 등록완료 - B
// 초/중/종품 검사중 - C
// 초/중/종품 검사완료 - D
// 공정순회 등록중 - E
// 공정순회 등록완료 - F
// 공정순회 검사중 - G
// 공정순회 검사완료 - H

const columns = [
    {    title: '상태' , dataIndex: 'tag' , key: 'tag' , scopedSlots: { customRender: 'tags' }, width : 100},
    {    title: '모델명' , dataIndex: 'model' , key: 'model' , scopedSlots: { customRender: 'model' },},
    {    title: '세부모델명' , dataIndex: 'modelDetail' , key: 'modelDetail' , scopedSlots: { customRender: 'modelDetail' },},
    {    title: '제품명' , dataIndex: 'product' , key: 'product' , scopedSlots: { customRender: 'name' },},
    {    title: '최종수정일' , dataIndex: 'updated' , key: 'updated' , scopedSlots: { customRender: 'name' },},
    {    title: '관리' , key: 'action' , scopedSlots: { customRender: 'action' }, width : 250},
];
const data = [];
import _ from "lodash";
import PageHeader from "../components/PageHeader.vue" ;
import PatrolViewer from "./PatrolViewer.vue" ;
export default {
    name: 'Patrol',
    components: {
        PageHeader,
        PatrolViewer,
    },
    data() {
        return {
            flagSpinner : false,
            data,
            dataBackup: [],
            columns,
            dataArray : [],
            total : 0,
            valid : 0,
            suspended : 0,
            validRate : 0,
            suspendedRate : 0,
            flagForDrawer: false,
            dataOffset : 0,
            filterState : '모두',
            filterCS : ''
        };
    },
    mounted() {
        this.loadProducCSStattistics();
    },
    methods : {
        loadProducCSStattistics() {
            this.flagSpinner = true;
            let urlToFetchProductCSStat = this.$store.state.hostInService + "/product/cs/statistics";
            this.$http.get(urlToFetchProductCSStat)
            .then((response) => {
                if(response.status === 200 && response.data.code === 'success') {
                    let dataObject = response.data.data;
                    this.valid = dataObject.valid;
                    this.suspended = dataObject.suspended;
                    this.total = this.valid + this.suspended;
                    this.validRate = Number((this.valid/this.total)*100).toFixed(0)*1;
                    this.validSuspended = Number((this.valid/this.total)*100).toFixed(0)*1;
                    // DESC : 리스트를 조회한다.
                    this.fetchProductCSList();
                }
            }).catch(error => {
                console.log(error);
                this.flagSpinner = false;
            });
        },
        fetchProductCSList() {
            this.dataArray = [];
            this.data = [];
            let urlToFetchProductCSList = "https://cowaylqc.iotinfras.com:8100/product/cs/list_simple?size=10000&page=0";
            this.$http.get(urlToFetchProductCSList)
            .then((response) => {
                if(response.status === 200 && response.data.code === 'success') {
                    this.dataArray = response.data.data.content;
                    this.dataArray = _.filter(response.data.data.content, (item) => {
                        return item.state === 'E' || item.state === 'F';
                    });
                    // this.dataArray = this.dataArray.reverse();
                }
                for(let x = 0; x < this.dataArray.length ; x ++) {
                    let tag;
                    if (this.dataArray[x].state === 'E') {
                        tag = '등록중';
                    } else if (this.dataArray[x].state === 'F') {
                        tag = '등록완료';
                    }

                    // TO DO - 서버에서 수정한 후 이 코드 삭제 - updated 가 9 시간이 작게 나와서 프론트엔드에서 수정
                    let updatedObj = new Date((new Date(this.dataArray[x].updated)).getTime() + 0 * 60 * 60 * 1000);
                    let year = updatedObj.getFullYear();
                    let month = updatedObj.getMonth() + 1;
                    if (month < 10) {
                        month = '0' + month;
                    }
                    let date = updatedObj.getDate();
                    if (date < 10) {
                        date = '0' + date;
                    }
                    let hour = updatedObj.getHours();
                    if (hour < 10) {
                        hour = '0' + hour;
                    }
                    let minute = updatedObj.getMinutes();
                    if (minute < 10) {
                        minute = '0' + minute;
                    }
                    let second = updatedObj.getSeconds();
                    if (second < 10) {
                        second = '0' + second;
                    }

                    this.data.push({
                        key : x,
                        name : this.dataArray[x].name,
                        tag : tag,
                        // updated : this.dataArray[x].updated,
                        updated : year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second,
                        model : this.dataArray[x].clsProduct.productModel,
                        modelDetail : this.dataArray[x].clsProduct.productModelDetail,
                        product : this.dataArray[x].clsProduct.productName,
                    });
                }
                this.flagSpinner = false;

                this.dataBackup = JSON.parse(JSON.stringify(this.data));
            }).catch(error => {
                console.log(error);
                this.flagSpinner = false;
            });
        },
        handlerToViewProductCS(dataOffset) {
            this.dataOffset = dataOffset;
            let target = this.dataArray[dataOffset];

            let urlToFetchProductCSList = "https://cowaylqc.iotinfras.com:8100/product/cs/get_patrol_cs_item?seq=" + target.productCsSeq;
                this.$http.get(urlToFetchProductCSList)
                .then((response) => {
                    if(response.status === 200 && response.data.code === 'success') {
                        let item = response.data.data.content[0];
                        this.dataArray[dataOffset] = item;
                        this.flagForDrawer = true;         
                    }
                });
        },
        handlerToCreateProductCS(dataOffset) {
            let target = this.dataArray[dataOffset];

            let urlToFetchProductCSList = "https://cowaylqc.iotinfras.com:8100/product/cs/get_patrol_cs_item?seq=" + target.productCsSeq;
                this.$http.get(urlToFetchProductCSList)
                .then((response) => {
                    if(response.status === 200 && response.data.code === 'success') {
                        let item = response.data.data.content[0];
                        this.$router.push({ name: 'PatrolProduct', params: { editCS: item } });            
                    }
                });
        },
        handlerForCloseDrawer() {
            this.flagForDrawer = false;
        },
        handlerToSaveCSForProudct(listItem) {

            console.log(listItem)

            let generatedObjectToPersist = {
                productSeq: listItem.clsProduct.productSeq,
                productCSName: 'PATROLCS-' + listItem.name,
                productCSInspections: [],
                productCSTimes: []
            };

            _.forEach(listItem.listProductCSInpection, (item) => {
                let obj = {
                    index: '' + item.inspectionIndex,
                    offset: '' + item.inspectionOffset,
                    inspection: item.inspectionName,
                    inspectionTarget: item.inspectionTarget || '',
                    standard: {
                        min: item.listProductCSInpectionStandard.length && item.listProductCSInpectionStandard[0].min || '',
                        max: item.listProductCSInpectionStandard.length && item.listProductCSInpectionStandard[0].max || '',
                        name: item.listProductCSInpectionStandard.length && item.listProductCSInpectionStandard[0].name || '',
                        result: item.listProductCSInpectionStandard.length && item.listProductCSInpectionStandard[0].result || ''
                    },
                    imageSeq: item.inspectionImageSeq,
                    image2Seq: 0,
                    description: item.inspectionDescription
                };

                generatedObjectToPersist.productCSInspections.push(obj);
            });

            _.forEach(listItem.listProductCSTime, (item) => {
                let obj = {
                    start: item.timeStart,
                    terms: item.timeTerms
                };

                generatedObjectToPersist.productCSTimes.push(obj);
            });


            let urlForProduct = this.$store.state.hostInService + '/product/cs/add';
            this.$http.post(urlForProduct,generatedObjectToPersist)
            .then((response) => {
                if (response.status === 200 && response.data.code === 'success') {
                    this.$message.success('등록되었습니다');
                    this.fetchProductCSList();
                }  else {
                    this.$message.error('등록되지 않았습니다(1).');
                }
            })
            .catch(error => {
                console.error(error);
                this.$message.error('등록되지 않았습니다(2).');
            });
        },
        handlerToDeleteProductCS(dataOffset) {
            if (confirm('삭제하시겠습니까?')) {
                let urlForProduct = this.$store.state.hostInService + '/product/cs/delete';
                let listItem = this.dataArray[dataOffset];
                let generatedObjectToPersist = {
                    productCsSeq: listItem.productCsSeq
                };
                this.$http.put(urlForProduct,generatedObjectToPersist)
                    .then((response) => {
                        if (response.status === 200 && response.data.code === 'success') {
                            this.$message.success('삭제되었습니다');
                            this.fetchProductCSList();
                        }  else {
                            this.$message.error('삭제되지 않았습니다(1).');
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        this.$message.error('삭제되지 않았습니다(2).');
                    });
            }
        },
        changeFilterState(state) {
            this.filterState = state;

            if (state === '등록중' || state === '등록완료') {
                this.data = _.filter(this.dataBackup, (item) => {
                    return item.tag && item.tag === state;
                });
            } else {
                this.data = this.dataBackup;
            }
        },
        filterCSList() {
            if (this.filterCS) {
                let re = new RegExp(this.filterCS, 'gi');

                this.data = _.filter(this.dataBackup, (item) => {
                    return item.product && item.product.match(re) || item.model && item.model.match(re) || item.modelDetail && item.modelDetail.match(re);
                });
            } else {
                this.data = this.dataBackup;
            }
        }
    }
}
</script>
<style scoped>
    .div_list_footer {
        text-align: center;
    }
</style>