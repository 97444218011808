/****************************************************************************************
 * File Name    : SheetPatrol.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
    <div>
        <a-row :gutter="16">
            <a-col class="gutter-row" :span="12">
                <div class="gutter-box">
                    <PageHeader title = "등록현황" />
                </div>
            </a-col>
        </a-row>
        <a-divider type = 'horizontal' />
        <a-form class="" layout = "vertical" >
            <a-card>
                <a-space align="end">
                    조회
                    <a-input default-value="" v-model="filterCS" v-on:keyup="filterCSList">	
                        <a-select slot="addonAfter" default-value="모델명" v-model="filterField" v-on:change="filterCSList">	
                            <a-select-option value="모델명">모델명</a-select-option>	
                            <a-select-option value="부품명">부품명</a-select-option>
                        </a-select>	
                    </a-input>

                    일자 조회
                    <a-input default-value="" v-model="filterDateStartCS" v-on:keyup="filterCSList" style="width:100px;" placeholder="0000-00-00"></a-input> ~
                    <a-input default-value="" v-model="filterDateEndCS" v-on:keyup="filterCSList" style="width:100px;" placeholder="0000-00-00"></a-input>

                    <a-divider type = 'vertical' />
                    <div class="border:1px solid red;width:100%">
                        
                    </div>
                </a-space>
                <p/>
                <a-table :columns="columns" size = "small" :bordered=false :data-source="data" :pagination="true" :scroll="{ x: 0, y: '80vh' }">
                    <span slot="tablet" slot-scope="record">
                        <span v-if="record.use">{{ record.tablet }}</span>
                        <span v-if="!record.use" style="color:#ccc;">{{ record.tablet }}</span>
                    </span>
                    <span slot="model" slot-scope="record">
                        <span v-if="record.use">{{ record.model }}</span>
                        <span v-if="!record.use" style="color:#ccc;">{{ record.model }}</span>
                    </span>
                    <span slot="part" slot-scope="record">
                        <span v-if="record.use">{{ record.part }}</span>
                        <span v-if="!record.use" style="color:#ccc;">{{ record.part }}</span>
                    </span>
                    <span slot="purpose" slot-scope="record">
                        <span v-if="record.use">{{ record.purpose }}</span>
                        <span v-if="!record.use" style="color:#ccc;">{{ record.purpose }}</span>
                    </span>
                    <span slot="content" slot-scope="record">
                        <span v-if="record.use">{{ record.content }}</span>
                        <span v-if="!record.use" style="color:#ccc;">{{ record.content }}</span>
                    </span>
                    <span slot="date" slot-scope="record">
                        <span v-if="record.use">{{ record.date }}</span>
                        <span v-if="!record.use" style="color:#ccc;">{{ record.date }}</span>
                    </span>
                    <span slot="action" slot-scope="record">
                        <span class="not-mobile" style = 'cursor:pointer' @click="handlerToEditProductCS(record.seq)"><a-icon type="save" /> Edit</span>
                        <a-divider class="not-mobile" type="vertical" />
                        <span class="not-mobile" style = 'cursor:pointer' @click="handlerToDeleteProductCS(record.seq)"><a-icon type="delete" /> Delete</span>
                        <a-divider class="not-mobile" type="vertical" />
                        <span style = 'cursor:pointer' @click="handlerToViewProductCS(record.seq)"><a-icon type="eye" /> View</span>
                    </span>
                </a-table>
            </a-card>

        </a-form>
        <a-drawer title="" placement="right" :closable="false" :visible="flagForDrawer" @close="handlerForCloseDrawer" width="100%" >
            <Patrol4mViewer :target = "target" :pop = "pop" :showSaveBtn = true :showConfirmBtn = true v-on:flagForDrawer="handlerForCloseDrawer" v-on:editPatrolCS="handlerToEditPatrolCS" />
        </a-drawer>
    </div>
</template>
<script>
import _ from "lodash";
import PageHeader from "../components/PageHeader.vue";
import Patrol4mViewer from "./Patrol4mViewer.vue" ;
const columns = [];
const columns3 = [
    {    title: '태블릿' , dataIndex: 'tablet' , key: 'tablet' , scopedSlots: { customRender: 'tablet' }, width: 70},
    {    title: '번호' , dataIndex: 'no' , key: 'no' , scopedSlots: { customRender: 'no' }, width: 50},
    {    title: '모델명' , dataIndex: 'model' , key: 'model' , scopedSlots: { customRender: 'model' },},
    {    title: '부품명' , dataIndex: 'part' , key: 'part' , scopedSlots: { customRender: 'part' },},
    {    title: '변경목적' , dataIndex: 'purpose' , key: 'purpose' , scopedSlots: { customRender: 'purpose' },},
    {    title: '변경내용' , dataIndex: 'content' , key: 'content' , scopedSlots: { customRender: 'content' },},
    {    title: '참고사진' , dataIndex: 'image' , key: 'image' , scopedSlots: { customRender: 'image' },},
    {    title: '등록일자' , dataIndex: 'date' , key: 'date' , scopedSlots: { customRender: 'date' },},
    {    title: '공지기간' , dataIndex: 'noticedate' , key: 'noticedate' , scopedSlots: { customRender: 'noticedate' }, width: 180},
    {    title: '관리'                          ,   key: 'action'   ,  scopedSlots: { customRender: 'action'      },  width : 180},
];
const data = [];
const data3 = [
  { tablet: 'Y', key: '1', gubun: 'Material', no: 1, line: 'E', purpose: '얼음정수기 3종에대한 위생성 강화', content: 'PCB 색상(Green->Blue), UV 기능 추가, 프로그램 변경 Check sum : CHPI - 6446, CPI - E4FB', model: 'CHPI-6500L', part: '메인PBA', date: '2022.06.01', noticedate: '2023.06.01-2023.06.10' },
  { tablet: 'N', key: '2', gubun: 'Material', no: 2, line: 'A', purpose: '', content: '핸드밸브  색상변경', model: 'CHP-671R', part: '밸브-감압아세이', date: '2022.05.29', noticedate: '2023.05.29-2023.05.31' },
];
export default {
    name: 'Patrol4m',
    components: {
        PageHeader ,
        Patrol4mViewer,
    },
    data() {
        return {
            isDataLoaded : false,
            flagForDrawer: false,
            data,
            columns,
            columns3,
            data3,
            data3Backup: [],	
            csArray: [],	
            dataArray: [],	
            dataArray3: [],	
            pop: null,	
            target: null,	
            filterCS : '',	
            filterField: '모델명',
            filterDateStartCS: '',
            filterDateEndCS: ''
        };
    },
    mounted() {
        this.fetchList();

        const userAgent = navigator.userAgent.toLowerCase();
        const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|samsungbrowser|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);

        if (isTablet) {
            let x;
            let els1 = document.body.querySelectorAll('.ant-layout-header');
            for (x = 0; x < els1.length; x++) {
                els1[x].style.display = 'none';
            }
            let els2 = document.body.querySelectorAll('.ant-breadcrumb');
            for (x = 0; x < els2.length; x++) {
                els2[x].style.display = 'none';
            }
            let els3 = document.body.querySelectorAll('.ant-divider-horizontal');
            for (x = 0; x < els3.length; x++) {
                els3[x].style.display = 'none';
            }
            let els4 = document.body.querySelectorAll('.desktop-only');
            for (x = 0; x < els4.length; x++) {
                els4[x].style.display = 'none';
            }
            let els5 = document.body.querySelectorAll('.ant-layout-sider');
            for (x = 0; x < els5.length; x++) {
                els5[x].style.display = 'none';
            }
        }

        if (isTablet) {
            this.columns = [
                {    title: '태블릿' , key: 'tablet' , scopedSlots: { customRender: 'tablet' }, width: 70},
                {    title: '모델명' , key: 'model' , scopedSlots: { customRender: 'model' }, width: 150},
                {    title: '부품명' , key: 'part' , scopedSlots: { customRender: 'part' }, width: 150},
                {    title: '변경목적' , key: 'purpose' , scopedSlots: { customRender: 'purpose' }},
                {    title: '변경내용' , key: 'content' , scopedSlots: { customRender: 'content' },},
                {    title: '등록일자' , key: 'date' , scopedSlots: { customRender: 'date' }, width: 100},
                {    title: '관리'                          ,   key: 'action'   ,  scopedSlots: { customRender: 'action'      },  width : 100},
            ];
        } else {
            this.columns = [
                {    title: '태블릿' , key: 'tablet' , scopedSlots: { customRender: 'tablet' }, width: 70},
                {    title: '모델명' , key: 'model' , scopedSlots: { customRender: 'model' }, width: 150},
                {    title: '부품명' , key: 'part' , scopedSlots: { customRender: 'part' }, width: 150},
                {    title: '변경목적' , key: 'purpose' , scopedSlots: { customRender: 'purpose' }},
                {    title: '변경내용' , key: 'content' , scopedSlots: { customRender: 'content' },},
                {    title: '등록일자' , key: 'date' , scopedSlots: { customRender: 'date' }, width: 100},
                {    title: '관리'                          ,   key: 'action'   ,  scopedSlots: { customRender: 'action'      },  width : 230},
            ];
        }
    },
    methods : {
        fetchList() {
            let timestamp = (new Date()).getTime();

            this.data = [];

            let url = 'https://cowaylqc.iotinfras.com:8100/patrol/4mlist';
            this.$http.get(url)
                .then((response) => {
                    _.forEach(response.data, (item) => {
                        let endTimestamp = (new Date(item.END + ' 23:59:59')).getTime();
                        let use = endTimestamp >= timestamp ? true : false;

                        this.data.push({
                            key: item.SEQ,
                            seq: item.SEQ,
                            model: item.MODEL,
                            part: item.PART,
                            purpose: item.PURPOSE,
                            content: item.CONTENT,
                            date: item.CREATED.substr(0, 10),
                            noticedate: item.START + '~' + item.END,
                            tablet: item.TABLET,
                            use: use
                        });
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        },
        // showDrawer() {
        //     this.flagForDrawer = true;
        // },
        // closeDrawer() {
        //     this.flagForDrawer = false;
        // },
        // editTool() {
        //     console.log("## editTool in Tools ##");
        //     this.flagForDrawer = true;
        // },
        // deleteTool() {
        //     console.log("## deleteTool in Tools  ##");
        //     this.flagForDrawer = true;
        // }
        fetchProductCSList(skipBarcodeScan, forceView) {
            this.csArray = [];
            this.dataArray = [];
            this.data = [];
            let data3 = [];
            this.data3 = [];
            let urlToFetchProductCSList = "https://cowaylqc.iotinfras.com:8100/product/cs/list_simple?size=10000&page=0";
            this.$http.get(urlToFetchProductCSList)
            .then((response) => {
                if(response.status === 200 && response.data.code === 'success') {
                    this.dataArray = response.data.data.content;
                    this.csArray = _.filter(response.data.data.content, (item) => {
                        return item.state === 'F';
                    });
                    this.csArray = this.csArray.reverse();
                    this.dataArray = _.filter(response.data.data.content, (item) => {
                        return item.state === 'G';
                    });
                    this.dataArray = this.dataArray.reverse();
                    this.dataArray3 = _.filter(response.data.data.content, (item) => {
                        return item.state === 'H' || item.state === 'L';
                    });
                    this.dataArray3 = this.dataArray3.reverse();

                    _.forEach(this.dataArray, function(item) {
                        _.forEach(item.listProductCSInpection, function(item2) {
                            if (item2.listProductCSInpectionStandard.length === 1 && item2.listProductCSInpectionStandard[0].min === '') {
                                for (var i = 0; i < 14; i++) {
                                    item2.listProductCSInpectionStandard.push(JSON.parse(JSON.stringify(item2.listProductCSInpectionStandard[0])));
                                }
                            } else if (item2.listProductCSInpectionStandard.length === 1) {
                                let min = item2.listProductCSInpectionStandard[0].min.split('||'),
                                    max = item2.listProductCSInpectionStandard[0].max.split('||'),
                                    name = item2.listProductCSInpectionStandard[0].name.split('||'),
                                    result = item2.listProductCSInpectionStandard[0].result.split('||'),
                                    obj = JSON.parse(JSON.stringify(item2.listProductCSInpectionStandard[0]));
                                for (var j = 0; j < 15; j++) {
                                    obj.min = min[j];
                                    obj.max = max[j];
                                    obj.name = name[j];
                                    obj.result = result[j];
                                    item2.listProductCSInpectionStandard[j] = JSON.parse(JSON.stringify(obj));
                                }
                            }
                        })
                    });

                    _.forEach(this.dataArray3, function(item) {
                        _.forEach(item.listProductCSInpection, function(item2) {
                            if (item2.listProductCSInpectionStandard.length === 1 && item2.listProductCSInpectionStandard[0].min === '') {
                                for (var i = 0; i < 14; i++) {
                                    item2.listProductCSInpectionStandard.push(JSON.parse(JSON.stringify(item2.listProductCSInpectionStandard[0])));
                                }
                            } else if (item2.listProductCSInpectionStandard.length === 1) {
                                let min = item2.listProductCSInpectionStandard[0].min.split('||'),
                                    max = item2.listProductCSInpectionStandard[0].max.split('||'),
                                    name = item2.listProductCSInpectionStandard[0].name.split('||'),
                                    result = item2.listProductCSInpectionStandard[0].result.split('||'),
                                    obj = JSON.parse(JSON.stringify(item2.listProductCSInpectionStandard[0]));
                                for (var j = 0; j < 15; j++) {
                                    obj.min = min[j];
                                    obj.max = max[j];
                                    obj.name = name[j];
                                    obj.result = result[j];
                                    item2.listProductCSInpectionStandard[j] = JSON.parse(JSON.stringify(obj));
                                }
                            }
                        })
                    });
                }
                for(let x = 0; x < this.dataArray.length ; x ++) {
                    let tags = ['검사중'];

                    // TO DO - 서버에서 수정한 후 이 코드 삭제 - updated 가 9 시간이 작게 나와서 프론트엔드에서 수정
                    let updatedObj = new Date((new Date(this.dataArray[x].updated)).getTime() + 9 * 60 * 60 * 1000);
                    let year = updatedObj.getFullYear();
                    let month = updatedObj.getMonth() + 1;
                    if (month < 10) {
                        month = '0' + month;
                    }
                    let date = updatedObj.getDate();
                    if (date < 10) {
                        date = '0' + date;
                    }
                    let hour = updatedObj.getHours();
                    if (hour < 10) {
                        hour = '0' + hour;
                    }
                    let minute = updatedObj.getMinutes();
                    if (minute < 10) {
                        minute = '0' + minute;
                    }
                    let second = updatedObj.getSeconds();
                    if (second < 10) {
                        second = '0' + second;
                    }

                    this.data.push({
                        key : x,
                        name : this.dataArray[x].name,
                        pop : this.dataArray[x].clsProduct.pop,
                        tags : tags,
                        // updated : this.dataArray[x].updated,
                        updated : year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second,
                        model : this.dataArray[x].clsProduct.productModel,
                        modelDetail : this.dataArray[x].clsProduct.productModelDetail,
                        product : this.dataArray[x].clsProduct.productName,
                    });
                }

                // 검사완료, 승인완료 정렬 : 검사완료, 승인완료 순으로, 검사완료는 과거 데이터가 먼저 나오도록
                let tempArr1 = _.filter(this.dataArray3, {
                    state: 'H'
                });
                tempArr1 = _.orderBy(tempArr1, ['updated'], ['desc']);  // 수정 날짜 최신순 정렬
                let tempArr2 = _.filter(this.dataArray3, {
                    state: 'L'
                });
                tempArr2 = _.orderBy(tempArr2, ['updated'], ['desc']);  // 수정 날짜 최신순 정렬
                this.dataArray3 = tempArr1.concat(tempArr2);

                for(let x = 0; x < this.dataArray3.length ; x ++) {
                    // TO DO - 서버에서 수정한 후 이 코드 삭제 - updated 가 9 시간이 작게 나와서 프론트엔드에서 수정
                    let updatedObj = new Date((new Date(this.dataArray3[x].updated)).getTime() + 0 * 60 * 60 * 1000);
                    let year = updatedObj.getFullYear();
                    let month = updatedObj.getMonth() + 1;
                    if (month < 10) {
                        month = '0' + month;
                    }
                    let date = updatedObj.getDate();
                    if (date < 10) {
                        date = '0' + date;
                    }
                    let hour = updatedObj.getHours();
                    if (hour < 10) {
                        hour = '0' + hour;
                    }
                    let minute = updatedObj.getMinutes();
                    if (minute < 10) {
                        minute = '0' + minute;
                    }
                    let second = updatedObj.getSeconds();
                    if (second < 10) {
                        second = '0' + second;
                    }

                    let checkState = 'OK';
                    _.forEach(this.dataArray3[x].listProductCSInpection, function(item) {
                        if (item.listProductCSInpectionStandard[0].min === 'NG') {
                            checkState = 'NG';
                        }
                    });

                    data3.push({
                        key : x,
                        name : this.dataArray3[x].name,
                        pop : this.dataArray3[x].clsProduct.productPop,
                        tags : (this.dataArray3[x].state === 'H' ? ['검사완료'] : ['승인완료']),
                        // updated : this.dataArray3[x].updated,
                        updated : year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second,
                        model : this.dataArray3[x].clsProduct.productModel,
                        modelDetail : this.dataArray3[x].clsProduct.productModelDetail,
                        product : this.dataArray3[x].clsProduct.productName,
                        line : this.dataArray3[x].line,
                        checkState: checkState
                    });
                }
                this.data3 = data3;
                this.data3Backup = JSON.parse(JSON.stringify(this.data3));
                this.flagSpinner = false;

                if (skipBarcodeScan && forceView) {   // 바코드 스캔 또는 직접 입력 후 체크시트가 생성된 후의 조회인 경우, 수정, 삭제인 경우
                    this.target = this.dataArray[0];  // 방금 생성하였으므로 맨 처음 체크시트 조회 - 기존에는 맨 끝을 찾았으나 this.dataArray 정렬 순서 변경으로 맨 처음을 찾음
                    this.flagForDrawer = true;
                } else {
                    // 바코드 스캔한 경우
                    if (this.pop) {
                        let popMatch = false;
                        let matchedCS = null;
                        _.forEach(this.csArray, (item) => {
                            if (item.clsProduct.productPop == this.pop && item.state === 'F') {   // CS에서 바코드 스캔된 pop 에 해당하는 것이 있는지 확인. 여러개 있어도 가장 최근 것 하나를 선택
                                popMatch = true;
                                matchedCS = item;
                                return false;
                            }
                        });

                        // 바코드 매칭이 된 경우
                        if (popMatch) {
                            if (confirm(this.pop + ' 공정순회 체크시트를 작성하시겠습니까?')) {
                                // matchedCS 를 복사해서 valid 값을 C 로 하여 체크시트 생성 후 열기
                                this.handlerToCreateCSForPatrol(matchedCS);
                            } else {
                                this.pop = '';
                                sessionStorage.removeItem('patrol_pop');
                                location.reload();
                            }
                        } else {
                            this.$message.error('인식된 바코드의 제품이 등록되어 있지 않습니다.');
                        }
                    }
                }
            }).catch(error => {
                console.log(error);
                this.flagSpinner = false;
            });
        },
        handlerToConfirmProductCS(dataOffset) {
            if (confirm('완료처리하시겠습니까?')) {
                this.data3.push({
                    key: this.dataArray[dataOffset].x,
                    name: this.dataArray[dataOffset].clsProduct.productName,
                    model: this.dataArray[dataOffset].clsProduct.productModel,
                    cs : this.dataArray[dataOffset].name,
                    id: '',
                    created: this.dataArray[dataOffset].created,
                    updated: this.dataArray[dataOffset].updated,
                    tags: ['완료']
                });

                this.data.splice(dataOffset, 1);
            }
        },
        handlerToAdd() {
            this.flagForDrawer = true;
        },
        handlerToEditProductCS(seq) {
            this.seq = seq;
            console.log(this.seq);

            location = '#/patrol4mCreate?seq=' + this.seq;
        },
        handlerToViewProductCS(seq) {
            this.seq = seq;
            console.log(this.seq);

            location = '#/patrol4mView?seq=' + this.seq;
        },
        handlerToEditPatrolCS(listItem, state) {
            let generatedObjectToPersist = {
                productCsSeq: listItem.productCsSeq,
                productSeq: listItem.clsProduct.productSeq,
                productCSName: listItem.name,
                productCSInspections: [],
                productCSTimes: [],
                state: state,
                pop: listItem.pop,
                csTime: listItem.csTime,
                line: listItem.line,
                manager: listItem.manager,
                approver: (state === 'L' ? sessionStorage.getItem('user_name') : ''),
            };

            _.forEach(listItem.listProductCSInpection, (item) => {
                let min = [],
                    max = [],
                    name = [],
                    result = [];

                _.forEach(item.listProductCSInpectionStandard, function(item2) {
                    min.push(item2.min || '');
                    max.push(item2.max || '');
                    name.push(item2.name || '');
                    result.push(item2.result || '');
                });

                min = min.join('||');
                max = max.join('||');
                name = name.join('||');
                result = result.join('||');

                let obj = {
                    index: '' + item.inspectionIndex,
                    offset: '' + item.inspectionOffset,
                    inspection: item.inspectionName,
                    inspectionTarget: item.inspectionTarget || '',
                    standard: {
                        min: min,
                        max: max,
                        name: name,
                        result: result
                    },
                    imageSeq: item.inspectionImageSeq,
                    image2Seq: item.inspectionImage2Seq,
                    description: item.inspectionDescription
                };

                generatedObjectToPersist.productCSInspections.push(obj);
            });

            _.forEach(listItem.listProductCSTime, (item) => {
                let obj = {
                    start: item.timeStart,
                    terms: item.timeTerms
                };

                generatedObjectToPersist.productCSTimes.push(obj);
            });


            let urlForProduct = this.$store.state.hostInService + '/product/cs/edit';
            this.$http.put(urlForProduct,generatedObjectToPersist)
            .then((response) => {
                if (response.status === 200 && response.data.code === 'success') {
                    this.$message.success('저장되었습니다');
                    // this.fetchProductCSList(true);
                    location.reload();
                }  else {
                    this.$message.error('저장되지 않았습니다(1).');
                }
            })
            .catch(error => {
                console.error(error);
                this.$message.error('저장되지 않았습니다(2).');
            });
        },
        handlerForCloseDrawer() {
            this.flagForDrawer = false;
        },
        handlerToDeleteProductCS(seq) {
            if (confirm('삭제하시겠습니까?')) {
                let url = 'https://cowaylqc.iotinfras.com:8100/patrol/4mdelete/' + seq;
                this.$http.delete(url)
                    .then(() => {
                        this.$message.success('삭제되었습니다.');
                        this.fetchList();
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        },
        handlerToCreateCSForPatrolPop() {
            if (!this.pop) {
                this.$message.error('POP를 입력해주세요');
                return;
            }
            
            let pop = this.pop;
            if (pop) {
                sessionStorage.setItem('patrol_pop', pop);
                location.reload();
            } else {
                this.$message.error('인식된 POP의 제품이 등록되어 있지 않습니다.');
            }
        },
        handlerToCreateCSForPatrol(listItem) {

            let generatedObjectToPersist = {
                productSeq: listItem.clsProduct.productSeq,
                productCSName: 'PATROLCS-' + listItem.name,
                productCSInspections: [],
                productCSTimes: [],
                state: 'G',
                pop: this.pop,
                csTime: ''
            };

            _.forEach(listItem.listProductCSInpection, (item) => {
                let obj = {
                    index: '' + item.inspectionIndex,
                    offset: '' + item.inspectionOffset,
                    inspection: item.inspectionName,
                    inspectionTarget: item.inspectionTarget || '',
                    standard: {
                        min: item.listProductCSInpectionStandard.length && item.listProductCSInpectionStandard[0].min || '',
                        max: item.listProductCSInpectionStandard.length && item.listProductCSInpectionStandard[0].max || '',
                        name: item.listProductCSInpectionStandard.length && item.listProductCSInpectionStandard[0].name || '',
                        result: item.listProductCSInpectionStandard.length && item.listProductCSInpectionStandard[0].result || ''
                    },
                    imageSeq: item.inspectionImageSeq,
                    image2Seq: 0,
                    description: item.inspectionDescription
                };

                generatedObjectToPersist.productCSInspections.push(obj);
            });

            _.forEach(listItem.listProductCSTime, (item) => {
                let obj = {
                    start: item.timeStart,
                    terms: item.timeTerms
                };

                generatedObjectToPersist.productCSTimes.push(obj);
            });


            let urlForProduct = this.$store.state.hostInService + '/product/cs/add';
            this.$http.post(urlForProduct,generatedObjectToPersist)
            .then((response) => {
                if (response.status === 200 && response.data.code === 'success') {
                    this.$message.success('생성되었습니다');
                    this.fetchProductCSList(true, true);
                    this.pop = '';
                    sessionStorage.removeItem('patrol_pop');
                }  else {
                    this.$message.error('생성되지 않았습니다(1).');
                }
            })
            .catch(error => {
                console.error(error);
                this.$message.error('생성되지 않았습니다(2).');
            });
        },
        filterCSList() {
            if (this.filterCS || this.filterDateStartCS || this.filterDateEndCS) {
                let tempData = _.cloneDeep(this.data3Backup);

                if (this.filterCS) {
                    let re = new RegExp(this.filterCS, 'gi');

                    tempData = _.filter(tempData, (item) => {
                        // 모델명
                        if (this.filterField === '모델명') {
                            return item.model && item.model.match(re) || item.modelDetail && item.modelDetail.match(re);
                        }
                        // 제품명
                        else if (this.filterField === '제품명') {
                            return item.product && item.product.match(re);
                        }
                        // 검사결과
                        else if (this.filterField === '검사결과') {
                            return item.checkState === this.filterCS.toUpperCase();
                        }
                    });
                }

                if (this.filterDateStartCS) {
                    try {
                        let startTime = (new Date(this.filterDateStartCS)).getTime();
                        tempData = _.filter(tempData, (item) => {
                            return (new Date(item.updated)).getTime() >= startTime;
                        });
                    } catch (e) {
                        console.log(e)
                    }
                }

                if (this.filterDateEndCS) {
                    try {
                        let endTime = (new Date(this.filterDateEndCS)).getTime() + 86400000;
                        tempData = _.filter(tempData, (item) => {
                            return (new Date(item.updated)).getTime() <= endTime;
                        });
                    } catch (e) {
                        console.log(e)
                    }
                }

                this.data3 = tempData;
            } else {
                this.data3 = this.data3Backup;
            }
        }
    }
}
</script>
<style scoped>
@media (max-width: 1280px) {
    .not-mobile {
        display: none !important;
    }
}
</style>