/****************************************************************************************
 * File Name    : Data.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
    <div>
        
        <div class="menu-container">

            <a-menu class="menu">
                <a-menu-item class="menu-item" key="13"><router-link to="/sheetFinished"><a-icon type="bars" /><br />초/중/종품 검사</router-link></a-menu-item>
                <a-menu-item class="menu-item" key="14"><router-link to="/sheetPatrol"><a-icon type="profile" /><br />공정순회 검사</router-link></a-menu-item>
                <a-menu-item class="menu-item" key="15"><router-link to="/sheetEquipment"><a-icon type="code" /><br />설비 점검 시트</router-link></a-menu-item>
                <a-menu-item class="menu-item" key="16"><router-link to="/tool"><a-icon type="database" /><br />치공구/계측기 점검 시트</router-link></a-menu-item>
                <a-menu-item class="menu-item" key="17"><router-link to="/filter"><a-icon type="layout" /><br />필터</router-link></a-menu-item>
                <a-menu-item class="menu-item" key="18"><router-link to="/patrol4mList"><a-icon type="appstore" /><br />4M 관리</router-link></a-menu-item>
            </a-menu>

        </div>

        <div v-if="showPopup" class="dls-license-mask" style="width: 100%;height: 100%;position: fixed;left:0;top:0;background:rgba(0,0,0,0.7);font-family:Helvetica,Arial,sans-serif;z-index:10;">
            <div class="dls-license-dialog">
                <div style="height:40px;background:#000;position:relative;">
                    <p style="
                        color: #fff;
                        line-height: 46px;
                        margin-left: 20px;
                        font-size: 20px;
                    ">4M 등록현황</p>
                    
                    <i class="dls-license-icon-close" style="width:40px;height:32px;position:absolute;right:0;top:0;line-height:36px;color:#ccc;font-size:36px;font-style:normal;text-align:center;display:inline-block;cursor:pointer;" onclick="this.parentElement.parentElement.parentElement.parentElement.removeChild(this.parentElement.parentElement.parentElement);">x</i>
                </div>
                <div class="dls-license-msg-content" style="font-size:17px;line-height:24px;padding:25px 20px 20px 25px;height: auto;overflow-y: auto;">

                    <div class="ant-card ant-card-bordered">
                    
                        <!-- 내용 시작 -->
                        <div class="ant-card-body">
                            <div data-v-73b355b6="" class="ant-row ant-form-item">
                                <div class="ant-col ant-form-item-label">
                                    <label for="validate_other_model" title="모델명" class="">모델명</label>
                                </div>
                                <div class="ant-col ant-form-item-control-wrapper">
                                    <div class="ant-form-item-control has-success">
                                        <span class="ant-form-item-children">
                                            <input data-v-73b355b6="" placeholder="" type="text" readonly="true" v-model="selectedItem.model" id="validate_other_model" class="ant-input">
                                        </span><!---->
                                    </div>
                                </div>
                            </div>
                            <div data-v-73b355b6="" class="ant-row ant-form-item">
                                <div class="ant-col ant-form-item-label">
                                    <label for="validate_other_part" title="부품명" class="">부품명</label>
                                </div>
                                <div class="ant-col ant-form-item-control-wrapper">
                                    <div class="ant-form-item-control has-success">
                                        <span class="ant-form-item-children">
                                            <input data-v-73b355b6="" placeholder="" type="text" readonly="true" v-model="selectedItem.part" data-__meta="[object Object]" data-__field="[object Object]" id="validate_other_part" class="ant-input">
                                        </span><!---->
                                    </div>
                                </div>
                            </div>
                            <div data-v-73b355b6="" class="ant-row ant-form-item">
                                <div class="ant-col ant-form-item-label">
                                    <label for="validate_other_purpose" title="변경목적" class="">변경목적</label>
                                </div>
                                <div class="ant-col ant-form-item-control-wrapper">
                                    <div class="ant-form-item-control has-success">
                                        <span class="ant-form-item-children">
                                            <input data-v-73b355b6="" placeholder="" type="text" readonly="true" v-model="selectedItem.purpose" data-__meta="[object Object]" data-__field="[object Object]" id="validate_other_purpose" class="ant-input">
                                        </span><!---->
                                    </div>
                                </div>
                            </div>
                            <div data-v-73b355b6="" class="ant-row ant-form-item">
                                <div class="ant-col ant-form-item-label">
                                    <label for="validate_other_content" title="변경내용" class="">변경내용</label>
                                </div>
                                <div class="ant-col ant-form-item-control-wrapper">
                                    <div class="ant-form-item-control has-success">
                                        <span class="ant-form-item-children">
                                            <textarea data-v-73b355b6="" placeholder="" readonly="true" v-model="selectedItem.content" class="ant-input" rows="5" data-__meta="[object Object]" data-__field="[object Object]" id="validate_other_content"></textarea>
                                        </span><!---->
                                    </div>
                                </div>
                            </div>
                            <div data-v-73b355b6="" class="ant-row ant-form-item" style="margin-top:20px;">
                                <div class="ant-col ant-form-item-label">
                                    <label title="참고사진" class="">참고사진</label>
                                </div>
                                <div class="ant-col ant-form-item-control-wrapper">
                                    <div class="ant-form-item-control">
                                        <span class="ant-form-item-children">
                                            <div v-html="selectedItem.imgHtml"></div>
                                        </span><!---->
                                    </div>
                                </div>
                            </div>
                            <div data-v-73b355b6="" class="ant-row ant-form-item" style="margin-top:30px;">
                                <div class="ant-col ant-form-item-label" style="display:inline-block;">
                                    <label title="공지시작일" class="">공지시작일</label>
                                </div>
                                <div class="ant-col ant-form-item-control-wrapper" style="display:inline-block;">
                                    <div class="ant-form-item-control">
                                        <span class="ant-form-item-children">
                                            <span data-v-73b355b6="">{{ selectedItem.start }}</span>
                                        </span><!---->
                                    </div>
                                </div>
                            </div>
                            <div data-v-73b355b6="" class="ant-row ant-form-item">
                                <div class="ant-col ant-form-item-label" style="display:inline-block;">
                                    <label title="공지종료일" class="">공지종료일</label>
                                </div>
                                <div class="ant-col ant-form-item-control-wrapper" style="display:inline-block;">
                                    <div class="ant-form-item-control">
                                        <span class="ant-form-item-children">
                                            <span data-v-73b355b6="">{{ selectedItem.end }}</span>
                                        </span><!---->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 내용 끝 -->

                    </div>

                    <ul v-if="data.length" class="ant-pagination mini ant-table-pagination">
                        <li v-for="(item) in data" :key="item.key" @click="selectItem(item.index)" :class="{ 'ant-pagination-item-active': (item.index === selectedIndex) }" class="ant-pagination-item"><a>{{ item.num }}</a></li>
                    </ul>

                    <p style="
                        margin-top: 20px;
                        margin-bottom: 0;
                    "><label><input type="checkbox" @click="doNotShowToday()"> 오늘 하루 이 창을 표시하지 않음</label></p>
            
                </div>
            </div>
            <div id="image-viewer-container" style="display:none; position:absolute; top:0; left:0; width:100%; height:100%; background-color:rgba(0,0,0,0.3);">
                <div id="image-viewer" style="position:absolute; top:10%; left:10%; width:80%; height:80%; background-color:#fff; border:1px solid #ccc;">
                    <div id="image-viewer-close" @click="imageViewerClose()" style="position:absolute; top:23px; right:20px; cursor:pointer; font-size:34px; font-weight:bold; -webkit-text-stroke:1px #fff; background-color:#eee;     border:1px solid #ccc;     padding:0 10px; line-height: 1.2; border-radius:4px;">X</div>
                    <div id="image-viewer-content" style="height:100%; overflow:auto;"></div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import _ from "lodash";
import $ from "jquery";
export default {
    name: 'TabletMenu',
    components: {
    },
    data() {
        return {
            selectedIndex: 0,
            selectedItem: null,
            data: [],
            fileDelimiter: '||',
            popuptime: 0,
            showPopup: false,
        };
    },
    mounted() {
        this.fetchList();

        let time = localStorage.getItem('popuptime');
        if (time) {
            this.popuptime = +time;
        }

        const userAgent = navigator.userAgent.toLowerCase();
        const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|samsungbrowser|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);

        if (isTablet) {
            let x;
            let els1 = document.body.querySelectorAll('.ant-layout-header');
            for (x = 0; x < els1.length; x++) {
                els1[x].style.display = 'none';
            }
            let els2 = document.body.querySelectorAll('.ant-breadcrumb');
            for (x = 0; x < els2.length; x++) {
                els2[x].style.display = 'none';
            }
            let els3 = document.body.querySelectorAll('.ant-divider-horizontal');
            for (x = 0; x < els3.length; x++) {
                els3[x].style.display = 'none';
            }
            let els4 = document.body.querySelectorAll('.desktop-only');
            for (x = 0; x < els4.length; x++) {
                els4[x].style.display = 'none';
            }
            let els5 = document.body.querySelectorAll('.ant-layout-sider');
            for (x = 0; x < els5.length; x++) {
                els5[x].style.display = 'none';
            }
        }
    },
    methods : {
        fetchList() {
            let timestamp = (new Date()).getTime();

            this.data = [];

            let url = 'https://cowaylqc.iotinfras.com:8100/patrol/4mlist';
            this.$http.get(url)
                .then((response) => {
                    let self = this;

                    _.forEach(response.data, (item) => {
                        let endTimestamp = (new Date(item.END + ' 23:59:59')).getTime();
                        let use = endTimestamp >= timestamp ? true : false;

                        // 날짜가 공지기간 이내이고 태블릿 표시가 Y 인 건에 대해서만 표시
                        if (use && item.TABLET === 'Y') {
                            let html = '';
                            let files = item.UPLOAD_FILE_SEQ.split(this.fileDelimiter);
                            _.forEach(files, (seq) => {
                                if (!seq) {
                                    return;
                                }
                                
                                let src = this.$store.state.hostInService +'/upload/image/download/' + seq;
                                html += '<a href = "' + src + '" target="_blank" id="view-image-' + seq + '" style="margin-right:20px;"><img src = "' + src + '" style = "max-width:80px; max-height:60px;"/></a> ';

                                ((self) => {
                                    // 이미지 보기
                                    $('body').off('click.view-image-' + seq).on('click.view-image-' + seq, '#view-image-' + seq , function(e) {
                                        e.preventDefault();

                                        let html = '<p style="margin:20px; text-align:center;"><img src = "' + src + '" style = "max-width:900px;"/></p>';

                                        self.viewInspectionImage(html);
                                    });
                                })(self);
                            });
                            self.imgHtml = html;

                            this.data.push({
                                key: item.SEQ,
                                seq: item.SEQ,
                                model: item.MODEL,
                                part: item.PART,
                                purpose: item.PURPOSE,
                                content: item.CONTENT,
                                start: item.START,
                                end: item.END,
                                date: item.CREATED.substr(0, 10),
                                noticedate: item.START + '~' + item.END,
                                tablet: item.TABLET,
                                imgHtml: html,
                                use: use
                            });
                        }

                        if (this.data.length) {
                            _.forEach(this.data, (item, i) => {
                                item.index = i;
                                item.num = i + 1;
                            });

                            this.selectedItem = this.data[this.selectedIndex];

                            if (!this.popuptime || (this.popuptime && +(new Date()) > this.popuptime)) {
                                this.showPopup = true;
                            }
                        }
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        },
        selectItem(index) {
            this.selectedIndex = index;
            this.selectedItem = this.data[this.selectedIndex];
        },
        viewInspectionImage(html) {
            document.getElementById('image-viewer-container').style.display = 'block';
            document.getElementById('image-viewer-content').innerHTML = html;
        },
        imageViewerClose() {
            document.getElementById('image-viewer-container').style.display = 'none';
            document.getElementById('image-viewer-content').innerHTML = '';
        },
        getDateStr(dateObj) {
            if (!dateObj) {
                dateObj = new Date();
            }

            let year = dateObj.getFullYear();
            let month = dateObj.getMonth() + 1;
            if (month < 10) {
                month = '0' + month;
            }
            let date = dateObj.getDate();
            if (date < 10) {
                date = '0' + date;
            }

            return year + '-' + month + '-' + date;
        },
        doNotShowToday() {
            this.showPopup = false;

            let datetime = this.getDateStr() + ' 23:59:59';
            let popuptime = (new Date(datetime)).getTime();

            localStorage.setItem('popuptime', popuptime);
        }
    }
}
</script>
<style scoped>
    .menu-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
    }

    .menu {
        margin: 130px auto;
        width: 100%;
        border-right: none;
    }

    .menu-item {
        display: inline-block;
        margin-left: 3%;
        margin-right: 3%;
        margin-bottom: 80px !important;
        width: 27%;
        box-sizing: border-box;
        border: 1px solid #2d3a66;
        border-radius: 8px;
        height: auto !important;
        background-color: #fdfbfb;
    }

    .menu-item a {
        display: block;
        padding: 70px 0 !important;
        text-align: center;
        font-size: 24px !important;
        font-weight: bold;
        color: #2d3a66;
    }

    .menu-item:last-child {
        margin-bottom: 8px;
    }

    .ant-menu-item .anticon {
        margin-bottom: 10px;
        margin-right: 0;
        font-size: 50px;
        color: #2d3a66;
    }

    .ant-pagination.mini .ant-pagination-item {
        margin-right: 10px;
        width: 30px;
        height: 30px;
        font-size: 16px;
        line-height: 28px;
    }

    .dls-license-dialog {
        position: absolute;
        left: 0;
        top: 50%;
        right: 0;
        margin: 0 auto;
        transform: translateY(-50%);
        background: #fff;
        width: 80%;
        height: 680px;
    }

    .dls-license-dialog .ant-card.ant-card-bordered {
        height: 550px;
        overflow-y: auto;
    }

    @media screen and (max-width: 1024px) {
        .dls-license-dialog {
            width: 90%;
            height: 930px;
        }

        .dls-license-dialog .ant-card.ant-card-bordered {
        height: 800px;
        overflow-y: auto;
    }
    }
</style>