/****************************************************************************************
 * File Name    : Home.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.06.26  	최명호    	Create
****************************************************************************************/
<template>
    <div class="home">
        <div class="login-container">
            <a-form v-if="!userNumber" class="" :form="form" layout = "vertical"  @submit="onSubmit">
                <div class="login-top-container">
                    <img src="../assets/images/login-top.jpg" class="login-top" />
                </div>
                <a-card>
                    <a-form-item label="공장 선택">
                        <a-select v-model="user.factory" v-decorator="['user.factory',{initialValue: user.factory, rules: [{ required: true, message: '공장을 선택하세요' }],},]">
                            <a-select-option value="yugu">
                                유구
                            </a-select-option>
                            <a-select-option value="incheon">
                                인천
                            </a-select-option>
                            <a-select-option value="pocheon">
                                포천
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item label="사번">
                        <a-input placeholder="Number" v-decorator="['number',{rules: [{ required: true, message: '사번을 입력하세요' }],},]"/>
                    </a-form-item>
                    <a-form-item label="비밀번호">
                        <a-input type="password" placeholder="Password" v-decorator="['pass',{rules: [{ required: true, message: '비밀번호를 입력하세요' }],},]"/>
                    </a-form-item>
                </a-card>
                <a-button type="default" html-type="submit"><img src="../assets/images/btn-login.jpg" /></a-button>
                <a-divider type = 'vertical' />
            </a-form>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';

// 공장별 API 포트 번호
const factoryPort = {
    yugu: 19108,
    incheon: 19109,
    pocheon: 19110
};

export default {
  name: 'Home',
  components: {
  },
  data() {
      return {
          isDataLoaded : false,
          flagSpinner : false,
          data : [],
          dataArray : [],
          user: {
            factory: ''
          }
      };
  },
  beforeCreate() {
      this.form = this.$form.createForm(this, { name: 'validate_other' });
  },
  mounted() {
    this.user.factory = window.localStorage.getItem('factory') || 'yugu';

      this.isDataLoaded = true;

      this.fetchAccountList();

      this.userNumber = sessionStorage.getItem('user_number');

        const userAgent = navigator.userAgent.toLowerCase();
        const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|samsungbrowser|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);

      if (this.userNumber) {
        if (isTablet) {
            // this.$router.push('/tabletMenu');
            location = 'https://cowaylqc.iotinfras.com/sites/' + this.user.factory + '/#/tabletMenu';
        } else {
            this.$router.push('/dashboard');
        }
      }

      if (isTablet) {
            let x;
            let els1 = document.body.querySelectorAll('.ant-layout-header');
            for (x = 0; x < els1.length; x++) {
                els1[x].style.display = 'none';
            }
            let els2 = document.body.querySelectorAll('.ant-breadcrumb');
            for (x = 0; x < els2.length; x++) {
                els2[x].style.display = 'none';
            }
            let els3 = document.body.querySelectorAll('.ant-divider-horizontal');
            for (x = 0; x < els3.length; x++) {
                els3[x].style.display = 'none';
            }
            let els4 = document.body.querySelectorAll('.desktop-only');
            for (x = 0; x < els4.length; x++) {
                els4[x].style.display = 'none';
            }
            let els5 = document.body.querySelectorAll('.ant-layout-sider');
            for (x = 0; x < els5.length; x++) {
                els5[x].style.display = 'none';
            }
      }
  },
  methods : {
        addAccount() {
            this.flagForDrawer = false;
            this.data = [];
        },
        onSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                if(!err) {
                    let packetForLogin = {};
                    packetForLogin.number = values.number;
                    packetForLogin.pass = values.pass;

                    


                    this.dataArray = [];
                    this.data = [];
                    let urlToFetchAccountList = 'https://cowaylqc.iotinfras.com:' + factoryPort[this.user.factory] + '/account/list?size=1000';
                    this.$http.get(urlToFetchAccountList)
                    .then((response) => {
                        if(response.status === 200 && response.data.code === 'success') {
                            this.dataArray = response.data.data.content;
                        }
                        for(let x = 0; x < this.dataArray.length ; x ++) {
                            this.dataArray[x].key = x;
                            this.dataArray[x].role = this.dataArray[x].kind;
                            this.dataArray[x].image = this.$store.state.hostInService + '/upload/image/download/' + this.dataArray[x].profileImageSeq
                            this.data.push(JSON.parse(JSON.stringify(this.dataArray[x])));
                        }
                        this.flagSpinner = false;

                        let user = _.find(this.data, {
                            number: packetForLogin.number
                        });

                        if (user && user.pass === packetForLogin.pass) {
                            sessionStorage.setItem('user_number', user.number);
                            sessionStorage.setItem('user_name', user.name);
                            sessionStorage.setItem('user_kind', user.kind);
                            
                            window.localStorage.setItem('factory', this.user.factory);

                            //   this.$message.success("로그인 되었습니다.");
                            const userAgent = navigator.userAgent.toLowerCase();
                            const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|samsungbrowser|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);

                            if (isTablet) {
                                // this.$router.push('/tabletMenu');
                                location = 'https://cowaylqc.iotinfras.com/sites/' + this.user.factory + '/#/tabletMenu';

                                let x;
                                let els1 = document.body.querySelectorAll('.ant-layout-header');
                                for (x = 0; x < els1.length; x++) {
                                    els1[x].style.display = 'none';
                                }
                                let els2 = document.body.querySelectorAll('.ant-breadcrumb');
                                for (x = 0; x < els2.length; x++) {
                                    els2[x].style.display = 'none';
                                }
                                let els3 = document.body.querySelectorAll('.ant-divider-horizontal');
                                for (x = 0; x < els3.length; x++) {
                                    els3[x].style.display = 'none';
                                }
                                let els4 = document.body.querySelectorAll('.desktop-only');
                                for (x = 0; x < els4.length; x++) {
                                    els4[x].style.display = 'none';
                                }
                                let els5 = document.body.querySelectorAll('.ant-layout-sider');
                                for (x = 0; x < els5.length; x++) {
                                    els5[x].style.display = 'none';
                                }
                            } else {
                                // this.$router.push('/dashboard');
                                location = 'https://cowaylqc.iotinfras.com/sites/' + this.user.factory + '/#/dashboard';
                            }
                        } else if (user) {
                            this.$message.error("비밀번호가 틀립니다.");
                        } else {
                            this.$message.error("입력하신 사용자 정보가 없습니다.");
                        }
                    }).catch(error => {
                        console.log(error);
                        this.flagSpinner = false;
                    });




                    
                }
            });
        },
    }
}
</script>
<style scoped>
    .home {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
    }

    .login-container {
        margin: 200px auto;
        padding: 0 50px 60px;
        width: 470px;
        background-color: #fff;
    }

    .login-container .login-top-container {
        padding-top: 30px;
        text-align: center;
    }

    .login-container .ant-card-bordered {
        border: none !important;
    }

    .login-container .ant-input {
        width: 320px;
    }

    .login-container .ant-btn {
        border: none;
    }
</style>
