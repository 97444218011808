/****************************************************************************************
 * File Name    : ModalCheckItemStandard.vue
 * Function     :
 * Author       : 정재윤
 * Tester       :
 * Page         :
 * Target       :
 * Description  : : Standard  부분만 따로 
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2022.02.1  	zena    	Create
****************************************************************************************/
<template>
    <a-form :form="form">
        <a-form-item :label="cInspectionSubTitle" v-if="this.type !== 'patrol'">
            <a-button icon="minus-circle" v-show="this.parentListSize > 1" @click="remove()" :ghost=true  type = 'danger' size = "small" style = 'position:absolute; top:-46px; left:170px;'> 삭제</a-button>
            <!--baseInput placeholder="세부검사기준" v-model="productCSInspectionStandard.name" /-->
            <a-input placeholder="세부검사기준" v-decorator="['inspectionStandardName',{rules: [{ required: true, message: '세부검사기준을 입력하세요' }],initialValue:productCSInspectionStandard.name}]" v-on:input="event=>{productCSInspectionStandard.name = event.target.value} "  />

        </a-form-item>

        <a-form-item>
            <a-spin :spinning="spinning">
                <a-upload name="file" :multiple="false" :action="$store.state.imageUploadServer" @change="imageUpload"
                    v-decorator="['imageSeq',{rules: [{ required: true, message: '참고이미지를 선택하세요' }],},]">
                    <a-button> <a-icon type="camera" /> 제품이미지 선택 </a-button>
                </a-upload>
            </a-spin>
            <div v-html="inspectionImageList"></div>
        </a-form-item>
    </a-form>
</template>
<script>
import _ from "lodash";
import $ from "jquery";
//import BaseInput from "../components/BaseInput.vue"

export default {

    name: 'ModalCheckItemStandard',
    model: {prop: 'productCSInspectionStandard'},
    props : ['productCSInspectionStandard', 'viewIndex', 'parentListSize', 'type'],
    components: {
        //'baseInput': BaseInput
    },
    data() {
        this.cInspectionSubTitle = '세부검사기준';
        
        return {
            spinning        : false,
            form : this.$form.createForm(this, {
                name: 'ModalCheckItem',
            }),
            uploadFileSeq: [],
            inspectionImageList: ''
        };
    },

    mounted() {
        console.log('ModalCheckItemStandard: produtCSInspection', this.productCSInspectionStandard);
        console.log('parentListSize:', this.parentListSize);
        //console.log(this.main_offset, this.sub_offset, this.inspection)
        //this.isDataLoaded = true;

        let self = this;

        let html = '';
        let imageSeq = [];
        if (self.productCSInspectionStandard.inspectionImageSeq && self.productCSInspectionStandard.inspectionImageSeq != '0') {
            imageSeq = this.productCSInspectionStandard.inspectionImageSeq.split('||');
        }

        _.forEach(imageSeq, (item) => {
            self.uploadFileSeq.push(item);

            let src = self.$store.state.hostInService +'/upload/image/download/' + item;
            html += '<p><a href = "' + src + '" target="_blank"><img src = "' + src + '" style = "height:100px; vertical-align:bottom;"/></a> <span id="delete-image-' + self.productCSInspectionStandard.produtCsInspectionSeq + '-' + item + '" style="font-size:12px; cursor:pointer;">삭제</span></p>';

            ((self) => {
                $('body').off('click.delete-image-' + self.productCSInspectionStandard.produtCsInspectionSeq + '-' + item).on('click.delete-image-' + self.productCSInspectionStandard.produtCsInspectionSeq + '-' + item, '#delete-image-' + self.productCSInspectionStandard.produtCsInspectionSeq + '-' + item , function() {
                    if (confirm('삭제하시겠습니까?')) {
                        self.uploadFileSeq.splice(self.uploadFileSeq.indexOf('' + item), 1);
                        
                        html = '';
                        _.forEach(self.uploadFileSeq, (item) => {
                            let src = self.$store.state.hostInService +'/upload/image/download/' + item;
                            html += '<p><a href = "' + src + '" target="_blank"><img src = "' + src + '" style = "height:100px; vertical-align:bottom;"/></a> <span id="delete-image-' + self.productCSInspectionStandard.produtCsInspectionSeq + '-' + item + '" style="font-size:12px; cursor:pointer;">삭제</span></p>';
                        });

                        self.inspectionImageList = html;

                        self.productCSInspectionStandard.inspectionImageSeq = self.uploadFileSeq.join('||');
                        self.$emit('input', self.productCSInspectionStandard);
                    }
                });
            })(self);
        });

        self.inspectionImageList = html;
    },
    methods : {
        imageUpload(info) {
            console.log('Image Upload Info:',info);
            this.spinning = info.file.status === 'uploading' ? true : false;
            if(info.file.status === 'done') {
                this.$message.success(`${info.file.name} file uploaded successfully`);

                this.uploadFileSeq.push(info.file.response.data.uploadFileSeq);

                this.productCSInspectionStandard.inspectionImageSeq = this.uploadFileSeq.join('||');
                this.$emit('input', this.productCSInspectionStandard);

            } else if(info.file.status === 'error') {
                this.$message.error(`${info.file.name} file upload failed.`);
            }
        },
        remove() {
            console.log('remove()', this.viewIndex)
            this.$emit('removeStarndardItem', this.viewIndex);
        }
    }
}
</script>
<style scoped>
</style>