/****************************************************************************************
 * File Name    : EquipmentControlsAdd.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
    <div>
         <!-- <a-row :gutter="4">
            <a-col class="gutter-row" :span="12">
                <div class="gutter-box">
                    <PageHeader title = "설비 점검 항목 등록하기" />
                </div>
            </a-col>
            <a-col class="gutter-row" :span="12">
                <div class="gutter-box div_action">
                </div>
            </a-col>
        </a-row>
        <a-divider type = 'horizontal' />
        <p/> -->
        <div v-if = "isVisibleForNew === true" style = 'margin-top:20px;padding:10px 10px 10px 10px;border:0px solid #CCC'>
            <a-row type="flex" justify="center" align="top" >
                <!-- <a-col :span="6" style = 'border-right:1px solid #CCC'>
                     <div class = "todo">설정을 단계별로 진행한다</div>
                    <a-steps direction="vertical" :current="step">
                        <a-step title="제품선택" :description="selectedProduct" />
                        <a-step title="점검항목설정" :description="createdCSTotal" />
                        <a-step title="완료" description="점검항목을 설정하고, 설정된 정보를 저장하세요" />
                    </a-steps>
                    <div style = 'width:100%;text-align:center;margin-top:20px'>
                            <a-divider type = 'horizontal' />
                            <div class = 'todo'>Step 상태에 따라 버튼은 Disable / Enable 됨</div>
                            <a-button icon="rollback" type = 'danger' ghost="true" @click = "setStepPrev"> 이전 </a-button>
                            <a-divider type = 'vertical' />
                            <a-button icon="check" type = 'primary' ghost="true" @click = "setStepNext"> 다음 </a-button>
                    </div>
                </a-col> -->
                <a-col :span="24">
                    <div style = 'padding:0px 10px 10px 20px'>
                        <div v-if = "step === 0">
                            <!-- <div class = "todo">전체선택 추가, 중복선택 불가, 검색 기능 추가예정. 상품을 선택후 좌측 다음 버튼 클릭 </div>
                            <a-input-search placeholder="검색 상품명 / 코드명" style="width: 100%"  />
                            <p/>
                            <a-table :columns="columns" size = "small" :bordered=false :data-source="data" :pagination="false" :scroll="{ x: 0, y: '57vh' }">
                                <span slot="action" slot-scope="record">
                                    <a-switch v-if = "record.selected === true " checked-children="선택" un-checked-children="취소" @change="handlerForSwitch" />
                                    <a-switch v-else disabled checked-children="선택" un-checked-children="사용불가" />
                                </span>
                                <span slot="tags" slot-scope="tags">
                                    <a-tag v-for="(item,index) in tags" :key='index' :color="index===0 ? '#7F7F7F' : '#5B5B5B'" >
                                        {{ item.toUpperCase() }}
                                    </a-tag>
                                </span>
                                <span slot="category" slot-scope="category">
                                    <a-tag  color = "#5B5B5B">
                                        {{ category }}
                                    </a-tag>
                                </span>
                                <span slot="name" slot-scope="text">{{ text }}</span>
                            </a-table> -->
                        </div>
                        <div v-if = "step === 1">
                            <!-- <div class = 'todo'>버튼 '세부항목' 는 처음에 disabled </div> -->
                            <!-- <a-divider type = 'vertical' />
                             -->
                            <div class = 'todo'>등록된 설비항목에 따라 탭은 달라짐</div>
                            <div class = 'todo'>모든 탭을 설정해야함</div>
                            <a-row type="flex" justify="center" align="top" :gutter="16">
                                <a-col :span="24" style = ''>
                                    <div style = 'height:60vh;overflow:auto;'>
                                        <a-tabs v-model="activeKey" hide-add type="editable-card" tab-position="right" @edit="onEdit" :key="keyForTab">
                                            <a-tab-pane v-for="pane in panes" :key="pane.key" :tab="pane.title" :closable="pane.closable">
                                                <!-- <ModalCheckItem :main_offset="pane.key" :sub_offset = "pane.key"/> -->
                                                <EquipmentControlForm :title = "pane.title"/>
                                            </a-tab-pane>
                                        </a-tabs>
                                    </div>
                                </a-col>
                            </a-row>
                            <a-button type="primary" html-type="submit" :ghost = false size = "default" icon = "plus">Save</a-button>
                            <a-divider type = 'vertical' />
                            <a-button type="secondary" html-type="button" :ghost = false  size = "default" icon = "plus">Add more</a-button>
                            <a-divider type = 'vertical' />
                            <a-button type="danger" html-type="reset" :ghost = false  size = "default" icon = "reload">Reset</a-button>
                        </div>
                        <div v-if = "step === 2">
                            <!-- <div class = 'todo'> 자료가 등록되면 enabled 됨</div> -->
                            <!-- <div class = 'todo'>설정된 CS의 검사시간을 설정한다.</div>
                            <ModalSetTime /> -->
                            <!-- <div style = 'text-align:center;margin-top:50px'>
                                <div class = 'todo'>'입력내용지우기' 클릭시 입력내용을 지우고, 처음으로, 저장후, 처음 리스트로 이동</div>
                                <a-button icon="rollback" type = 'danger' ghost=true> 입력내용지우기 </a-button>
                                <a-divider type = 'vertical' />
                                <a-button icon="check" type = 'primary' ghost=true> 완료 </a-button>
                            </div> -->
                        </div>

                    </div>
                </a-col>
                <!-- <ModalCheckItem main_offset="1" sub_offset = "1"/> -->
            </a-row>
        </div>
        <!-- <a-divider type = 'horizontal' /> -->
    </div>
</template>
<script>
// import PageHeader from "../components/PageHeader.vue" ;
// import ModalProductList from "../modals/ModalProductList.vue" ;
// import ModalCheckItem from "../modals/ModalCheckItem.vue" ;
// import ModalSetTime from "../modals/ModalSetTime.vue" ;
// import Product from "../components/Product.vue" ;
import EquipmentControlForm from "../components/EquipmentControlForm.vue" ;
// EquipmentControlForm

const MAX_STEP = 2;
const MIN_STEP = 0;

const columns = [
    {    title: '선택'                                  ,   key: 'action'   ,  scopedSlots: { customRender: 'action'      },  width : 100},
    {    title: '설비명'    ,   dataIndex: 'name'       ,   key: 'name'     ,  scopedSlots: { customRender: 'name'        },},
    {    title: '구분'      ,   dataIndex: 'category'   ,   key: 'category' ,  scopedSlots: { customRender: 'category'     },},
    {    title: '구성장치'  ,   dataIndex: 'tags'       ,    key: 'tags'     ,  scopedSlots: { customRender: 'tags'        },  width : 200},
];

const data = [
  {    key: '1',    name: '컨베이어벨트'    ,   category : '정수기전용',    tags: ['배관부','제어부' ],  selected : true    },
  {    key: '2',    name: '진공펍프'        ,   category : '공통',          tags: ['배관부'          ],  selected : false   },
  {    key: '3',    name: '컨트롤 패널'     ,   category : '공통',          tags: ['배관부'          ],  selected : false   },
  {    key: '4',    name: '냉매척진기'      ,   category : '정수기전용',    tags: ['제어부'          ],  selected : false   },
];
export default {
    name: 'EquipmentControlsAdd',
    components: {
        // PageHeader,
        // ModalProductList,
        // Product,
        // ModalCheckItem,
        // ModalSetTime
        EquipmentControlForm
    },
    data() {
        const panes = [
                { title: '배관부', content: '', key: '1' ,child : []},
                { title: '제어부', content: '', key: '2' ,child : []},
                { title: '진공펌프', content: '', key: '3' ,child : []},
            ];
        return {
            MAX_STEP,
            MIN_STEP,
            step : 1,
            data,
            columns,
            selectedProduct : '상품을 선택하세요',
            // createdCSTotal : '검사항목을 추가하세요',
            itemTotal : 1,
            panes,
            activeKey: panes[0].key,
            newTabIndex: 1,
            keyForTab : 0,
            //
            isDataLoaded : false,
            isVisibleForNew : true,
            isVisibleForTemplate : false,
            isSelectProduct : false,
            isVisibleForModalCheckItem : false,
            isVisibleForModalSetTime : false,
        };
    },
    computed : {
        createdCSTotal() {
            if(this.panes.length == 1)
                 return '기본항목이 설정되었습니다';
            else return '항목 (' + this.panes.length + ') 건을 설정합니다';
        }
    },
    mounted() {
        this.isDataLoaded = true;
    },
    methods : {
        handlerForButtonNewElement() {
            const panes = this.panes;
            if(this.newTabIndex === 1) this.newTabIndex ++;
            const activeKey = `${this.newTabIndex++}`;
            panes.push({ title: '검사항목 ' + activeKey, content: 'Content of new Tab', key: activeKey, child : [] });
            this.panes = panes;
            this.activeKey = activeKey;
        },
        onEdit(targetKey, action) {
            this[action](targetKey);
        },
        remove(targetKey) {
            let activeKey = this.activeKey;
            let lastIndex;
            this.panes.forEach((pane, i) => {
                if (pane.key === targetKey) {
                lastIndex = i - 1;
                }
            });
            const panes = this.panes.filter(pane => pane.key !== targetKey);
            if (panes.length && activeKey === targetKey) {
                if (lastIndex >= 0) {
                activeKey = panes[lastIndex].key;
                } else {
                activeKey = panes[0].key;
                }
            }
            this.panes = panes;
            this.activeKey = activeKey;
            this.itemTotal --;
        },
        // onEdit(targetKey, action) {
        //     this[action](targetKey);
        // },
        setStepNext() {
            if(this.step < this.MAX_STEP) {
                this.step ++;
            }
        },
        setStepPrev() {
            if(this.step > this.MIN_STEP) {
                this.step --;
            }
        },
        handlerForSwitch() {
            this.selectedProduct = "컨베이어벨트/정수기전용/배관부/제어부";
            // this.setStepNext();
        },

        // handlerForButtonNewElement() {
        //     console.log("## handlerForButtonNewElement ##");
        // },
        // handlerForButtonNewElement() {
        //     this.showModalCheckItem();
        // },
        // showModalCheckItem() {
        //     this.isVisibleForModalCheckItem = true;
        // },
        // handlerForButtonSetTime() {
        //     this.showModalSetTime();
        // },
        handlerForButtonTemplate() {
            this.isVisibleForTemplate = true;
            this.isVisibleForNew = false;
        },
        handlerForButtonNew() {
            this.isVisibleForTemplate = false;
            this.isVisibleForNew = true;
        },
        // showModalProductList() {
        //     this.isVisibleForNew = true;
        // },
        handlerForButtonNewInnerElement(offset) {
            this.panes[offset-1].child.push("#");
        }
        // showModalSetTime() {
        //     this.isVisibleForModalSetTime = true;
        // },
        // showModalCheckItem() {
        //     this.isVisibleForModalCheckItem = true;
        // },
        // onClickButtonInModalSetTime(e) {
        //     console.log(e);
        //     this.isVisibleForModalSetTime = false;
        // },
        // onClickButtonInModalCheckItem(e) {
        //     console.log(e);
        //     this.isVisibleForModalCheckItem = false;
        // },
        // onClickButtonInModalProductList(e) {
        //     console.log(e);
        //     this.isVisibleForNew = false;
        // },
        //  selectProduct() {
        //     console.log("## selectProduct in FinishedProduct ##");
        //     this.isSelectProduct = true;
        //     // this.$emit('selectProduct');
        // },
    }
}
</script>
<style scoped>
    .div_selected_product {
        margin-top : 30px;
    }
</style>