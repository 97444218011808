/****************************************************************************************
 * File Name    : Data.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
    <div style="margin-left: -24px; margin-right: -24px;">
        <a-row :gutter="16">
            <a-col :span="3">
                
            </a-col>
            <a-col :span="21">
                <a-row :gutter="16">
                    <a-col class="gutter-row" :span="24">
                        <div class="gutter-box">
                            <PageHeader title = "초/중/종품 검사현황 - 정수기" />
                        </div>
                    </a-col>
                </a-row>
                <a-divider type = 'horizontal' />
                <img id="loading-table" class="more-btn" src="../assets/images/Loading_2.gif" style="position:absolute; top:65%; left:50%; margin-top:-30px; margin-left:-30px; width:60px; z-index:10;" />
                <a-table :columns="columns" size = "small" :bordered=false :data-source="data" :pagination="{ pageSize: 15 }" :scroll="{ x: 0, y: '80vh' }">
                    <span slot="tags" slot-scope="tags">
                        <a-tag v-for="tag in tags" :key="tag" :color="tag === '승인완료' ? '#00b0f0' : '#929292'" >
                            {{ tag.toUpperCase() }}
                        </a-tag>
                    </span>
                    <span slot="name" slot-scope="text">{{ text }}</span>
                    <span slot="barcode" slot-scope="barcode">{{ barcode }}</span>
                    <span slot="updated" slot-scope="updated">{{ updated }}</span>
                </a-table>
                <p/>
                <p/>
            </a-col>
        </a-row>
    </div>
</template>
<script>
const columns = [
    {    title: '상태'    ,   dataIndex: 'tags' ,   key: 'tags'     ,  scopedSlots: { customRender: 'tags'        },},
    {    title: '모델명' , dataIndex: 'model' , key: 'model' , scopedSlots: { customRender: 'model' },},
    {    title: '세부모델명' , dataIndex: 'modelDetail' , key: 'modelDetail' , scopedSlots: { customRender: 'modelDetail' },},
    {    title: '제품명' , dataIndex: 'product' , key: 'product' , scopedSlots: { customRender: 'name' },},
    {    title: '제품정보' , dataIndex: 'barcode' , key: 'barcode' , scopedSlots: { customRender: 'barcode' },},
    {    title: '검사결과',  dataIndex: 'checkState', key: 'checkState'  ,  scopedSlots: { customRender: 'updated'     },},
    {    title: '최종수정일',  dataIndex: 'updated', key: 'updated'  ,  scopedSlots: { customRender: 'updated'     },},
];

import _ from "lodash";
import PageHeader      from "../components/PageHeader.vue";
export default {
    name: 'DashboardDetailFinished1',
    components: {
        PageHeader
    },
    data() {
        return {
            isDataLoaded : false,
            flagForDrawer: false,
            data : [],
            dataArray: [],
            columns,
        };
    },
    mounted() {
        this.loadList();
    },
    methods : {
        loadList() {
            this.csArray = [];
            this.dataArray = [];
            let data = [];
            this.data = [];
            this.data3 = [];
            let urlToFetchProductCSList = "https://cowaylqc.iotinfras.com:8100/product/cs/list?size=10000&page=0";
            this.$http.get(urlToFetchProductCSList)
            .then((response) => {
                if(response.status === 200 && response.data.code === 'success') {
                    this.dataArray = response.data.data.content;
                    this.dataArray = _.filter(response.data.data.content, (item) => {
                        return item.clsProduct.listProductType[0].productTypeName === 'WATER' && (item.state === 'D' || item.state === 'J');
                    });
                    this.dataArray = this.dataArray.reverse();
                }
                for(let x = 0; x < this.dataArray.length ; x ++) {
                    let tags;

                    if (this.dataArray[x].state === 'D') {
                        tags = ['검사완료'];
                    } else if (this.dataArray[x].state === 'J') {
                        tags = ['승인완료'];
                    }

                    // TO DO - 서버에서 수정한 후 이 코드 삭제 - updated 가 9 시간이 작게 나와서 프론트엔드에서 수정
                    let updatedObj = new Date((new Date(this.dataArray[x].updated)).getTime() + 9 * 60 * 60 * 1000);
                    let year = updatedObj.getFullYear();
                    let month = updatedObj.getMonth() + 1;
                    if (month < 10) {
                        month = '0' + month;
                    }
                    let date = updatedObj.getDate();
                    if (date < 10) {
                        date = '0' + date;
                    }
                    let hour = updatedObj.getHours();
                    if (hour < 10) {
                        hour = '0' + hour;
                    }
                    let minute = updatedObj.getMinutes();
                    if (minute < 10) {
                        minute = '0' + minute;
                    }
                    let second = updatedObj.getSeconds();
                    if (second < 10) {
                        second = '0' + second;
                    }

                    let checkState = 'OK';
                    _.forEach(this.dataArray[x].listProductCSInpection, function(item) {
                        if (item.listProductCSInpectionStandard[0].min === 'NG') {
                            checkState = 'NG';
                        }
                    });

                    data.push({
                        key : x,
                        name : this.dataArray[x].name,
                        barcode : this.dataArray[x].barcode,
                        tags : tags,
                        // updated : this.dataArray[x].updated,
                        updated : year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second,
                        model : this.dataArray[x].clsProduct.productModel,
                        modelDetail : this.dataArray[x].clsProduct.productModelDetail,
                        product : this.dataArray[x].clsProduct.productName,
                        checkState : checkState,
                    });
                }
                data = data.reverse();
                this.data = data;
                this.flagSpinner = false;
                document.getElementById('loading-table').style.display = 'none';
            }).catch(error => {
                console.log(error);
                this.flagSpinner = false;
            });
        },
    }
}
</script>
<style scoped>
</style>