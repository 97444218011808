/****************************************************************************************
 * File Name    : EquipmentControl.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
    <div class = 'div_list'>
        <a-row v-if = "type !== 'view'" :gutter="16" type="flex" justify="space-around" align="middle">
            <a-col class="gutter-row" :span="3">
                <div class="gutter-box">
                    <a-avatar src = "https://static.turbosquid.com/Preview/001180/025/TE/conveyor-belt-3D_600.jpg" shape="square" :size="50" icon="user" class = "avatar"/>
                </div>
            </a-col>
            <a-col class="gutter-row" :span="10">
                <div class="gutter-box">
                    {{name}}
                    <a-divider type = 'vertical' />
                    <a-tag v-for="(item,index) in description" :key='index' :color="index===0 ? '#7F7F7F' : '#5B5B5B'" >{{item}}</a-tag>
                    등록된 접검항목 : 12건
                </div>
            </a-col>
            <a-col class="gutter-row" :span="4">
            </a-col>
             <a-col class="gutter-row" :span="7">
                <span class = 'icon_button' @click = 'addEquipmentControl'><a-icon type="plus" /> Add</span>
                <a-divider type = 'vertical' />
                <span class = 'icon_button' @click = 'editEquipmentControl'><a-icon type="edit" /> Edit</span>
                <a-divider type = 'vertical' />
                <span class = 'icon_button' @click = 'deleteEquipmentControl'><a-icon type="delete" /> Delete</span>
            </a-col>
        </a-row>
    </div>
</template>
<script>
export default {
    name: 'EquipmentControl',
    props : ['name','type','description'],
    methods : {
        addEquipmentControl() {
            console.log("## addEquipmentControl in EquipmentControl ##");
            this.$emit('addEquipmentControl');
        },
        editEquipmentControl() {
            console.log("## editEquipmentControl in EquipmentControl ##");
            this.$emit('editEquipmentControl');
        },
        deleteEquipmentControl() {
            console.log("## deleteEquipmentControl in EquipmentControl ##");
            this.$emit('deleteEquipmentControl');
        }
    }
}
</script>
<style scoped>
    .div_list {
        border-bottom : 1px solid #ECEAEA;
        padding : 10px 10px 10px 10px;
    }
    .avatar {
        border : 1px solid #ECEAEA;
        padding : 5px 5px 5px 5px;
        /* border-radius: 50%; */
    }

</style>